import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import AuthenticationStyle from "../../Styles/AuthenticationStyle";
import { Button, Input, InputAdornment } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AllservicesModify from './ModifyPage.jsx/AllservicesModify';
import FooterModify from './ModifyPage.jsx/FooterModify';
import { getBaseURL } from '../../api/apiManagement';

const ChangePass = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { classes } = props;
  const [data, setData] = React.useState({
    password: ""
  });
  const [newPass, setNewPass] = React.useState(false);
  const [confirmPass, setConfirmPass] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState("");

  const url = getBaseURL() + "/vendor/api/v1/changepassword";

  function handle(e) {
    data[e.target.id] = e.target.value;
  }

  function validatePassword(password) {
    // Add your password validation rules here
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    }
    if (!/[@#$%^&*!]/.test(password)) {
      setPasswordError("Password must contain at least one special character");
      return false;
    }
    setPasswordError("");
    return true;
  }

  function reset() {
    if (!validatePassword(data.password)) {
      // Password validation failed, don't proceed with the request
      return;
    }

    let params = {};
    params["email"] = localStorage.getItem("email");
    params["new_password"] = data.password;
    params["userId"] = id;

    axios.post(url, params).then((res) => {
      if (res.data.status === "success") {
        navigate('/');
      }
    });
  }

  function showPass() {
    setNewPass(!newPass);
  }

  function showConfirm() {
    setConfirmPass(!confirmPass);
  }

  return (
    <>
      <AllservicesModify></AllservicesModify>
      <Grid container className={classes.coperate_start3}>
        <Grid container className={classes.ht}>
          <Grid item xs={6} md={6} lg={6} className={classes.allm} style={{ display: "flex" }}>
            <img src='../Images/background-1.png' alt="bg" className='background-1' style={{ marginLeft: "0px", objectFit: "contain" }}></img>
          </Grid>
          <Grid item xs={12} md={5} lg={5} style={{ marginLeft: "50px", marginRight: "20px" }}>
            <div className={classes.coperate_section} style={{ marginRight: "20px" }}>
              <b className={classes.coperate_btn}>Corporate Login</b>
              <h2 className='auth-content-align9'>
                <div className='welcome2'><strong>Change/Reset Password</strong></div>
              </h2>
              <div className='newpass'>
                Create a new password that is at least 8 characters long and contains special characters.
              </div>
              <form>
                <div className='auth-content-align'>
                  <b style={{ display: "flex" }}>Enter new password</b>
                  <Input
                    item
                    xs={12}
                    id="password"
                    type={newPass ? 'text' : 'password'}
                    onChange={(e) => handle(e)}
                    className={classes.input}
                    startAdornment={
                      <InputAdornment position="start">
                        <img className={classes.msg_icon} src='../Images/email.svg'></img>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" sx={{ paddingRight: "10px", cursor: "pointer" }}>
                        <img onClick={showPass} className={classes.msg_icon} src='../Images/eye.png'></img>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className='auth-content-align'>
                  <b style={{ display: "flex" }}>Confirm new password</b>
                  <Input
                    item
                    xs={12}
                    id="user_name"
                    type={confirmPass ? 'text' : 'password'}
                    className={classes.input}
                    startAdornment={
                      <InputAdornment position="start">
                        <img className={classes.msg_icon} src='../Images/email.svg'></img>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" sx={{ paddingRight: "10px", cursor: "pointer" }}>
                        <img onClick={showConfirm} className={classes.msg_icon} src='../Images/eye.png'></img>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="error-message">
                  {passwordError}
                </div>
                <div className='auth-content-align' style={{ display: "flex" }}>
                  <Button className={classes.login4} onClick={reset}>Change/ Reset Password</Button>
                </div>
              
              </form>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <FooterModify></FooterModify>
    </>
  );
};

export default withStyles(AuthenticationStyle)(ChangePass);
