import React, { useState } from "react";
import "./docutracker.css";
import Allservices from "../Allservices";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import Footer from "../../Homepage/Footer";
import { Box, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import SendPackageStyle from "../Package/SendPackageStyle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import { getIotUrl } from "../../../api/apiManagement";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";
import { initializeApp } from "firebase/app";
import { getFirestore, onSnapshot, collection } from "@firebase/firestore";
import GoogleMapComponent from "./GoogleMap";

const names = ["ddd", "fff"];
function DashboardIot(props) {
  const { classes } = props;
  const iotUrl = getIotUrl() + "/api/document/";
  const iotSpecificUrl = getIotUrl() + "/api/doc_event/"
  const [formData, setFormData] = useState({
    device_id: "",
    device_name: "",
    device_description: "",
  });

  const [openCreate, setOpenCreate] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [documentDetails, setDocumentDetails] = useState();
  const [specDetails, setSpecDetails] = useState();
  const [showDetails, setShowDetails] = useState(false)
  const [personName, setpersonName] = useState();
  const [deviceId, setDeviceId] =useState()
  const [owner, setOwner] =useState()
  const [access, setAccess] =useState()
  const [documentId, setDocumentId] =useState()
  const [currentStatus, setCurrentstatus] =useState("")
  const [statusNm, setstatusNm] =useState("")
  const documentDetailsStatus = ['inprogress','done']
  const handleCardClick = (index,iot,status) => {
    setDocumentId(iot)
    setShowDetails(!showDetails)
   
    setCurrentstatus(status)
    setActiveCardIndex(index === activeCardIndex ? null : index);
    axios.get(iotSpecificUrl,{
      params:{
        document_id:iot,
        device_id: index
      }
    }).then((response) => {
        console.log("jkjjkkjk", response);
        if (response && response.data) {
          setSpecDetails(response.data.data);
          setpersonName(index)
         
          setDeviceId(index);
          //setCurrentstatus(response.data.document_status)
        }
      });
  };
  const handleChangeStatus =(event)=>{
    console.log('ggg',event.target.value)
    setCurrentstatus(event.target.value)
    axios.put(iotUrl, {
     
        document_id:personName,

   
    }).then((response) => {
      console.log("tyyy", response);
    });
  }
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6,
      }}
    />
  );
  function handleCloseError() {
    setOpenCreate(false);
  }
  function handleCloseEdit() {
    setOpenEdit(false);
  }
  function handleopenError() {
    setFormData({
      device_id: "",
      device_name: "",
      device_description: "",
    });
    setOpenCreate(true);
  }
  function handleCloseEdit() {
    setOpenEdit(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEdit = (e) => {
    console.log("data", e);
    setFormData({
      device_id: e.title,
      device_name: e.name,
      device_description: e.details,
    });
    setOpenEdit(true);
  };

  React.useEffect(() => {
    setOwner(localStorage.getItem('name'));
    setAccess(localStorage.getItem('previlage'));
    axios.get(iotUrl, {
      params:{
        document_status:"in_progress"
      }
    }).then((response) => {
      console.log("tyyy", response);
    });
  }, [iotUrl]);
  const handleChangeDocument = (event) =>{
    console.log("device",event.target.value)
    setDeviceId(event.target.value.iot_device_id)
    setpersonName(event.target.value.id)
    axios.get(iotSpecificUrl,{
      params:{
        document_id: event.target.value.id
      }
    }).then((response) => {
        console.log("jkjjkkjk", response);
        if (response && response.data) {
          setSpecDetails(response.data.data);
          setCurrentstatus(response.data.document_status)
        }
      });
  }
  const handleSubmit = (e) => {
    // You can perform form submission logic here
    axios.post(iotUrl, formData).then((res) => {
      try {
        console.log("fff", res);
      } catch (error) {
        console.error();
      }
    });
    console.log("Form submitted:", formData);
  };
  const gotoTrack = (e) => {
    axios.get(iotUrl + e,{
      }).then((response) => {
          console.log("jkjjkkjk", response);
          if (response && response.data) {
            setDocumentDetails(response.data);
          }
        });
  }
  React.useEffect(() => {
    axios.get(iotUrl, {
      params:{
        document_status:"in_progress"
      }
    }).then((response) => {
      console.log("tyyy", response);
      if(response && response.data.data){
        setDocumentDetails(response.data.data);
        setDeviceId(response.data.data.iot_device_id)
      }
      
    });
  }, [iotUrl]);

  const Card = ({
    iotId,
    name,
    details,
    isActive,
    onClick,
    docDetails,
    phone,
    location,
  }) => {
    return (
      <div className={`Card ${isActive ? "active" : ""}`} >
        <div className="card-new">
          <div className="card-details">
            <span>
              <h3>Iot Name: {name}</h3>
            </span>
            <span>
              <h3>Doc.ID.{iotId}</h3>
            </span>
          </div>
          <div className="card-iot">IoT Linked</div>
        </div>
        <br />
        <div className="card-alls">
          {docDetails.map((item, index) => (
            <div className="card-doc">
              <div className="card-doc-details">
                <span className="card-doc-text">{item.document_type}</span>
                <span className="card-doc-text"></span>
              </div>
            </div>
          ))}
        </div>

        <div className="card-pers">
          <div className="card-doc-details">
            <span className="card-doc-per">{name}</span>
            <span className="card-doc-per">{phone}</span>
          </div>
        </div>
        <div className="card-pers">
          <div className="card-doc-details">
            <span className="card-doc-per">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.383"
                height="11.976"
                viewBox="0 0 8.383 10"
              >
                <g id="ic_location_on_24px" transform="translate(-5 -2)">
                  <path
                    id="Path_651"
                    data-name="Path 651"
                    d="M9.192,2A4.189,4.189,0,0,0,5,6.192c0,3.144,4.192,7.784,4.192,7.784s4.192-4.641,4.192-7.784A4.189,4.189,0,0,0,9.192,2Zm0,5.689a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,9.192,7.689Z"
                    fill="#d99e12"
                  />
                </g>
              </svg>
              <span className="doc-loc">&nbsp;{location}</span>
            </span>
            <span className="card-doc-per">
              <button className="btn-doc" onClick={onClick}>
                Track
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  };
  const CardAlert = () => {
    return (
      <div className="Card">
        <div className="card-new">
          <div className="card-details">
            <span>
              <h3>Iot No.</h3>
              <span></span>
            </span>
            <span>
              <h3>Doc.ID.</h3>
            </span>
          </div>

          <div className="card-iot">IoT Linked</div>
          <div></div>

          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    
    <Box>
       {documentDetails && documentDetails !== undefined ?
        ( <>
      <Grid container xs={12} md={12} lg={12} className="card-main">
        <Grid item xs={12} md={8} lg={8}>
          <div className="welcome-details">
            Welcome back,{" "}
            <span className="superadmin">{owner} ({access})</span>
          </div>
          <div>
            <span className="iot-dark">
              {documentDetails && documentDetails.length
                ? documentDetails.length
                : "0"}
            </span>{" "}
            <span className="Active">Active</span>{" "}
            <span className="iot-dark">IoT</span>{" "}
            <span className="progress">
              with their location progress & status
            </span>
          </div>
          <br />
        </Grid>
        <Grid item xs={12} md={2} lg={2}></Grid>
        <Grid item xs={12} md={2} lg={2}></Grid>
      </Grid>
      <Grid container xs={12} md={12} lg={12}>
        <Grid item xs={12} md={4} lg={4}>
          <GoogleMapComponent id={documentId}></GoogleMapComponent>
        </Grid>
{!showDetails ? (<>
        {documentDetails && documentDetails.length && documentDetails !== undefined ?
        ( 
        <Grid item xs={12} md={8} lg={8} className="card-main">
          <div className="card-all">
          {documentDetails.map((item, index) => (
            <Card
              key={index}
              iotId={item.iot_device_id}
              name={item.client_name}
              phone={item.client_phone}
              docDetails={item.documentdetail_set}
              isActive={index === activeCardIndex}
              location={item.client_address}
              details={item.details}
              onClick={() => handleCardClick(item.iot_device_id,item.id,item.current_status)}
            />
          ))}
          </div>
        </Grid>):(<>
         <div className='center2'>
                  
                    </div></>)}
        </>):(<>
        {specDetails && specDetails.length && specDetails !== undefined ? (
          <>
          <Grid item xs={12} md={8} lg={8}  className="card-main-sec">
     
        
        <Grid xs={12} md={12} lg={12}>
            <div className="headed">
            <span className="subhead">Document ID</span>
            <span style={{ paddingTop: "10px", paddingLeft: "15px" }}>
              <Select
                displayEmpty
                className={classes.searchInput3}
                id="outlined-adornment-weight"
                renderValue={(selected) => {
                  if (selected && selected.length === 0) {
                    return (
                      <span className={classes.photoQty}>
                       Document ID
                      </span>
                    );
                  }

                  return personName;
                }}
                value={personName}
                onChange={handleChangeDocument}
              >
                <MenuItem disabled value="">
                  Document ID
                </MenuItem>
                {documentDetails.map((name) => (
                  <MenuItem key={name} value={name}>
                    <div>
                      <span>{name.id}</span>&nbsp;&nbsp;
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </span>
            <span className="subhead">IoT Number.</span>
            <span className="subhead"> {deviceId}</span>
            <span className="subhead">Status</span>
            <span style={{ paddingTop: "10px", paddingLeft: "15px" }}>
              <Select
                displayEmpty
                className={classes.searchInput4}
                id="outlined-adornment-weight"
                
                renderValue={(selected) => {
                  if (selected && selected.length === 0) {
                    return (
                      <span className={classes.photoQty}>
                      Status
                      </span>
                    );
                  }

                  return currentStatus;
                }}
                value={currentStatus}
                onChange={handleChangeStatus}
              >
               {documentDetailsStatus.map((name) => (
                  <MenuItem key={name} value={name}>
                    <div>
                      <span>{name}</span>&nbsp;&nbsp;
                    </div>
                  </MenuItem>
                ))}
             
              </Select>
            </span>
            {/* <span className="subhead">Start Date</span>
            <span className="subhead">End Date</span> */}
          </div>
          {specDetails.map((item, index) => (
          <div className="informa">
            <Grid container xs={12} md={12} lg={12}>
              <Grid item xs={12} md={4} lg={4}>
                <div className="sub-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.383"
                    height="11.976"
                    viewBox="0 0 8.383 10"
                  >
                    <g id="ic_location_on_24px" transform="translate(-5 -2)">
                      <path
                        id="Path_651"
                        data-name="Path 651"
                        d="M9.192,2A4.189,4.189,0,0,0,5,6.192c0,3.144,4.192,7.784,4.192,7.784s4.192-4.641,4.192-7.784A4.189,4.189,0,0,0,9.192,2Zm0,5.689a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,9.192,7.689Z"
                        fill="#d99e12"
                      />
                    </g>
                  </svg>
                  <span className="pLoc">{item && item.location_name ?item.location_name :"" }</span>
                </div>
                <div>
                  <span className="pName">{item && item.person_name ?item.person_name :"" }</span>
                </div>
                <div>
                  <span className="pDept">{item && item.person_department ? item.person_department: ""}</span>
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={4} className="sub-info-middle">
                <div className="sub-infou">
                  {item.event_status === 'inprogress' ? (
                  <span className="status-progress">
                  IN PROGRESS
                  </span>):(<></>)}
                  {item.event_status === 'done' ? (
                  <span className="status-done">
                 DONE
                  </span>):(<></>)}
                  {item.event_status === 'infomissing' ? (
                  <span className="status-missing">
                  INFO MISSING
                  </span>):(<></>)}
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <div className="sub-infoi">
                  <img className="pSig"  src={item.person_signature}></img>
                </div>
              </Grid>
            </Grid>
          </div>
          ))}
          <br/>
        </Grid>
        
        
      
         </Grid></>) : (<></>)}
        </>)}
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        {/* <CardAlert /> */}
      </Grid>
      </>) : (<>
        <div className='center2'>
                    <div class="loader"></div>
                    </div>
      </>)}
    </Box>
  );
}

export default withStyles(SendPackageStyle)(DashboardIot);
