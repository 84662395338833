import React, { Component, forwardRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Checkbox, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import DeliveryStyle from './Delivery';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { useParams } from "react-router-dom";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Pdf from "react-to-pdf";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import DatePicker from 'react-datepicker'
import FilterListIcon from '@mui/icons-material/FilterList';
import { VisibilityOff, DateRange, RadioButtonChecked } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { width } from '@mui/system';
import moment from "moment";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Configuration from '../../../config.json'
import Pagination from '@mui/material/Pagination';
import { getBaseURL } from '../../../../api/apiManagement';
const AllDeliveryNote = (props) => {
   // console.log("fff",Configuration)
    let dispalyData;
    const { id } = useParams();
    const url = getBaseURL() + "/vendor/api/v1/parcel"
    const { classes } = props;

    const [data, setData] = React.useState();
    const [dataParcel, setDataParcel] = React.useState();
    const [datanew, setDataNew] = React.useState();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [download, setdownload] = React.useState(false);
    const [btn, setButton] = React.useState(false);
    const [btnRadio, setButtonRadio] = React.useState(true);
    const [data1,setData1] = React.useState()
    const [logo,setLogo] = React.useState();
    const [logoFot,setLogoFot] = React.useState();
    const [logoFotDouble,setLogoFotDouble] = React.useState();
    let [pageNo,setPageNo] = React.useState(1);
    const ref = React.createRef();
    const decreasePage = (er,newpage) => {
        pageNo = newpage
        setPageNo(parseInt(newpage))
        axios.get(url, {
            params: {
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
                noOfRecord:"10",
                pageNumber: pageNo,
                order_type: 'Delivered'
            }
        }).then((response) => {

          //  console.log(response)
            setDataParcel(response.data.parcel)
            window.scroll(0,0)

        });
    }
    const addPage = () => {
        pageNo = pageNo +1;
       setPageNo(parseInt(pageNo))
       axios.get(url, {
        params: {
            userId: localStorage.getItem("userId"),
            company_id:localStorage.getItem("companyId"),
            noOfRecord:"10",
            pageNumber: pageNo
        }
    }).then((response) => {

       // console.log(response)
        setDataParcel(response.data.parcel)
        window.scroll(0,0)

    });
    }
    const addPageNumber = (e) => {
        pageNo = e;
       setPageNo(parseInt(e))
        //console.log("fff",pageNo)
        axios.get(url, {
            params: {
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
                noOfRecord:"10",
                pageNumber: parseInt(e)
            }
        }).then((response) => {
    
           // console.log(response)
            setDataParcel(response.data.parcel)
            window.scroll(0,0)
    
        });
    }
    const [caseNumber,setCaseNumber] = React.useState('');
    React.useEffect(() => {
        for (let i = 0; i < Configuration.Company.length; i++) {
            //console.log("444",Configuration.Company[i].logo)
            if(Configuration.Company[i].Cname ===  localStorage.getItem("company")){
             //   console.log("444",Configuration.Company[i].Cname)
                setLogo(Configuration.Company[i].logo);
                setLogoFot(Configuration.Company[i].logoFooter);
                setLogoFotDouble(Configuration.Company[i].logoFooterDouble);
                setCaseNumber(Configuration.Company[i].immigo_case_number_display_name);
            
            }
        } 
        
        axios.get(url, {
            params: {
                _id: id,
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
               

            }
        }).then((response) => {

           // console.log(response)
            setData(response.data.parcel[0])

        });



    }, [url]);
   
    React.useEffect(() => {

        axios.get(url, {
            params: {
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
                noOfRecord:"10",
                pageNumber: pageNo,
                order_type: 'Delivered'
            }
        }).then((response) => {

           // console.log(response)
            setDataParcel(response.data.parcel)

        });



    }, [url]);
    function resetData(){
        setDataParcel(undefined)
        setStartDate(null)
        setEndDate(null)
        axios.get(url, {
            params: {
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
                noOfRecord:"10",
                pageNumber: pageNo,
                order_type: 'Delivered'
            }
        }).then((response) => {

           // console.log(response)
            setDataParcel(response.data.parcel)

        });
    }

    const onChangeDate = (dates) => {
        setDataParcel(undefined)
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //console.log("fff",end)
        if(end){
        axios.get(url, {
            params: {
                userId:localStorage.getItem("userId").toString(),
                company_id:localStorage.getItem("companyId").toString(),
                from_date:moment(start).format("DD/MM/YYYY"),
                to_date:moment(end).format("DD/MM/YYYY"),
                
                order_status: 'Delivered'
            }
        }).then((response) => {

           // console.log("errrrfff",response)
            setData(response.data.parcel[0])
            setDataParcel(response.data.parcel)

        });
    }
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <OutlinedInput
            id="outlined-adornment-password"
            onClick={onClick}
            ref={ref}
            value={value}
            className={classes.datePicker}
            startAdornment={<InputAdornment position="start"><img src="./Images/calendar-alt.png" width={17} height={19} alt='calender' /></InputAdornment>}
           
            placeholder="Date From - Date To"
        />
    ));
    const createPDF = async (ind) => {  
        setButton(true) ;
        const pdf = new jsPDF("portrait", "pt", "a4"); 
        const data = await html2canvas(document.querySelector("#pdf"+ind));
        const img = data.toDataURL("image/png");  
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "SVG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("deliverynote.pdf");
        setTimeout(() => {
            setButton(false)
          }, 300);
      };

      const collection = async () => { 
        setButtonRadio(false)
      }
      const collection1 = async () => { 
        setButtonRadio(true)
      }
      const callcheck = async () => { 
        
      }
      const [valueInvoice, setInvoice] = React.useState("onGoing");
      const handleChangeInvoice = (event) => {
        setInvoice(event.target.value);
      };
    //console.log(data)
    return (<>
      <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={6} lg={6}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={valueInvoice}
              onChange={handleChangeInvoice}
                    >
                   
                       
                        <FormControlLabel onClick={collection1}  className={classes.radioLabel} value="onGoing"  control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label="Delivery Note" />
                     
                        <FormControlLabel onClick={collection} className={classes.radioLabel} value="completed" control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label="Collection Note" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.filterContainer} textAlign="right">
                <FilterListIcon />
                <p className={classes.filterText}>Filter by date</p>
                <DatePicker
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    customInput={<ExampleCustomInput />}
                    calendarClassName={classes.calender}
                />&nbsp;&nbsp;
                 <img onClick={resetData} title='refresh' style={{cursor:'pointer'}} src="./Images/undo.svg"></img>
            </Grid>
           
        </Grid>
        
        {dataParcel !== undefined ? (
            dispalyData = dataParcel.map((item, index) =>
                item.order_status === 'Delivered' && item.delivery_type !== 'accompaniment' && item.delivery_type !== 'accompainmentschedulelater' ? <>
              
            <div key={index} id={`pdf${index}`}>
                <Grid container >
                    <Box className={classes.delivery} >
                        <Grid container ref={ref}>
                            <Grid container alignItems="center" justifyContent="space-between" item xs={12} md={12} lg={12} className={classes.deliverySpace1}>
                            {logoFotDouble === '' ? (
                                <Grid item xs={6} md={2} lg={2}>
                              
                                <img width={'100%'} className={classes.imgNote} src= {logo}></img>
                                  
                                   
                                </Grid>
                                  ):(<>
                                  <Grid item xs={6} md={2} lg={2}>
                                    <img width={'80%'} className={classes.imgNote} src= {logo}></img>
                                    <img width={'60%'} style={{paddingLeft: '15px'}} className={classes.imgNote} src= {logoFotDouble}></img>
                                    </Grid>
                                    </>)}

                                {valueInvoice === 'completed' ?
                                    <Grid item xs={12} md={4} lg={4} className={classes.deliverySpace}>
                                        <b className={classes.note}> Document Collection Note</b>
                                    </Grid> :
                                    <Grid item xs={12} md={4} lg={4} className={classes.deliverySpace}>
                                        <b className={classes.note}> Document Delivery Note</b>
                                    </Grid>}

                                <Grid item xs={6} md={2} lg={2} className={classes.deliverySpace2}>
                                    <p className={classes.orderId}>OrderID: <span className={classes.orderNumber}>{item.order_id}</span></p>
                                </Grid>
                                <Grid item xs={6} md={2} lg={2} className={classes.delDownload}>
                                    {!btn ? 
                                <div title='DOWNLOAD'>
                                <button onClick={()=>createPDF(index)}  className={classes.btnInvoice}>Download</button>
                                </div>:<></>}

                                </Grid>
                            </Grid>
                            <Grid container className={classes.addressSection}>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}>Pickup Address &nbsp;&nbsp;
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                           <span className={classes.notedts}>{item.pickup[0].pickup_location}</span>&nbsp;
                                        {item && item.pickup &&item.pickup[1] && item.pickup[1].pickup_location ? (<>
                                            <span className={classes.notedts}><br/>{item.pickup[1].pickup_location}</span></>):(<></>)}&nbsp;
                                            {item && item.pickup &&item.pickup[2] && item.pickup[2].pickup_location ? (<>
                                            <span className={classes.notedts}> <br/> {item.pickup[2].pickup_location}</span></>):(<></>)}&nbsp;
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Delivery Address</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{item.delivery[0].delivery_location}
                                            </span>
                                        </Grid></div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Telephone</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{item.pickup[0].pickup_location_phone}
                                            </span>
                                        </Grid></div>
                                </Grid>
                               {/*} <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                                {data.partner_name !== undefined ? (
                                    <div>Delivered by: {item.partner_name.toUpperCase()} </div>) : (<div>Delivered by: Pending </div>)}
                                </Grid>*/}
                                 {(item.immigoCaseNumber !== '' && item.immigoType ==='single') || !item.immigoType ?(
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> {caseNumber}</span>
                                        </Grid>
                                       
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{(item.immigoCaseNumber)}
                                            </span>
                                        </Grid>
                                        </div>

                                </Grid>):(<></>)}
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Date</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            {item.updatedAt ? (
                                            <span className={classes.notedts}>{moment(item.updatedAt).format("DD-MM-YYYY")}
                                            </span>):(<></>)}
                                        </Grid></div>

                                </Grid>
                                {item.total_timetaken ? (
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Time</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                           
                                            <span className={classes.notedts}>{item.total_timetaken}
                                            </span>
                                        </Grid></div>

                                </Grid>):(<></>)}
                               {/*}  {item.rule ? 
                                <Grid item xs={6} md={3} lg={6} className={classes.deliverySpace}>
                                    <b className={classes.note}> Document Collection Note</b>
                                </Grid>:
                                <Grid item xs={6} md={3} lg={6} className={classes.deliverySpace}>
                                <b className={classes.note}> Document Delivery Note</b>
                            </Grid>}
                            <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                                <div>
                                    <span><b>Status:</b>&nbsp;{item.order_status}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span><b>OrderId:</b>&nbsp;{item._id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                   
                                </div>

                            </Grid>*/}

                                {/*} <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                            <table className={classes.tabled}>
                                <tr>
                               
                                    <th>Qty</th>
                                    <th>Document Description</th>
                                    
                                </tr>
                                <tr className={classes.tabledtr} >
                                  
                                   {item && item.passport ?<> <td><div>{item.passport.length}
                                </div></td>
                                <td>Passport</td></>
                                : null}
                                   
                            
                                </tr>
                                
                            </table>
    </Grid>*/}
                            </Grid>
                            {item && item.delivery_type !=='accompaniment' ? (
                            <Grid item xs={12} md={12} lg={12} className={classes.documentContainer}>
                                <b className={classes.descDoc}>Document Description</b> : &nbsp;&nbsp;&nbsp;
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.documentTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeadItem}>No.</TableCell>
                                            <TableCell align="left" className={classes.tableHeadItem}>Qty.</TableCell>
                                            <TableCell align="left" className={classes.tableHeadItem}>Document Description</TableCell>
                                            <TableCell align="left" className={classes.tableHeadItem}>Name</TableCell>
                                            {localStorage.getItem('company') === 'pwc' && item.immigoType !=='single' ?(
                                            <TableCell align="left" className={classes.tableHeadItem}>{caseNumber}</TableCell>):(<></>)}
                                            <TableCell align="left" className={classes.tableHeadItem}>Reference Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {item && item.product_info && item.product_info !== undefined ? (
                                             item.product_info.map((items, index) =>
                                    <TableBody>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>{index+1}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{items.qty}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{items.product_name}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>
                                               {items &&  items.document_info ? (
                                                items.document_info.map((it, index) =>
                                               <>
                                               <div className='vbb'>
                                               {it.name ? it.name : ""}
                                               </div>
                                                </>
                                                )):(<>
                                                </>)}</TableCell>
                                                {localStorage.getItem('company') === 'pwc' && item.immigoType === 'multiple' ? (
                                                <TableCell align="left" className={classes.tableCellItem}>
                                                 {items &&  items.document_info ? (
                                                items.document_info.map((it, index) =>
                                               
                                               <>
                                             <div className='vbb'>
                                             {it.immigoCaseNumber ? it.immigoCaseNumber : ""}
                                             </div>
                                                </>
                                                )):(<></>)}
                                               </TableCell>):(<></>)}
                                                <TableCell align="left" className={classes.tableCellItem}>
                                                 {items &&  items.document_info ? (
                                                items.document_info.map((it, index) =>
                                               
                                               <>
                                             <div className='vbb'>
                                             {it.refrence_no ? it.refrence_no : ""}
                                             </div>
                                                </>
                                                )):(<></>)}
                                               </TableCell>
                                            </TableRow>
                                    </TableBody>)
                                    ):<></>}
                                </Table>
                                {!btn ?
                                <FormControlLabel className={classes.acknowledgeCheckboxContainer} control={<Checkbox checked className={classes.acknowledgeCheckbox} />} label="I hereby acknowledge the receipt of the above documents in good order" />
                                    :<></>}
                                </Grid>):(<></>)}

                                    {btnRadio ? (
                            <Grid container item xs={12} md={12} lg={12} alignItems="center" justifyContent="space-between" className={classes.evidenceContainer}>
                                <Grid xs item className={classes.evidenceItem}>
                                    <Typography className={classes.evidenceTitle}>Name</Typography>
                                    {item && item.delivery_signed_by ? (
                                    <Typography className={classes.customerName}>{item.delivery_signed_by}</Typography>):(<Typography className={classes.customerName}>Fayiz</Typography>)}
                                </Grid>
      
                                {item.delivery[0] && item.delivery[0].delivery_signature ?(
                                <Grid xs item className={classes.evidenceItem}>

                                    
                                    <Typography className={classes.evidenceTitle}>Customer Signature</Typography>
                                   
                                    <img className={classes.base} src={"data:image/png;base64," + item.delivery[0].delivery_signature}></img>
                                   {/*} <img src={item.customer_signature && item.customer_signature !== "" ? item.customer_signature : './../Images/nim.png'} width={140} height={90}></img>*/}
                                </Grid>):(<></>)}
                                {item.delivery[0].image && item.delivery[0].image ? 
                                <Grid xs item className={classes.evidenceItem} justifyContent="flex-end">
                                    <Typography className={classes.evidenceTitle}>Parcel Delivered Evidence</Typography>
                                    
                                    <img className={classes.base} src={"data:image/png;base64," + item.delivery[0].image}></img>
                                    {/*<img src={item.delivery_image && item.delivery_image !== "" ? item.delivery_image[0] : './../Images/nim.png'} width={160} height={90}></img>*/}
                                </Grid>:<></>}

                            </Grid>):(
                                <>
                                 <Grid container item xs={12} md={12} lg={12} alignItems="center" justifyContent="space-between" className={classes.evidenceContainer}>
                                <Grid xs item className={classes.evidenceItem}>
                                    <Typography className={classes.evidenceTitle}>Name</Typography>
                                    {item && item.pickup_signed_by ? (
                                    <Typography className={classes.customerName}>{item.pickup_signed_by}</Typography>):(<Typography className={classes.customerName}>Fayiz</Typography>)}
                                </Grid>
      
                                {item.pickup[0] && item.pickup[0].pickup_signature ?(
                                <Grid xs item className={classes.evidenceItem}>

                                    
                                    <Typography className={classes.evidenceTitle}>Customer Signature</Typography>
                                   
                                   <img className={classes.base} src={"data:image/png;base64," + item.pickup[0].pickup_signature}></img>
                                   {/*} <img src={item.customer_signature && item.customer_signature !== "" ? item.customer_signature : './../Images/nim.png'} width={140} height={90}></img>*/}
                                </Grid>):(<></>)}
                                {item.pickup[0].image && item.pickup[0].image ? 
                                <Grid xs item className={classes.evidenceItem} justifyContent="flex-end">
                                    <Typography className={classes.evidenceTitle}>Parcel Delivered Evidence</Typography>
                                    {valueInvoice === 'completed' ? (     
                           <img className={classes.base} src={"data:image/png;base64," + item.pickup[0].image}></img>):( <img className={classes.base} src={"data:image/png;base64," + item.delivery[0].image}></img>)}
                                    {/*<img src={item.delivery_image && item.delivery_image !== "" ? item.delivery_image[0] : './../Images/nim.png'} width={160} height={90}></img>*/}
                                </Grid>:<></>}

                            </Grid>
                          
                                </>
                            )}
                              <Grid container item xs={12} md={4} lg={4} className={classes.evidenceContainer} >
                              
                              </Grid>
                            
                              <Grid container item xs={12} md={6} lg={6} className={classes.evidenceContainer} >
                             
                                        {logoFotDouble !== '' ? (<>
                                            <img className={classes.fotLogo} width={'30%'} src={logoFot}></img>
                                            <img className={classes.fotLogo} width={'30%'} src={logoFotDouble}></img></>) : (<>
                                                <img className={classes.fotLogo} width={'80%'} src={logoFot}></img>
                                            </>)}
                                 {/*} <img width={'40%'} src='./../Images/newland.png'></img>*/}
                                  </Grid>
                              <Grid container item xs={12} md={2} lg={2} className={classes.evidenceContainer} >
                                
                              </Grid>
                        </Grid>
                    </Box>
                </Grid>
                </div>



                {/**Download starts */}
                {download ? 
                <div className={classes.download} >
                <Grid container >
                    <Box className={classes.delivery} >
                        <Grid container ref={ref}>
                            <Grid container alignItems="center"  item xs={12} md={12} lg={12} className={classes.deliverySpace1}>
                                <Grid item xs={6} md={3} lg={3}>
                                    <img className={classes.imgNote} src='./../Images/Jiffy_Lite.svg' ></img>
                                </Grid>
                                {item.rule ?
                                    <Grid item xs={6} md={4} lg={4} className={classes.deliverySpace}>
                                        <b className={classes.note}> Document Collection Note</b>
                                    </Grid> :
                                    <Grid item xs={6} md={4} lg={4} className={classes.deliverySpace}>
                                        <b className={classes.note}> Document Delivery Note</b>
                                    </Grid>}

                                <Grid item xs={6} md={2} lg={2} className={classes.deliverySpace2}>
                                    <p className={classes.orderId}>OrderID: <span className={classes.orderNumber}>{item.order_id}</span></p>
                                </Grid>
                                
                            </Grid>
                            <Grid container className={classes.addressSection}>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}>Pickup Address &nbsp;&nbsp;
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{item.pickup_location}</span>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Delivery Address</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{item.delivery[0].delivery_location}
                                            </span>
                                        </Grid></div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Telephone</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{item.pickup_location_phone}
                                            </span>
                                        </Grid></div>
                                </Grid>
                               {/*} <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                                {data.partner_name !== undefined ? (
                                    <div>Delivered by: {item.partner_name.toUpperCase()} </div>) : (<div>Delivered by: Pending </div>)}
                                </Grid>*/}
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Date</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}> {item.updatedAt}
                                            </span>
                                        </Grid></div>

                                </Grid>
                                <Grid item xs={12} md={12} lg={12} className={classes.containerItem}>
                                    <div className={classes.notemain}>
                                        <Grid item xs={12} md={2.5} lg={2.5}>
                                            <span className={classes.notehead}> Price</span>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <span className={classes.notedts}>{item.estimatedPrice} AED
                                            </span>
                                        </Grid></div>

                                </Grid>
                               {/*}  {item.rule ? 
                                <Grid item xs={6} md={3} lg={6} className={classes.deliverySpace}>
                                    <b className={classes.note}> Document Collection Note</b>
                                </Grid>:
                                <Grid item xs={6} md={3} lg={6} className={classes.deliverySpace}>
                                <b className={classes.note}> Document Delivery Note</b>
                            </Grid>}
                            <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                                <div>
                                    <span><b>Status:</b>&nbsp;{item.order_status}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span><b>OrderId:</b>&nbsp;{item._id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                   
                                </div>

                            </Grid>*/}

                                {/*} <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                            <table className={classes.tabled}>
                                <tr>
                               
                                    <th>Qty</th>
                                    <th>Document Description</th>
                                    
                                </tr>
                                <tr className={classes.tabledtr} >
                                  
                                   {item && item.passport ?<> <td><div>{item.passport.length}
                                </div></td>
                                <td>Passport</td></>
                                : null}
                                   
                            
                                </tr>
                                
                            </table>
    </Grid>*/}
                            </Grid>

                            <Grid item xs={12} md={12} lg={12} className={classes.documentContainer}>
                                <b className={classes.descDoc}>Document Description</b> : &nbsp;&nbsp;&nbsp;
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.documentTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeadItem}>No.</TableCell>
                                            <TableCell align="left" className={classes.tableHeadItem}>Qty.</TableCell>
                                            <TableCell align="left" className={classes.tableHeadItem}>Document Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {item && item.passport && item.passport.length && item.passport.length !== 0 ? (item && item.passport) && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>1</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{item.passport.length}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>Passport</TableCell>
                                            </TableRow>
                                        ):<></>}
                                        {item && item.emiratesId && item.emiratesId.length && item.emiratesId.length !== 0 ? (item && item.emiratesId) && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>2</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{item.emiratesId.length}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>Emirates Id</TableCell>
                                            </TableRow>
                                        ):<></>}
                                        {item && item.mCerti && item.mCerti.length && item.mCerti.length !== 0 ? (item && item.mCerti) && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>3</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{item.mCerti.length}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>Marriage Certificate</TableCell>
                                            </TableRow>
                                        ):<></>}
                                        { item && item.bCerti && item.bCerti.length && item.bCerti.length ?(item && item.bCerti) && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>4</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{item.bCerti.length}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>Birth Certificate</TableCell>
                                            </TableRow>
                                        ):<></>}
                                        {item && item.dCerti && item.dCerti.length && item.dCerti.length !== 0 ?(item && item.dCerti) && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>5</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{item.dCerti.length}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>Degree Certificate</TableCell>
                                            </TableRow>
                                        ):<></>}
                                        {item && item.other && item.other.length && item.other.length !== 0 ? (item && item.other) && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" className={classes.tableCellItem}>6</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>{item.other.length}</TableCell>
                                                <TableCell align="left" className={classes.tableCellItem}>Other</TableCell>
                                            </TableRow>
                                        ) : <></>}
                                    </TableBody>
                                </Table>
                                {/*<FormControlLabel className={classes.acknowledgeCheckboxContainer} control={<Checkbox checked className={classes.acknowledgeCheckbox} />} label="I hereby acknowledge the receipt of the above documents in good order" />*/}
                            </Grid>


                            <Grid container item xs={12} md={12} lg={12} alignItems="center" justifyContent="space-between" className={classes.evidenceContainer}>
                                <Grid xs item className={classes.evidenceItem}>
                                    <Typography className={classes.evidenceTitle}>Name</Typography>
                                    <Typography className={classes.customerName}>Farookh Sheikh Abdul Ramzad</Typography>
                                </Grid>
                                <Grid xs item className={classes.evidenceItem}>


                                    <Typography className={classes.evidenceTitle}>Customer Signature</Typography>
                                    <img src={item.pickup[0].pickup_signature}></img>
                                    <img className={classes.base} src={"data:image/png;base64," + item.delivery[0].delivery_signature}></img>
                                   {/*} <img src={item.customer_signature && item.customer_signature !== "" ? item.customer_signature : './../Images/nim.png'} width={140} height={90}></img>*/}
                                </Grid>
                                <Grid xs item className={classes.evidenceItem} justifyContent="flex-end">
                                    <Typography className={classes.evidenceTitle}>Parcel Delivered Evidence</Typography>
                                    <img className={classes.base} src={"data:image/png;base64," + item.delivery[0].image}></img>
                                    {/*<img src={item.delivery_image && item.delivery_image !== "" ? item.delivery_image[0] : './../Images/nim.png'} width={160} height={90}></img>*/}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                </div> : <></>}
                  {/**Download ends */}
         
                </>
                
                
                :<>
                
                
                </>)) : (<>
                    <div className='center2'>
                    <div class="loader"></div>
                    </div>    
                </>)}
                
                <Grid spacing={5} style={{display:"flex",justifyContent:"flex-end",gap:"10px"}}>
               
           <Pagination className={classes.paged} page={pageNo} onChange={decreasePage} count={10} />
              {/*}  <button class='pagination'  disabled={pageNo === 1} variant="outlined" onClick={() => decreasePage()} >
            Previous
          </button>
          
          <Button class='pagination' variant="outlined" onClick={() => addPageNumber(1)}>
            1
          </Button>
          <Button class='pagination' variant="outlined" onClick={() => addPageNumber(2)}>
            2
          </Button>
          <Button class='pagination' variant="outlined" onClick={() => addPageNumber(3)}>
            3
          </Button>
          <Button class='pagination' variant="outlined" onClick={() => addPageNumber(4)}>
            4
          </Button>
          <Button class='pagination' variant="outlined" onClick={() => addPageNumber(5)}>
            5
          </Button>
          <Button class='pagination' variant="outlined" onClick={() => addPage()}>
            Next
                </Button>*/}
        </Grid>
        <br/>
    </>

    );

}

export default withStyles(DeliveryStyle)(AllDeliveryNote);