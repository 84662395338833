import React from "react";

import { Routes,Route,Navigate } from "react-router-dom";
import Snowfall from 'react-snowfall'
//import Applayout from "../layouts/Applayout.jsx";
import { setAuthorizationHeader } from "../api/index";
import Authentication from "./Homepage/Authentication";
import Accompainment from "./Navigation/Accompainment/Accompainment";
import Allorders from "./Navigation/Allorders/Allorders";
import Allservices from "./Navigation/Allservices";
import Dashboardservices from "./Navigation/Dashboard/Dashboardservices";
import DocumentA from "./Navigation/Document/DocumentA";
import Modify from "./Navigation/ModifyPage.jsx/Modify";
import CollectPackage from "./Navigation/Package/CollectPackage";
import SendPackage from "./Navigation/Package/SendPackage";
import ServiceOptions from "./Navigation/ServiceOptions";
import Trackorder from "./Navigation/TrackOrders/Trackorder";
import Personelised from "./Navigation/Personelised/Personelised";
import Changepass from "./Navigation/Changepass";
import Profile from "./Navigation/Profile";
import Commingsoon from "./Navigation/Commingsoon";
import ModifyExp from "./Navigation/ModifyPage.jsx/ModifyExp";
import IotConfig from "./Navigation/Iot-configuration/iot-configuration";
import {useBnIdle} from 'use-bn-idle';
import { Link, useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DocumentTracker from "./Navigation/Document-tracker/DocumentTracker";
import DocumentTrackerGlobal from "./DocumentTracker/DocumentTrackerGlobal";
import PhotoService from "./Navigation/PhotoService/Package/PhotoService";
import Users from "./Navigation/Users";
const Root = () => {
  const navigate = useNavigate();
  const [startTimer, stopTimer] = useBnIdle(() => {
    setLoc(true)
    //Will execute if the user is idle for a certain period of time.
    //You can write logic like showing popup or log out the user.
    console.log('Expired: Session Timeout');
    navigate('/')
  })
  
  React.useEffect(() => {
    // start the user idle detection
    startTimer(720); //passed 1800 seconds as argument(12 mins).
  })

  const restartTimer = () => {
     startTimer(30)
  }
  React.useEffect(() => {
    setAuthorizationHeader();
    const currentURL = window.location.href;
    if(!sessionStorage.getItem('userId') && !currentURL.includes('change') ){
      navigate('/')
    }
  
  }, []);
  const [openLoc, setLoc] = React.useState(false);
  function handleCloseLoc() {
    setLoc(false)
  }
  function handleokLoc() {
    setLoc(false)
    
  }

  return (
      <>
      {/* <Snowfall
      snowflakeCount={150}
     
    /> */}
      {openLoc ? (
       <Dialog
        open={openLoc}
        onClose={() => handleCloseLoc()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontSize:'600'}}>{"Jiffy Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'black',fontSize:'400'}}>
          You have been inactive for a while. To ensure the security of your account, your session has been timed out. To continue, please login with your credentials.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleokLoc()} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>):(<></>)}
   
    <Routes>
    
      <Route  exact path="/"  element={<Authentication/>}/>
      <Route  exact path="/track"  element={<DocumentTrackerGlobal/>}/>
      <Route  exact path="/services"  element={<ServiceOptions/>}/>
      <Route  exact path="/dashboard"  element={<Dashboardservices/>}/>
      <Route exact path="/sendpackage" element={<SendPackage/>}/>
      <Route exact path="/collectpackage" element={<CollectPackage/>}/>
      <Route exact path="/accompainment" element={<Accompainment/>}/>
      <Route exact path="/documentAttestation" element={<DocumentA/>}/>
      <Route exact path="/personelised" element={<Personelised/>}/>
      <Route exact path="/trackorder/:id" element={<Trackorder/>}/>
      <Route exact path="/allorders" element={<Allorders/>}/>
      <Route exact path="/change/:id" element={<Changepass/>}/>
      <Route exact path="/profile" element={<Profile/>}/>
      <Route exact path="/users" element={<Users/>}/>
      <Route exact path="/commingsoon" element={<Commingsoon/>}/>
      <Route exact path="/iotconfig" element={<IotConfig/>}/>
      <Route exact path="/documenttracker" element={<DocumentTracker/>}/>
      <Route exact path="/modify/:idData" element={<ModifyExp/>}></Route>
      <Route exact path="/photoService" element={<PhotoService/>}></Route>

    </Routes>
    </>
  );
};

export default Root;