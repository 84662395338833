import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { withStyles } from "@material-ui/core/styles";
import AuthenticationStyle from "../../Styles/AuthenticationStyle";
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { Input, InputAdornment } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import Allservices from './Allservices';
import Services from '../Homepage/Services';
import Flow from '../Homepage/Flow';
import Purpose from '../Homepage/Purpose';
import AskedQuestions from '../Homepage/AskedQuestions';
import Footer from '../Homepage/Footer';
import axios from 'axios';
import { getBaseURL } from '../../api/apiManagement';
import OutlinedInput from "@material-ui/core/OutlinedInput";
const Profile =(props) => {
    const navigate = useNavigate();
  const { classes } = props;
  const [data, setData] = React.useState({
    password: "",
    curr_password:""


  })
  
  const [data1,setData1] = React.useState()
  const [edit,setEdit] = React.useState(false)
  const [name,setName] = React.useState()
  const [phone,setPhone] = React.useState()
  const [image,setImage] = React.useState([])
  const [modImage,setModImage] = React.useState()
  const url = getBaseURL() + "/vendor/api/v1/changepassword"
  const url2 = getBaseURL() + "/vendor/api/v1/corporateuser"






  function handle(e) {
    //const newTableData = { ...tableData }
    //const selectedStatus = e.target.value;
    data[e.target.id] = e.target.value

    //console.log(data)
  }
  useEffect(() => {

    axios.get(url2, {
        params: {
            _id: localStorage.getItem("userId")
        }
    }).then((response) => {

        //console.log(response)
        setData1(response.data);
        setModImage(response.data.vendors_collection[0].image)
        setName(response.data.vendors_collection[0].name)
        setPhone(response.data.vendors_collection[0].phone)

    });



}, [url2]);
  function reset(){
    let params={};
    params["email"]=localStorage.getItem("email")
    params["new_password"]=data.password;
    params["curr_password"]=data.curr_password;
    params["userId"] = localStorage.getItem("userId")

    axios.post(url, params).then((res) => {
        //console.log(res.data);
        //postId = res.data;
        if (res.data.status === "success") {
          //postId = res.data.id;
          //setId(res.data.id);
          //console.log(res);
          navigate('/')
          
        }
        try {
        } catch (error) {
          //console.error();
        }
      });
  }
  const editopen = () => {
    setEdit(true)
  }
  function saveAfter(){
    axios.get(url2, {
      params: {
          _id: localStorage.getItem("userId")
      }
  }).then((response) => {

      //console.log(response)
      setData1(response.data)

  });
  }

  const save = () => {
    var bodyFormData = new FormData();
    setEdit(false);
    // let params={};
    //params["_id"]=localStorage.getItem("userId")
    bodyFormData.append("_id", localStorage.getItem("userId"));
    console.log("gg",image !== null)
    if(image && image.length !== 0){
      bodyFormData.append("image", modImage);
    }
     
    bodyFormData.append("name", name);
    bodyFormData.append("phone", phone);
    // params["image"]= image;

    //params["name"] = name;
    // params["phone"] = phone;t
    //console.log("yyy", bodyFormData);
    axios.put(url2, bodyFormData).then((res) => {
     // console.log(res.data);

      if (res.data.status === "Success") {
        //console.log(res);
       // navigate('/services')
       window.location.reload();
      }
      try {
      } catch (error) {
       // console.error();
      }
    });
  };
  const changeName = (event) => {
    setName(event.target.value)
  }
  const changePhone = (event) => {
    setPhone(event.target.value)
  }
 function changeImage(e){
  setImage(e.target.files[0])
  setModImage(e.target.files[0])
    //console.log(e.target.files)
  }
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6
      }}
    />
  );
  return (<>
    <Allservices></Allservices>
    <Grid container className={classes.coperate_start2}>
 
    <Grid container className={classes.ht}  >
       
        <Grid item xs={8} md={2} lg={2}>
                  <div className={classes.heading}>
                   
                    <div className='profile'> Profile</div>
                  </div>
                </Grid>
                <Grid item xs={4} md={10} lg={10}>
                  <div  className='profilen'>
                    <ColoredLine color="#131C4C" />
                  </div>
                </Grid>
                {!edit ? <>
                <Grid item xs={12} md={12} lg={12}  style={{height: "150px", marginTop: "55px",display:"flex"}}>
                <Grid item xs={6} md={2} lg={2}>
                  <div className='imgRound'>
                    <img height={"100px"} width={"100px"} src={data1 && data1.vendors_collection && data1.vendors_collection[0] && data1.vendors_collection[0].image ? data1.vendors_collection[0].image : './Images/user.png'}></img>
                  </div>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                  <div className='fields'>
                 <span>{data1 && data1.vendors_collection && data1.vendors_collection[0] && data1.vendors_collection[0].name ? data1.vendors_collection[0].name : ''}</span>
                 
                  </div>
                  <div className='exMob'>
                   <img src='./Images/mob.png'></img>&nbsp;&nbsp;&nbsp;&nbsp;<span>{phone}</span>
                  </div>
                  <br/>
                  <div className='exMob'>
                   <img src='./Images/em.png'></img>&nbsp;&nbsp;<span>{localStorage.getItem("email")}</span>
                  </div>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <div className='edit' onClick={editopen}>Edit&nbsp;
                    <img height="15px" src='./Images/edit-2.svg'></img>
                    </div>
                  </Grid>
                </Grid></>:<>
                <Grid item xs={12} md={12} lg={12}  style={{height: "150px", marginTop: "55px",display:"flex"}}>
                <Grid item xs={2} md={2} lg={2}>
                  <div>
                    
                  </div>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                  <div className='fields1'><span>Name</span>&nbsp;&nbsp;&nbsp;&nbsp;
                 <span>
                 <OutlinedInput
                            id="outlined-adornment-weight"
                            name="name"
                            value={name}
                            placeholder="Name"
                            onChange={(event) => changeName(event)}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                
                 </span>
                 
                  </div>
                  <div className='fields1'>
                  <span>Phone</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>

                   <OutlinedInput
                            id="outlined-adornment-weight"
                            name="phone"
                            value={phone}
                            type='number'
                            placeholder="Phone number"
                            onChange={(event) => changePhone(event)}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                   </span>
                  </div>
                  <div className='fields1'>
                  <span>Photo</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                     
                    <OutlinedInput type="file" src={modImage} multiple accept="image/*" onChange={changeImage} />
           
                 
                    </span>
                  </div>
                  <br/>
                
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <div onClick={save} className='edit'>Save&nbsp;
                  
                    </div>
                  </Grid>
                </Grid>
                
                </>}
              <Grid item xs={12} md={12} lg={12}  style={{height: "150px", marginTop: "25px"}} >

               

  </Grid>
      
    <Grid item xs={12} md={5} lg={5} style={{marginLeft: "50px",marginRight: "20px"}}>
        
      
      </Grid>
      </Grid>
      </Grid>

    <Footer></Footer>
    </>
  )
}
export default withStyles(AuthenticationStyle)(Profile);
