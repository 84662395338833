import React, { useRef, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';

const CanvasInput = ({ onCanvasValueChange }) => {
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [value, setValue] = useState('');

  const handleMouseDown = () => {
    setDrawing(true);
  };

  const handleMouseUp = () => {
    setDrawing(false);
    // Capture the drawn content and process it if needed
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const dataURL = canvas.toDataURL(); // You can save or process this data
    setValue(dataURL);
    onCanvasValueChange(dataURL);
  };

  const handleMouseMove = (e) => {
    if (!drawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    ctx.lineTo(x, y);
    ctx.stroke();
  };
  const handleDown = (e) => {
    e.preventDefault(); // Prevent default touch actions, like scrolling
    setDrawing(true);
  };

  const handleUp = () => {
    setDrawing(false);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const dataURL = canvas.toDataURL();
    setValue(dataURL);
    onCanvasValueChange(dataURL);
  };

  const handleMove = (e) => {
    e.preventDefault(); // Prevent default touch actions, like scrolling
    if (!drawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const x = e.touches[0].clientX - canvas.offsetLeft;
    const y = e.touches[0].clientY - canvas.offsetTop;
    ctx.lineTo(x, y);
    ctx.stroke();
  };
  const handleClear = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setValue(''); // Clear the input value as well
    onCanvasValueChange('');
  };
  return (<>
    <div className='canvasTrack'>
      <div>
      <canvas
        ref={canvasRef}
        width={300}
        height={100}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleDown}
        onTouchEnd={handleUp}
        onTouchMove={handleMove}

      />
      </div>
     <div style={{paddingTop:"40px"}}>
     <span className='eraser' onClick={handleClear}><u>Erase</u></span>
     </div>
    
    </div>
     {/* <OutlinedInput
     value={value}
     placeholder="Draw on the canvas"
     fullWidth
     readOnly
   /> */}
   </>
  );
}

export default CanvasInput;
