import React, { useState } from "react";
import "./iotconfig.css";
import Allservices from "../Allservices";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import Footer from "../../Homepage/Footer";
import { Box, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import SendPackageStyle from "../Package/SendPackageStyle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import { getIotUrl } from "../../../api/apiManagement";

function IotConfig(props) {
  const { classes } = props;
  const iotUrl = getIotUrl() + "/api/devices/";
  const [formData, setFormData] = useState({
    device_id: "",
    device_name: "",
    device_description: "",
  });

  const [openCreate, setOpenCreate] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [details,setDetails] = React.useState()
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6,
      }}
    />
  );
  function handleCloseError() {
    setOpenCreate(false);
  }
  function handleCloseEdit() {
    setOpenEdit(false);
  }
  function handleopenError() {
    setFormData({
      device_id: "",
      device_name: "",
      device_description: "",
    });
    setOpenCreate(true);
  }
  function handleCloseEdit() {
    setOpenEdit(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEdit = (e) => {
    console.log("data", e);
    setFormData({
      ...formData, 
      device_id: e.device_id,
      device_name: e.device_name,
      device_description: e.device_description,
    });

console.log("fff",formData)
    setOpenEdit(true);
  };

  const items = [
    {
      title: "IOT45667",
      name: "IOT device 1",
      details: "New device",
    },
    {
      title: "IOT45667",
      name: "IOT device 1",
      details: "New device",
    },
    {
      title: "IOT45667",
      name: "IOT device 1",
      details: "New device",
    },
    {
      title: "IOT45667",
      name: "IOT device 1",
      details: "New device",
    },
    {
      title: "IOT45667",
      name: "IOT device 1",
      details: "New device",
    },
    {
      title: "IOT45667",
      name: "IOT device 1",
      details: "New device",
    },
  ];
    React.useEffect(() => {
    axios.get(iotUrl, {

  }).then((response) => {
    if(response){
      setDetails(response.data.device_info)
    }
      console.log("tttt",response)

  });
  }, [iotUrl]);
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpenCreate(true);
    console.log("json", JSON.stringify(formData));
    // You can perform form submission logic here
    axios.post(iotUrl, formData).then((res) => {
      try {
        console.log("fff", res);
        if (res) {
          setOpenCreate(false);
        }
      } catch (error) {
        console.error();
      }
    });
    console.log("Form submitted:", formData);
  };
  const handleSubmitedit = (e) => {
    // You can perform form submission logic here
    axios.post(iotUrl, formData).then((res) => {
      try {
        console.log("fff", res);
      } catch (error) {
        console.error();
      }
    });
    console.log("Form submitted:", formData);
  };
  const Card = ({ title, name, details, onClick }) => {
    return (
      <div className="Card-config" onClick={onClick}>
        <h3>{title}</h3>
        <p>{name}</p>
        <p>{details}</p>
      </div>
    );
  };

  return (
    <Box className="main">
      <Grid container className={classes.section_start}>
        <Grid container className={classes.newbgIot}>
          <Grid container className={classes.secn2}>
            <Grid container className={classes.ht}>
              <Grid container className={classes.send_bg} >
                <Grid item xs={8} md={5} lg={5}>
                  <div className={classes.heading}>
                    <div className={classes.send_heading}>
                      IOT Configurations
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} md={7} lg={7}>
                  <div className={classes.send_header}>
                    <ColoredLine color="#131C4C" />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.vatiot}>
                  Configure Iot devices details here.
                </Grid>
                <Dialog
                  open={openEdit}
                  onClose={handleCloseEdit}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Grid item xs={12} md={12} lg={12}>
                        <form className="form" onSubmit={handleSubmitedit}>
                          <div className={classes.contentiot}>
                            <div className="labelled-config">
                              Edit Iot devices configuration
                            </div>

                            <Grid item xs={12} md={8} lg={8}>
                              <div className="labelled">Iot device Id</div>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="iotId"
                                  type="text"
                                  value={formData.device_id}
                                  onChange={handleChange}
                                  placeholder="Enter Iot device Id"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/imigo.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8} lg={8}>
                              <div className="labelled">Iot device Name</div>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="iotName"
                                  value={formData.device_name}
                                  onChange={handleChange}
                                  placeholder="Enter Iot device Name"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/imigo.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8} lg={8}>
                              <div className="labelled">Iot device Details</div>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="iotDetails"
                                  value={formData.device_description}
                                  onChange={handleChange}
                                  //onChange={(event) => ImgNumber(event)}
                                  placeholder="Enter Iot device details"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/imigo.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>
                            <br />
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ display: "flex" }}
                            >
                              <Grid item xs={10} md={4} lg={4}>
                                <button
                                  onClick={ handleCloseEdit}
                                  type="submit"
                                  className={classes.service_btnCancel}
                                >
                                  Cancel
                                </button>
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid item xs={10} md={4} lg={4}>
                                <button
                                  onClick={handleCloseEdit}
                                  type="submit"
                                  className={classes.service_btnsubmit}
                                >
                                  Submit
                                </button>
                              </Grid>
                            </Grid>
                          </div>
                        </form>
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
                <Dialog
                  open={openCreate}
                  onClose={() => handleCloseError()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Grid item xs={12} md={12} lg={12}>
                        <form className="form" onSubmit={handleSubmit}>
                          <div className={classes.contentiot}>
                            <div className="labelled-config">
                              Create Iot devices configuration
                            </div>

                            <Grid item xs={12} md={8} lg={8}>
                              <div className="labelled">Iot device Id</div>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  name="device_id"
                                  type="text"
                                  value={formData.device_id}
                                  onChange={handleChange}
                                  placeholder="Enter Iot device Id"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/imigo.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8} lg={8}>
                              <div className="labelled">Iot device Name</div>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  name="device_name"
                                  value={formData.device_name}
                                  onChange={handleChange}
                                  placeholder="Enter Iot device Name"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/imigo.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8} lg={8}>
                              <div className="labelled">Iot device Details</div>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  name="device_description"
                                  value={formData.device_description}
                                  onChange={handleChange}
                                  //onChange={(event) => ImgNumber(event)}
                                  placeholder="Enter Iot device details"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/imigo.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>
                            <br />
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ display: "flex" }}
                            >
                              <Grid item xs={10} md={4} lg={4}>
                                <button
                                  onClick={() => handleCloseError()}
                                  type="submit"
                                  className={classes.service_btnCancel}
                                >
                                  Cancel
                                </button>
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid item xs={10} md={4} lg={4}>
                                <button
                                  type="submit"
                                  className={classes.service_btnsubmit}
                                >
                                  Submit
                                </button>
                              </Grid>
                            </Grid>
                          </div>
                        </form>
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
                <Grid item xs={12} md={12} lg={12} className="iot-start">
                  
                  <div className="dash-config">
                    <div className="labelled-config">Available Iot devices</div>
                    <div
                      className="labelled-config-create"
                      onClick={() => handleopenError()}
                    >
                      {" "}
                      Create{" "}
                    </div>
                  </div>
                {details && details !== undefined && details.length && details.length !== 0 ? (
                  <div className="CardContainer">
                    {details.map((item, index) => (
                      <Card
                        key={index}
                        title={item.device_id}
                        name={item.device_name}
                        details={item.device_description}
                        onClick={() => handleEdit(item)}
                      />
                    ))}
                  </div>):(<>
                    <Grid xs={12} md={12} lg={12}>
                   <div className='center3'>
                   <div className="labelled-config"> No Devices Found</div>
                  
                   
                    </div>
                   </Grid>
                  
                  </>)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withStyles(SendPackageStyle)(IotConfig);
