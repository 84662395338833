import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { withStyles } from "@material-ui/core/styles";
import AuthenticationStyle from "../../Styles/AuthenticationStyle";
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { Input, InputAdornment } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import Allservices from './Allservices';
import Services from '../Homepage/Services';
import Flow from '../Homepage/Flow';
import Purpose from '../Homepage/Purpose';
import AskedQuestions from '../Homepage/AskedQuestions';
import Footer from '../Homepage/Footer';
import axios from 'axios';
import { getBaseURL } from '../../api/apiManagement';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Radio from '@mui/material/Radio';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
const Users =(props) => {
    const navigate = useNavigate();
  const { classes } = props;
  const [data, setData] = React.useState({
    password: "",
    curr_password:""


  })
  const [selectedValue, setSelectedValue] = React.useState('ACTIVE');
  const [selectedUser, setSelectedUser] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleChange = (event,ind,user) => {
    setSelectedValue(event.target.value);
    setSelectedUser(user._id);
    console.log("hhh",event.target.value)
    if(event.target.value !=='INACTIVE'){
    axios.put(url2, {
       
            _id: user._id,
            user_status: event.target.value
        
    }).then((response) => {

    
        axios.get(url2,{
          params: {
          company_id:localStorage.getItem("companyId")
          }
      
  }).then((response) => {

            console.log("yyy",response)
            if(response.data && response.data.vendors_collection){
              setUsers(response.data.vendors_collection);
            }
           
      
        });

    });
  }
  else{
    setOpenDialog(true);
  }

  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOkClick = (user) => {
    console.log("gg",user)
    axios.put(url2, {
       
      _id: selectedUser,
      user_status: 'INACTIVE'
  
}).then((response) => {


  axios.get(url2,{
    params: {
    company_id:localStorage.getItem("companyId")
    }

}).then((response) => {

      console.log("yyy",response)
      if(response.data && response.data.vendors_collection){
        setUsers(response.data.vendors_collection);
      }
     

  });

});
    // Add logic for the "OK" button click
    setOpenDialog(false);
  };
  const [data1,setData1] = React.useState()
  const [edit,setEdit] = React.useState(false)
  const [name,setName] = React.useState()
  const [phone,setPhone] = React.useState()
  const [image,setImage] = React.useState([])
  const [modImage,setModImage] = React.useState()
  const [users,setUsers] = React.useState()
  const url = getBaseURL() + "/vendor/api/v1/changepassword"
  const url2 = getBaseURL() + "/vendor/api/v1/corporateuser"

  useEffect(() => {

    axios.get(url2, {
        params: {
            _id: localStorage.getItem("userId")
        }
    }).then((response) => {

        //console.log(response)
        setData1(response.data);
        setModImage(response.data.vendors_collection[0].image)
        setName(response.data.vendors_collection[0].name)
        setPhone(response.data.vendors_collection[0].phone)

    });

    axios.get(url2).then((response) => {

      console.log("yyy",response)
      if(response.data && response.data.vendors_collection){
        setUsers(response.data.vendors_collection);
      }
     

  });

}, [url2]);
 
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6
      }}
    />
  );
  return (<>
    <Allservices></Allservices>
    <Grid container className={classes.coperate_start2}>
 
    <Grid container className={classes.ht}  >
       
      
              <Grid container xs={12} md={12} lg={12}  style={{ marginTop: "20px",marginLeft:"60px",marginRight:"60px"}} >
              <Grid item xs={4} md={2} lg={2}>
              <div className={classes.heading}>
                   
                   <div className='profile'> Users</div>
                 </div>
                 </Grid>
                 <Grid item xs={4} md={10} lg={10}>
                  <div  className='profilen'>
                    <ColoredLine color="#131C4C" />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} style={{ height: "auto" ,marginTop:"20px"}}>
  {users !== undefined ? (
    <>
    <table>
      <thead>
        <tr>
        <th style={{textAlign:"left"}}>Sl No.</th>
          <th style={{textAlign:"left"}}>Name</th>
          <th style={{textAlign:"left"}}>Email</th>
          <th style={{textAlign:"left"}}>Phone</th>
          <th style={{textAlign:"left"}}>Access Type</th>
          <th style={{textAlign:"left"}}>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <>
          <tr key={index}>
          <td style={{width:"80px"}}>{index + 1}</td>
            <td style={{width:"150px"}}>
              <div className='userCont'>
                {user.name}
              </div>
            </td>
            <td style={{width:"150px"}}>
              <div className='userCont'>
                {user.email}
              </div>
            </td>
            <td style={{width:"150px"}}>
              <div className='userCont'>
                {user.phone}
              </div>
            </td>
            <td style={{width:"150px"}}>
              <div className='userCont'>
                {user.previlage_display}
              </div>
            </td>
            <td >
              <div className='userCont'>
                {user.user_status}
              </div>
            </td>
            <td>
              <div>
              
                <Radio
                  checked={user.user_status === 'ACTIVE'}
                  onChange={(event) => handleChange(event, index, user)}
                  value="ACTIVE"
                  name={`radio-buttons-${index}`}
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: 'green' }}
                />
                <Radio
                  checked={user.user_status === 'INACTIVE'}
                  onChange={(event) => handleChange(event, index, user)}
                  value="INACTIVE"
                  name={`radio-buttons-${index}`}
                  inputProps={{ 'aria-label': 'B' }}
                  style={{ color: 'red' }}
                />
              </div>
            </td>
          </tr>
           <Dialog open={openDialog} onClose={handleCloseDialog} BackdropProps={{ invisible: true }} PaperProps={{
    style: {
      border:'solid 2px grey',
      boxShadow: 'none',
    },
  }}>
           <DialogTitle>Jiffy Alert</DialogTitle>
           <DialogContent>
             {/* Add your content here */}
              Do you want to Deactivate this user?
           </DialogContent>
           <DialogActions>
             <Button onClick={()=>handleOkClick(user)} color="primary">OK</Button>
             <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
           </DialogActions>
         </Dialog>
         </>
        ))}
      </tbody>
    </table>
   
  </>
  ) : (<></>)}
</Grid>


  </Grid>
      
    <Grid item xs={12} md={5} lg={5} style={{marginLeft: "50px",marginRight: "20px"}}>
        
   
      </Grid>
      </Grid>
      </Grid>

    <Footer></Footer>
    </>
  )
}
export default withStyles(AuthenticationStyle)(Users);
