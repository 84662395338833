import React, { Component ,forwardRef} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Checkbox, Grid, IconButton, InputAdornment, OutlinedInput} from '@mui/material';
import Box from '@mui/material/Box';
import TrackorderStyle from '../TrackOrders/TrackorderStyle';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { useParams } from "react-router-dom";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Pdf from "react-to-pdf";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import DatePicker from 'react-datepicker'
import moment from "moment";
import Pagination from '@mui/material/Pagination';
import { getBaseURL } from '../../../api/apiManagement';
import clsx from "clsx";
import { useNavigate } from "react-router-dom";


const Updateorder = (props) => {
  const navigate = useNavigate();
    let dispalyData;
    const { id } = useParams();
    const url = getBaseURL() + "/vendor/api/v1/parcel"
    const { classes } = props;
    const [data, setData] = React.useState();
    const [dataParcel, setDataParcel] = React.useState();
    const [datanew, setDataNew] = React.useState();
    const [selectedValue, setSelectedValue] = React.useState('');
    const [estimatedprice, setPrice] = React.useState('');
    const [quantity, setQuantity] = React.useState('');

    const [bag, setbag] = React.useState('');
    const [bagqty, setbagqty] = React.useState('');
    const [parking, setparking] = React.useState('');
    const [searched, setSearched] = React.useState('');

    const ref = React.createRef();
    let [pageNo,setPageNo] = React.useState(1);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    /*React.useEffect(() => {

        axios.get(url, {
            params: {
                _id: id
            }
        }).then((response) => {

            console.log(response)
            setData(response.data.parcel[0])

        });



    }, [url]);*/
    const onChangeDate = (dates) => {
      setDataParcel(undefined)
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if(end){
        axios.get(url, {
            params: {
                order_status:'Delivered',

                userId:localStorage.getItem("userId").toString(),
                company_id:localStorage.getItem("companyId").toString(),
                from_date:moment(start).format("DD/MM/YYYY"),
                to_date:moment(end).format("DD/MM/YYYY")
            }
        }).then((response) => {

            setDataParcel(response.data.parcel)

        });
    }
    };
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <OutlinedInput
            id="outlined-adornment-password"
            onClick={onClick}
            ref={ref}
            value={value}
            className={classes.datePicker}
            startAdornment={<InputAdornment position="start"><img src="./Images/calendar-alt.png" width={17} height={19} alt='calender' /></InputAdornment>}
          
            placeholder="Date From - Date To"
        />
    ));
    const [searchRes, setSearchRes] = React.useState(false);
    const searchOrder = (val) => {
      setSearched(val.target.value)
      console.log("uuuu",val.target.value)
        axios.get(url, {
           params:{
            order_id:val.target.value,
                //userId: localStorage.getItem("userId"),
               // company_id:localStorage.getItem("companyId") 
                
              
            }

        }).then((response) => {
      
         if(response && response.data && response.data.parcel&& response.data.parcel.length){
          setDataParcel(response.data.parcel)
          setPrice(response.data.parcel[0].estimatedPrice)
          if(response?.data?.parcel[0]?.parking_fee)
          setparking(response.data.parcel[0].parking_fee)
          if(response.data.parcel[0].blackBag.active){
            setSelectedValue('blackbag')
            setQuantity(response.data.parcel[0].blackBag.quantity)
          }
          if(response.data.parcel[0].A3.active){
            setSelectedValue('A3')
            setQuantity(response.data.parcel[0].A3.quantity)
          }
          if(response.data.parcel[0].whiteBag.active){
            setSelectedValue('whitebag')
            setQuantity(response.data.parcel[0].whiteBag.quantity)
          }
          setSearchRes(true)
          if(val.target.value === ''){
            setDataParcel([])
          }
         }
         else{
          setDataParcel([])
         }
      
       //console.log("INVOICESS", response)
          window.scroll(0,0)

     });
     
    }
  
    const decreasePage = (er,newpage) => {
      setDataParcel(undefined)
     // console.log("ddd",newpage)
        pageNo = newpage;
        setPageNo(parseInt(newpage))
        axios.get(url, {
            params:{
                order_status:'invoice',
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
                noOfRecord:"10",
                pageNumber: pageNo
            }

        }).then((response) => {
            //setDataParcel(response.data.parcel)
            setDataParcel(response.data.parcel)
           // console.log("INVOICESS", response)
            window.scroll(0,0)

        });
    }
    const addPageNumber = (e) => {
      pageNo = e;
     setPageNo(parseInt(e))
     // console.log("fff",pageNo)
      axios.get(url, {
          params: {
              userId: localStorage.getItem("userId"),
              company_id:localStorage.getItem("companyId"),
              noOfRecord:"10",
              pageNumber: parseInt(e)
          }
      }).then((response) => {
  
         // console.log(response)
          setDataParcel(response.data.parcel)
          window.scroll(0,0)
  
      });
  }
    const addPage = () => {
        pageNo = pageNo +1;
       setPageNo(parseInt(pageNo))
        axios.get(url, {
            params:{
                order_status:'invoice',
                userId: localStorage.getItem("userId"),
                company_id:localStorage.getItem("companyId"),
                noOfRecord:"10",
                pageNumber: pageNo
            }

        }).then((response) => {
            //setDataParcel(response.data.parcel)
            setDataParcel(response.data.parcel)
          //  console.log("INVOICESS", response)
            window.scroll(0,0)

        });
    }
    React.useEffect(() => {

      axios.get(url, {
        params:{
            order_status:'invoice',
            userId: localStorage.getItem("userId"),
            company_id:localStorage.getItem("companyId"),
            noOfRecord:"10",
            pageNumber: pageNo
        }

    }).then((response) => {
        //setDataParcel(response.data.parcel)
       // setDataParcel(response.data.parcel)
      //  console.log("INVOICESS", response)


    });
    



    }, [url]);
  
    const handleEstimatedPriceChange = (index) => (event) =>{
      setPrice(event.target.value)
    }
    const handlePriceChange = (index) => (event) => {
  
      setQuantity(event.target.value)
      // const newItems = [...items];
      // newItems[index].estimatedPrice = event.target.value;
      //setItems(newItems);
    };
  
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
    const handleChangepark = (event) =>{
      setparking(event.target.value)
    }
    function updateorder (key){
        
        let params={};
        params["_id"] = key._id;
        params["userId"] = localStorage.getItem("userId")
        params["company_id"] = localStorage.getItem("companyId")
        params["estimatedPrice"] = estimatedprice

        // params["blackBag"] = {active: true, quantity: 1}
        // params["A3"] = {active: true, quantity: 1}
        // params["whiteBag"] =  {active: true, quantity: 1}

        if(selectedValue === 'A3'){
          params["blackBag"] = {active: false, quantity: 1}
          params["A3"] = {active: true, quantity: quantity}
          params["whiteBag"] =  {active: false, quantity: 1}
        }
        if(selectedValue === 'blackbag'){
          params["blackBag"] = {active: true, quantity: quantity}
          params["A3"] = {active: false, quantity: 1}
          params["whiteBag"] =  {active: false, quantity: 1}
        }
        if(selectedValue === 'whitebag'){
          params["blackBag"] = {active: false, quantity: 1}
          params["A3"] = {active: false, quantity: 1}
          params["whiteBag"] =  {active: true, quantity: quantity}
        }
        params["parking_fee"] = parking;
        axios.put(url, params).then((res) => {
         // console.log(res.data);
          //postId = res.data;
          if (res.data.status === "Success") {
            window.location.reload();
          // console.log("success");
            //postId = res.data.id;
            //setId(res.data.id);
          //  console.log(res);
            
          }
          try {
          } catch (error) {
           // console.error();
          }
        });
       
    }
  
  
    //React.useEffect(() => {

    //   axios.get(url, {
    //       params: {

    //      }
    //  }).then((response) => {

    //      console.log(response)
    //      setDataParcel(response.data.parcel)

    //   });



    // }, [url]);
    //console.log(data)
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={6} lg={6} className={classes.filterContainer1}>
            <span className={classes.filterText1}>Search</span>&nbsp;&nbsp;
            <OutlinedInput
              id="outlined-adornment-password"
              // onClick={searchOrder(value)}
              style={{ borderRadius: "31px", height: "51px" }}
              className={classes.datePicker5}
              onChange={(event) => searchOrder(event)}
              placeholder="Order id"
            />
            <img src="./Images/search.svg"></img>
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className={classes.filterContainer}
            textAlign="right"
          >
            <FilterListIcon />
            <p className={classes.filterText}>Filter by date</p>
            <DatePicker
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={<ExampleCustomInput />}
              calendarClassName={classes.calender}
            />&nbsp;&nbsp;
             <img onClick={resetData} title='refresh' style={{cursor:'pointer'}} src="./Images/undo.svg"></img>
          </Grid> */}
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6} md={6} lg={6}>
            {/*} <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="onGoing" control={<Radio />} label="Ongoing" />
                        <FormControlLabel value="completed" control={<Radio />} label="Completed" />
                    </RadioGroup>
                </FormControl>*/}
          </Grid>
          <Grid item xs={6} md={6} lg={6}></Grid>
        </Grid>

        <div className="searchRes">
          {searched !== "" && searchRes && dataParcel.length === 0  ? (
            <>
              <span>No Active Orders Found</span>
            </>
          ) : (<></>)
  
          
          }
        </div>
        <div className="searchRes">
          {searched === "" ||  dataParcel === undefined ? (
            <>
              <span>Search for Active Orders</span>
            </>
          ) : (<></>)
  
          
          }
        </div>
        {dataParcel !== undefined ? (
          (dispalyData = dataParcel.map((item, index) =>
            item.order_status ? (
              <Box className={classes.deliveryIn}>
                <Grid container ref={ref} className={classes.Invoiced}>
                <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.stepperIn}
                  >
                      <Grid xs={12} md={12} lg={12}>
                      <p className={classes.stepperBold}>
                        Order ID :{" "}
                        <span className={classes.stepperBold}>
                          {item.order_id}
                        </span>
                      </p>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid  xs={12} md={6} lg={6}>
                    <p className={classes.stepperBold}>
                       ESTIMATED PRICE:{" "}
                        </p>
                        <br/>
                     <FormControl
                                
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="mobileNo"
                                value={estimatedprice}
                                onChange={handleEstimatedPriceChange(index)}
                                  placeholder="Estimated Price"
                                  startAdornment={
                                    <InputAdornment position="start">
                                     
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput3}
                                />
                              </FormControl>
                    </Grid>
                    <Grid  xs={12} md={6} lg={6}>
                    <p className={classes.stepperBold}>
                       BAG INFO:{" "}
                        </p>
                        <br/>
                     <FormControl
                           
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                 <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={selectedValue} // Bind the state value to RadioGroup
        onChange={handleChange} // Attach the change handler
      >
        <FormControlLabel value="A3" control={<Radio />} label="A3" />
        <FormControlLabel value="blackbag" control={<Radio />} label="Black bag" />
        <FormControlLabel value="whitebag" control={<Radio />} label="White bag" />
      </RadioGroup>
   
                                {/* <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="mobileNo"
                                value={item.estimatedPrice}
                                 
                                  placeholder="Estimated Price"
                                  startAdornment={
                                    <InputAdornment position="start">
                                     
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput3}
                                /> */}
                              </FormControl>
                              <Grid  xs={12} md={6} lg={6}>
                    <p className={classes.stepperBold}>
                       QUANTITY{" "}
                        </p>
                        <br/>
                     <FormControl
                                
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="mobileNo"
                                value={quantity}
                                onChange={handlePriceChange(index)}
                                  placeholder="Estimated Price"
                                  startAdornment={
                                    <InputAdornment position="start">
                                     
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput3}
                                />
                              </FormControl>
                    </Grid>
                    </Grid>
                   
                    <Grid  xs={12} md={6} lg={6}>
                    <br/><br/>
                    <p className={classes.stepperBold}>
                       PARKING FEES:{" "}
                        </p>
                        <br/>
                     <FormControl
                           
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="mobileNo"
                                  value={parking}
                                  onChange ={handleChangepark}
                                  placeholder="Parking fee"
                                  startAdornment={
                                    <InputAdornment position="start">
                                     
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput3}
                                />
                              </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                    <br/>
          <br/>
            <button
              className="download-button" onClick={()=>updateorder(item)}
             
            >
              <span style={{cursor: 'pointer'}}>Update</span> &nbsp;&nbsp;
            
            </button>

          
          </Grid>
                </Grid>
                </Grid>
              </Box>
            ) : (
              <></>
            )
          ))
        ) : (
          <> 
          </>
        )}

      </>
    );

}

export default withStyles(TrackorderStyle)(Updateorder);