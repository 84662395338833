import React, { useEffect, useRef, useState ,useSearchParams} from "react";
import { withStyles } from "@material-ui/core/styles";
import AuthenticationStyle from "../../Styles/AuthenticationStyle";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import "./tracked.css";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Footer from "../Homepage/Footer";
import DrawableInput from "./DrawableInput";
import CanvasInput from "./DrawableInput";
import { getIotUrl } from "../../api/apiManagement";
import axios from "axios";
import { useLocation,useNavigate } from 'react-router-dom';

const DocumentTrackerGlobal = (props) => {
  const docUrl = getIotUrl() + "/api/doc_event/";
  const iotUrl = getIotUrl() + "/api/document/";
  const { classes } = props;
  const listElement = useRef(null);
  const navElement = useRef(null);
  const [currentView, setCurrentView] = useState("services");
  const [name, setName] = useState();
  const [designation, setDesignation] = useState();
  const [department, setDepartment] = useState();
  const [comments, setComments] = useState();
  const [signature, setSignature] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [imageUrl, setImageUrl] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [docId, setDocId] = useState(null);
  const [submitbtn, setSubmitbtn] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser.');
    }
  }, []);
  React.useEffect(() => {
    axios.get(iotUrl, {
      params:{
        device_id:queryParams.get('id')
      }
    }).then((response) => {
      console.log("tyyy", response);
      if(response && response.data.data){
       setDocId(response.data.data[0].id)
      }
      
    });
  }, [iotUrl]);
  const handleSubmit = () => {
    console.log("lat",)
  
    let obj = {
      person_name: name,
      person_designation: designation,
      person_department: department,
      person_signature: canvasValue,
      person_comment: comments,
      latitude: latitude.toFixed(6),
      longitude: longitude.toFixed(6),
      iot_device: queryParams.get('id'),
      document: docId
    };
    console.log("ggg",obj)
    axios.post(docUrl, obj).then((res) => {
      console.log(res.data.data);
      if (res.data.status === "success") {
        
        console.log(res);
        setSubmitbtn(true);
      }
      try {
      } catch (error) {
        console.error();
      }
    });
  };
  const trackName = (val) => {
    setName(val.target.value);
  };
  const trackDesignation = (val) => {
    setDesignation(val.target.value);
  };
  const trackDepartnment = (val) => {
    setDepartment(val.target.value);
  };
  const trackComments = (val) => {
    setComments(val.target.value);
  };
  const [canvasValue, setCanvasValue] = useState("");

  const handleCanvasValueChange = (newValue) => {
    
    console.log("ggg", newValue);
    const base64String = newValue; // Replace with your Base64 string
  const fileName = 'myImage'; // Replace with your desired filename (without extension)

  const imageURL = base64ToJPEG(base64String, fileName);
  setCanvasValue(newValue);
  };
  function base64ToJPEG(base64, fileName) {
    // Remove data URI scheme and prepare the Base64 string
    const base64WithoutHeader = base64.replace(/^data:\w+\/\w+;base64,/, '');
  
    // Convert the Base64 string to a Blob
    const blob = new Blob([atob(base64WithoutHeader)], { type: 'image/jpeg' });
  
    // Create a File object with the .jpg extension
    const file = new File([blob], fileName + '.jpg', { type: 'image/jpeg' });
  
    return URL.createObjectURL(file);
  }
  function move() {}
  const onKeyUp = () => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-menu");
    setTimeout(function () {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
      listElement.current.classList.toggle("active");
      navElement.current.classList.toggle("active");
      //console.log(listElement)
    }, 500);
  };

  const focussed = () => {
    localStorage.removeItem("userId");
    navigate("/");
  };

  const pickupDesc = () => {};
  function support() {
    setCurrentView("support");
    const element = document.getElementById("contents");
    //console.log("🚀 ~ file: Authentication.jsx ~ line 81 ~ support ~ element", element)

    element.scrollIntoView();
  }
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6,
      }}
    />
  );
  return (
    <>
      <div className={classes.htTrack}>
        <nav className="navbar" ref={navElement}>
          <a href="" class="nav-logo">
            <img src="./Images/Jiffy_Lite.svg" className="logo"></img>
          </a>
          <ul class="nav-menu">
            <li class="nav-item">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          className={classes.newbtn}
                          {...bindTrigger(popupState)}
                        >
                          Our Services
                          <img
                            className={classes.ared}
                            src="./Images/arrowed.svg"
                          ></img>
                        </Button>
                      </Grid>
                    </Grid>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>
                        <a className={classes.drp} href="#comps">
                          Send/Recieve Package
                        </a>
                      </MenuItem>
                      <MenuItem onClick={popupState.close}>
                        <a className={classes.drp} href="#comps">
                          {" "}
                          Document Attestation
                        </a>
                      </MenuItem>
                      <MenuItem onClick={popupState.close}>
                        <a className={classes.drp} href="#comps">
                          {" "}
                          Accompaniment
                        </a>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </li>

            <li class="nav-item">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button onClick={() => support()} id="contents">
                    <a className={classes.newbtn} href="#co">
                      Support
                    </a>
                  </Button>
                </Grid>
              </Grid>
            </li>
            <li></li>

            <div></div>
            <li class="nav-item">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <PersonOutlineIcon
                      className={classes.personel}
                    ></PersonOutlineIcon>
                    &nbsp;
                    <Button
                      className={classes.newbtn}
                      {...bindTrigger(popupState)}
                      onClick={focussed}
                    >
                      Login
                    </Button>
                  </React.Fragment>
                )}
              </PopupState>
            </li>
          </ul>
          <div
            id="ham"
            className="hamburger"
            onClick={onKeyUp}
            ref={listElement}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12} className="location">
          <Grid container className={classes.htTrack}>
            <div className={classes.location}>
              <p className="location-text">
                <span className={classes.htTrack}>
                  <a
                    href="https://www.google.com/maps/place/First+Gulf+Business+Center+L.L.C/@25.275864,55.3906433,13.5z/data=!4m5!3m4!1s0x3e5f5d8bf589793d:0xa36d2b6714157480!8m2!3d25.2819977!4d55.3982779"
                    target="_blank"
                  >
                    <div className="flexed" onClick={move}>
                      <LocationOnIcon
                        className={classes.locIcon}
                      ></LocationOnIcon>{" "}
                      <span>United Arab Emirates</span>
                    </div>
                  </a>
                </span>
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.newbgIot}>
        <Grid container className={classes.secn}>
          <Grid container className={classes.ht}>
            <Grid container className={classes.send_bg}>
              <div className={classes.start_track}>
              {!submitbtn ? (<>
                <Grid container className={classes.newbgTrack}>
                  <Grid container className={classes.ht}>
                    <Grid container className={classes.send_bg}>
                      <Grid item xs={8} md={6} lg={6}>
                        <div className={classes.heading}>
                          <div className={classes.send_heading}>
                            {" "}
                            Document Tracking
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4} md={6} lg={6}>
                        <div className={classes.send_header}>
                          <ColoredLine color="#131C4C" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                
                <Grid container className={classes.htTracked}>
                  <div className={classes.contentTrack}>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="desc">
                        Kindly fill in the details below:
                      </div>
                      <br />
                    </Grid>
                  </div>
                  <Grid item xs={12} md={12} lg={12} className="FormsTracked">
                    <Grid item xs={12} md={4} lg={4} className="forms-spec">
                      <div className={classes.send_label}>Name</div>
                      <FormControl
                        fullWidth
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          placeholder="Name"
                          name="pkinstruction"
                          onChange={(event) => trackName(event)}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          labelWidth={0}
                          className={classes.searchInput}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="forms-spec">
                      <div className={classes.send_label}>Designation</div>
                      <FormControl
                        fullWidth
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          placeholder="Designation"
                          name="pkinstruction"
                          onChange={(event) => trackDesignation(event)}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          labelWidth={0}
                          className={classes.searchInput}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="forms-spec">
                      <div className={classes.send_label}>Departnment</div>
                      <FormControl
                        fullWidth
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          placeholder="Select Departnment"
                          name="pkinstruction"
                          onChange={(event) => trackDepartnment(event)}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src="./Images/direction.svg"></img>
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          labelWidth={0}
                          className={classes.searchInput}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} className="FormsTracked">
                    <Grid item xs={12} md={4} lg={4} className="forms-spec">
                      <div className={classes.send_label}>e-Signature</div>
                      <CanvasInput
                        onCanvasValueChange={handleCanvasValueChange}
                      ></CanvasInput>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} className="forms-spec">
                      <div className={classes.send_label}>
                        Comments (if any)
                      </div>
                      <FormControl
                        fullWidth
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          placeholder="Please share any comments here…"
                          name="pkinstruction"
                          onChange={(event) => trackComments(event)}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src="./Images/direction.svg"></img>
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          labelWidth={0}
                          className={classes.searchInput}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
             
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={classes.content}>
                      <Grid container spacing={3}>
                        <Grid item md={3} lg={3}></Grid>
                        <Grid item xs={10} md={6} lg={6} className="flexed">
                          <button className={classes.service_btnReset}>
                            Reset
                          </button>
                          <button
                            className={classes.service_btnTrack}
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </Grid>
                        <Grid item md={3} lg={3}></Grid>
                      </Grid>
                    </div>
                    <br />
                    <br />
                  </Grid>
                 
                </Grid>
                </>
                ):(<>
           
               
               
                
               
                   
                  <div className="center4">
                  <button
                            className={classes.service_btnTrack}
                           
                          >
                           Thank you for Updating
                          </button>
                </div>
              
           
                   
                </>)}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} lg={12}>
          <div className="footed">
            <Footer></Footer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(AuthenticationStyle)(DocumentTrackerGlobal);
