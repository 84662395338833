import React, { useEffect, useLayoutEffect, forwardRef, useState } from "react";
import SendPackageStyle from "./SendPackageStyle";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid,TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Allservices from "../Allservices";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Checkbox from "@mui/material/Checkbox";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import MenuList from "@mui/material/MenuList";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@mui/icons-material/Check";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useNavigate } from "react-router-dom";
import ScheduleLater from "./ScheduleLater";
import Footer from "../../Homepage/Footer";
import DatePicker from "react-datepicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from '@mui/material/Backdrop';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import Configuration from '../../config.json'
import { getBaseURL } from "../../../api/apiManagement";
import Select from "@mui/material/Select";
import draftId from "../../AllService";
const blue = {
  50: "#131C4C",
  100: "#C2E0FF",
  200: "#FFFFFF",
  300: "#66B2FF",
  400: "#EBE3CA",
  500: "#EBE3CA",
  600: "#FFFFFF",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};
const names = [
  { value: "Online e-Visa", size: "43x55mm" },
  { value: "Visa", size: "43x55mm" },
  { value: "Passport", size: "40xmm" },
  { value: "ID Card", size: "40x60mm" },
];
const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #131c4c;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  height: 120px;
  border-radius: 60px;
  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    font-weight: 700;
    border-radius: 60px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
    border-radius: 60px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    border-radius: 60px;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
`;

const green = {
  500: "#000000",
};

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const Root = styled("span")(
  ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 75px;
    height: 35px;
    margin: 0px 15px;
    cursor: pointer;
    margin-top: 10px;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
      border-radius: 29px;
      display: block;
      height: 35px;
      width: 75px;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 29px;
      height: 29px;
      top: 3px;
      left: 5px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;
      transition: all 200ms ease;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: ${grey[500]};
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 41px;
        top: 3px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background: #131C4C;
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `
);

/*Drop DOWN Info */

const SendPackage = (props) => {
  const url = getBaseURL() + "/vendor/api/v1/parcel";
  const draftUrl = getBaseURL() + "/vendor/api/v1/draft";
  const AddressUrl = getBaseURL() + "/vendor/api/v1/savedaddress";
  const urlPhoto = getBaseURL() + "/vendor/api/v1/photoservice";
  const priceUrl = getBaseURL() + "/vendor/api/v1/price";
  const priceUrlPhoto = getBaseURL() + "/vendor/api/v1/photoprice";

  const { classes } = props;
  const navigate = useNavigate();
  const [alignmentPickup, setAlignmentPickup] = React.useState("AM");
  const [alignment, setAlignment] = React.useState("AM");
  const [alignmentTrans, setAlignmentTransport] = React.useState("car");
  const [immigoSet,setImmigoSet] = React.useState("single");
  const [alignmentDel, setAlignmentDel] = React.useState("AM");
  const [count, setCount] = React.useState(0);
  const [selected1, setSelected1] = React.useState(false);
  const [selected2, setSelected2] = React.useState(false);
  const [selected3, setSelected3] = React.useState(false);
  const [selected4, setSelected4] = React.useState(false);
  const [error1, setError1] = React.useState(false);
  const [error2, setError2] = React.useState(false);
  const [error3, setError3] = React.useState(false);
  const [error4, setError4] = React.useState(false);
  const [error5, setError5] = React.useState(false);
  const [error6, setError6] = React.useState(false);
  const [error7, setError7] = React.useState(false);
  const [error8, setError8] = React.useState(false);
  const [error9, setError9] = React.useState(false);
  const [error10, setError10] = React.useState(false);
  const [errorImg, setErrorImg] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);
  const [errorName1, setErrorName1] = React.useState(false);
  const [tabvalue, setTabValue] = React.useState(0);
  const [id, setId] = React.useState(0);
  const [pickupPrice, setPickupPrice] = React.useState();
  const [frMsg, setFrMsg] = React.useState("");
const [friday, setFriday] = React.useState(false);
const handleFriday = () =>{
  setFriday(false);
}
  const [openClause,setClause] = React.useState(false)
  const handleCloseClause = () =>{
    setClause(false)
  }
  const clause = () =>{
    setClause(true)
  }
  const handleChangePickup = (event, newAlignment) => {
    //alert(JSON.stringify(newAlignment))
    setAlignment(newAlignment);
  };
  const handleChangeDelivery = (event, newAlignment) => {
    //alert(JSON.stringify(newAlignment))
    setAlignmentDel(newAlignment);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6,
      }}
    />
  );
  const [openError, setOpenError] = React.useState(false);
  const [openBag, setBag] = React.useState(false);
  const handleBag = () =>{
    setBag(false);
  } 
  const [openDocError, setDocError] = React.useState(false);
  function handleCloseErrorDoc() {
    setDocError(false);
  }
  const [openLoc, setLoc] = React.useState(false);
  function handleCloseLoc() {
    setLoc(false)
  }
  function handleCloseError() {
    setOpenError(false);
  }
  const [prefer, setPrefer] = React.useState(false);
  const [preferIcon, setPreferIcon] = React.useState("black");
  function handleClosePrefer() {
    setPrefer(false);
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    height:'500px',
    overflowX:'scroll',
    "@media (max-width: 600px)": { 
      width: 350,
      top: '50%',
      left: '250%',
  }

  };
  const [placed, setPlaced] = React.useState(false);
  const handleSubmit = (event) => {
    
    let p = [];
    let pDetails = {};
    pDetails["id"] = "1";
    pDetails["pickup_location"] = address;
    pDetails["pickup_location_phone"] = phone;
    pDetails["pickupEmail"] = email;
    pDetails["pickUpName"] = pickupName;
    //pDetails["pickupTime"] = moment(pickup).format("hh:mm");
    pDetails["pickupTime"] =pickup
    pDetails["pickupTimeExact"] = moment(pickup).format("hh:mm");
   // pDetails["pickupTimeExact"] = pickup
    pDetails["pickupTimeDesc"] = alignmentPickup;
    pDetails["pickup_instruction"] = descript;
    pDetails["pickup_latitude"] = String(coordinate.lat);
    pDetails["pickup_longitude"] = String(coordinate.lng);
    pDetails["pickup_status"] = "pending";

    let pDetails1 = {};
    pDetails1["id"] = "2";
    pDetails1["pickup_location"] = address1;
    pDetails1["pickup_location_phone"] = phone1;
    pDetails1["pickupEmail"] = email1;
    pDetails1["pickUpName"] = pickupName1;
    pDetails1["pickupTime"] =pickup1

    //pDetails1["pickupTime"] = pickup1
    pDetails1["pickupTimeExact"] =  moment(pickup1).format("hh:mm")
    pDetails1["pickupTimeDesc"] = alignmentPickup1;
    pDetails1["pickup_instruction"] = descript1;
    pDetails1["pickup_latitude"] = String(coordinate1.lat);
    pDetails1["pickup_longitude"] = String(coordinate1.lng);
    pDetails1["pickup_status"] = "pending";

    let pDetails2 = {};
    pDetails2["id"] = "3";
    pDetails2["pickup_location"] = address2;
    pDetails2["pickup_location_phone"] = phone2;
    pDetails2["pickupEmail"] = email2;
    pDetails2["pickUpName"] = pickupName2;
    pDetails2["pickupTime"] = pickup2
    //pDetails2["pickupTime"] = pickup2
    pDetails2["pickupTimeExact"] = pickup2
    pDetails2["pickupTimeDesc"] = alignmentPickup2;
    pDetails2["pickup_instruction"] = descript2;
    pDetails2["pickup_latitude"] = String(coordinate2.lat);
    pDetails2["pickup_longitude"] = String(coordinate2.lng);
    pDetails2["pickup_status"] = "pending";

    p.push(pDetails);
    if (address1 !== null && !clear) {
      p.push(pDetails1);
    }
    if ((address2 !== null || address2 !== "") && email2 !== null && !clear) {
      p.push(pDetails2);
    }

    let d = [];
    let dDetails = {};
    dDetails["id"] = "1";
    dDetails["delivery_location"] = addressDelivery;
    dDetails["delivery_location_phone"] = phoneD;
    dDetails["deliveryEmail"] = emailD;
    dDetails["deliveryName"] = pickupNameD;
    dDetails["deliveryTime"] =drop
    dDetails["deliveryTimeExact"] =  moment(drop).format("hh:mm");
    dDetails["deliveryTimeDesc"] = alignment;
    dDetails["delivery_instruction"] = descriptD;
    dDetails["delivery_latitude"] = String(coordinateDelivery.lat);
    dDetails["delivery_longitude"] = String(coordinateDelivery.lng);
    dDetails["delivery_status"] = "pending";
    d.push(dDetails);

    var today = new Date();
    let postId;
    let product_list = [
      {
        image: "",
        qty: "2",
        product_name: "i",
        price: String(price),
      },
    ];
    event.preventDefault();
    console.log(event);
    let params = {};
    params["userId"] = localStorage.getItem("userId");
    params["company_id"] = localStorage.getItem("companyId");
    params["pickup"] = p;
    params["delivery"] = d;
    params["blackBag"] = {
      "active" : selected1,
      "quantity" : quanBlack
    }
    params["whiteBag"] = {
      "active" : selected2,
      "quantity" : quanWhite
    }
    params["A3"] = {
      "active" : selected3,
      "quantity" : quanA3
    }
   
    params["pickup_location"] = address;
    params["pickup_location_phone"] = email;
    params["pickupEmail"] = event.target[4].value;
    params["pickupTime"] = moment(pickup).format("hh:mm");
    params["pickupTimeDesc"] = alignment;
    params["pickup_instruction"] = descript;
    params["delivery_location"] = addressDelivery;
    params["delivery_location_phone"] = phoneD;
    params["deliveryEmail"] = emailD;
    params["deliveryTime"] = moment(drop).format("hh:mm");
    params["deliveryTimeDesc"] = alignment;
    params["delivery_instruction"] = descriptD;
    params["pickupCoordinates"] = coordinate;
    params["deliveryCoordinates"] = coordinateDelivery;
    params["pickup_status"] = "Pending";
    params["delivery_type"] = "delivernow";
    params["delivery_status"] = "Pending";
    params["pickup_latitude"] = String(coordinate.lat);
    params["pickup_longitude"] = String(coordinate.lng);
    params["delivery_latitude"] = String(coordinateDelivery.lat);
    params["delivery_longitude"] = String(coordinateDelivery.lng);
    params["stop_latitude"] = String(coordinateStop.lat);
    params["stop_longitude"] = String(coordinateStop.lng);
    params["order_amt"] = String(price);
    params["estimatedPrice"] = String(price);
    params["customer_name"] = "jiffy";
    params["customer_id"] = "JIFFY" + uuidv4();
    params["customer_phone"] = "8075095847";
    params["ordered_date"] = today;
    params["order_payment_method"] = "CARD";
    params["order_status"] = "Pending";
    params["pickup_status"] = "Pending";
    params["delivery_status"] = "Pending";
    params["compensation_phone"] = "Pending";
    params["package_size"] = "1kg";
    params["is_web"] = "true";
    params["product_list"] = product_list;
    params["emiratesId"] = inputFieldsCompany;
    params["passport"] = inputFields;
    params["mCerti"] = inputFieldsMcerti;
    params["bCerti"] = inputFieldsBcerti;
    params["dCerti"] = inputFieldsDcerti;
    params["other"] = inputFieldsOther;
    params["transport"] = alignmentTrans;
    params["immigoCaseNumber"] = imigo ? imigo : '';
    params["deliveryPartnerPrice"] = dprice;
    params["immigoType"] = immigoSet
    if(draftId.value){
      params["_id"] = draftId.value;
    }
    console.log(JSON.stringify(params));
    if( pDetails["pickup_latitude"] === "" || pDetails["pickup_latitude"] === "undefined" || dDetails["delivery_latitude"] === "undefined" ||  dDetails["delivery_longitude"] === ""){
      setLoc(true)
    }
    if (pDetails["pickup_location"] === null) {
      setError1(true);
    } else {
      setError1(false);
    }
    if (pDetails["pickup_location_phone"] === null) {
      setError2(true);
    } else {
      setError2(false);
    }
    if (pDetails["pickupEmail"] === null) {
      setError3(true);
    } else {
      setError3(false);
    }
   
    if (pDetails["pickUpName"] === null) {
      setErrorName(true);
    } else {
      setErrorName(false);
    }
    if (dDetails["deliveryName"] === null) {
      setErrorName1(true);
    } else {
      setErrorName1(false);
    }
    if (
      pDetails["pickupTime"] === null ||
      pDetails["pickupTime"] === "Invalid date"
    ) {
      setError4(true);
    } else {
      setError4(false);
    }
    if (pDetails["pickup_instruction"] === null) {
      setError5(true);
    } else {
      setError5(false);
    }
    if (d[0].delivery_location === null) {
      setError6(true);
    } else {
      setError6(false);
    }
    if (dDetails["delivery_location_phone"] === null) {
      setError7(true);
    } else {
      setError7(false);
    }
    if (dDetails["deliveryEmail"] === null) {
      setError8(true);
    } else {
      setError8(false);
    }
   
    if (
      dDetails["deliveryTime"] === null ||
      dDetails["deliveryTime"] === "Invalid date"
    ) {
      setError9(true);
    } else {
      setError9(false);
    }
    if (dDetails["delivery_instruction"] === null) {
      setError10(true);
    }
    if (
      params["immigoCaseNumber"] === "" ||
      params["immigoCaseNumber"] === null 
    ) {
      setErrorImg(true);
    } else {
      setErrorImg(false);
    }
    let fixDate = new Date().setHours(17, 0, 0); // for 3:00:00 pm
    let currDate = pickup;

    if (fixDate < currDate) {
      setOpenError(true);
    }
   
    let errorDoc = false
    if(inputFieldsCompany.length === 0 && inputFields.length === 0 && inputFieldsMcerti.length === 0 && inputFieldsBcerti.length === 0 && inputFieldsDcerti.length === 0 && inputFieldsOther.length === 0){
      errorDoc = true;
      setDocError(true)
    }
    if(!selected1 && !selected2 && !selected3){
      setBag(true);
    }

    if (
      fixDate > currDate &&
      pDetails["pickup_location"] !== null &&
      pDetails["pickup_location_phone"] !== null &&
      pDetails["pickupEmail"] !== null &&
      pDetails["pickupTime"] !== null &&
      pDetails["pickup_instruction"] !== null &&
      dDetails["delivery_location"] !== null &&
      dDetails["delivery_location_phone"] !== null &&
      dDetails["deliveryEmail"] !== null &&
      dDetails["deliveryTime"] !== null &&
      dDetails["delivery_instruction"] !== null &&
      ((immigoSet === 'single' && params["immigoCaseNumber"] !== "" &&
      params["immigoCaseNumber"] !== null) || immigoSet === 'multiple') && errorDoc !== true && openLoc === false &&
      (selected1 || selected2 || selected3) &&  (pDetails["pickup_latitude"] !== "") && (dDetails["delivery_latitude"] !== "undefined") 
     ) {
      setPlaced(true)
      setLoaded("Loading...");
      axios.post(url, params).then((res) => {
        console.log(res.data);
        postId = res.data;
        if (res.data.status === "Success") {
          postId = res.data.id;
          setId(res.data.id);
          console.log(id);
          setTimeout(() => {
            navigate("/allorders");
          }, 3000);
        }
        else{
          setFriday(true);
    setFrMsg(res.data.msg)
        }
        try {
        } catch (error) {
          console.error();
        }
      });
      if(photoChecked){
        let params = {
          place: address,
          phone_number: phone,
          user_email_address: email,
          pick_up_time: moment(pickup).format("hh:mm"),
          am_or_pm: alignmentPickup,
          name: pickupName,
          photo_specification: photoValue,
          instruction: descript,
          price: pricePhoto,
          order_status: "Pending",
          user_id: localStorage.getItem("userId"),
        };
        axios.post(urlPhoto, params).then((res) => {
          console.log(res.data);
          // postId = res.data
          if (res.data.status === "Success") {
           // navigate("/allorders");
            // postId = res.data.id
            //setId(res.data.id);
            //  console.log(id);
          }
          try {
          } catch (error) {
            console.error();
          }
        });
      }
    
    }
  };
  const [pickTime, setData3] = React.useState('');
  const [pickTime1, setData31] = React.useState('');
  const [pickTime2, setData32] = React.useState('');
  const [dropTime, setData4] = React.useState('');

  useEffect(() => {
    if(draftId.value !== ''){
    let isMounted = true;
    //let res;
   
    axios
      .get(draftUrl, {
        params: {
          _id: draftId.value,
        },
      })
      .then((response) => {
        //setLoad(false)
        if (isMounted) {
          if(response.data.parcel[0].delivery_type && response.data.parcel[0].delivery_type === 'deliverlater' || response.data.parcel[0].delivery_type === 'deliverLater'){
            setActiveTab(2)
            setTabValue(1)
          }
          else{
            setActiveTab(1)
            setTabValue(0)
          }
        
         // setFlaged(true)
          console.log(response);
          setImigo(response.data.parcel[0].immigoCaseNumber)
          setAddress(response.data.parcel[0].pickup[0].pickup_location);
          setCoordinates({lat: response.data.parcel[0].pickup[0].pickup_latitude,long: response.data.parcel[0].pickup[0].pickup_longitude})
          setPhone(response.data.parcel[0].pickup[0].pickup_location_phone);
          setemail(response.data.parcel[0].pickup[0].pickupEmail);
          setPickupName(response.data.parcel[0].pickup[0].pickUpName);
          setData3(response.data.parcel[0].pickup[0].pickupTimeExact);
          setAlignmentPickup(response.data.parcel[0].pickup[0].pickupTimeDesc);
          setdesc(response.data.parcel[0].pickup[0].pickup_instruction);
         setPrice(response.data.parcel[0].estimatedPrice)

         setAddressDelivery(response.data.parcel[0].delivery[0].delivery_location);
         setCoordinatesDelivery({lat: response.data.parcel[0].delivery[0].delivery_latitude,long: response.data.parcel[0].delivery[0].delivery_longitude})
         setPhoneD(response.data.parcel[0].delivery[0].delivery_location_phone);
          setemailD(response.data.parcel[0].delivery[0].deliveryEmail);
          setPickupNameD(response.data.parcel[0].delivery[0].deliveryName ? response.data.parcel[0].delivery[0].deliveryName : response.data.parcel[0].delivery[0].pickUpName ? response.data.parcel[0].delivery[0].pickUpName : "")
          setData4(response.data.parcel[0].delivery[0].deliveryTimeExact);
          setAlignment(response.data.parcel[0].delivery[0].deliveryTimeDesc);
          setdescD(response.data.parcel[0].delivery[0].delivery_instruction);
          if(response.data.parcel[0].pickup[1] && response.data.parcel[0].pickup[1].pickup_location){
            setAddress1(response.data.parcel[0].pickup[1].pickup_location);
            setPhone1(response.data.parcel[0].pickup[1].pickup_location_phone);
            setemail1(response.data.parcel[0].pickup[1].pickupEmail);
            setPickupName1(response.data.parcel[0].pickup[1].pickUpName)
            setData31(response.data.parcel[0].pickup[1].pickupTime);
            setAlignmentPickup(response.data.parcel[0].pickup[1].pickupTimeDesc);
           setdesc1(response.data.parcel[0].pickup[1].pickup_instruction);
         }
          if(response.data.parcel[0].pickup[2] && response.data.parcel[0].pickup[2].pickup_location){
            setAddress2(response.data.parcel[0].pickup[2].pickup_location);
            setPhone2(response.data.parcel[0].pickup[2].pickup_location_phone);
            setemail2(response.data.parcel[0].pickup[2].pickupEmail);
            setPickupName2(response.data.parcel[0].pickup[2].pickUpName)
            setData32(response.data.parcel[0].pickup[2].pickupTime);
            setAlignmentPickup(response.data.parcel[0].pickup[2].pickupTimeDesc);
            setdesc2(response.data.parcel[0].pickup[2].pickup_instruction);
          }
         
          
          setInputFields(response.data.parcel[0].passport)
         setInputFieldsCompany(response.data.parcel[0].emiratesId)
          setInputFieldsBcerti(response.data.parcel[0].bCerti)
          setInputFieldsDcerti(response.data.parcel[0].dCerti)
          setInputFieldsMcerti(response.data.parcel[0].mCerti)
          setInputFieldsOther(response.data.parcel[0].other)
          setSelected1(response.data.parcel[0].blackBag.active)
          setSelected2(response.data.parcel[0].whiteBag.active)
          setSelected3(response.data.parcel[0].A3.active)
          setquanBlack(response.data.parcel[0].blackBag.quantity)
          setquanWhite(response.data.parcel[0].whiteBag.quantity)
          setquanA3(response.data.parcel[0].A3.quantity)
  
       
         
        
        }
      });
    return () => {
      isMounted = false;
    };
  }
  }, [draftUrl]);
  const handleSubmitDraft = () => {
    
    let p = [];
    let pDetails = {};
    pDetails["id"] = "1";
    pDetails["pickup_location"] = address;
    pDetails["pickup_location_phone"] = phone;
    pDetails["pickupEmail"] = email;
    pDetails["pickUpName"] = pickupName;
    //pDetails["pickupTime"] = moment(pickup).format("hh:mm");
    pDetails["pickupTime"] =pickup
    pDetails["pickupTimeExact"] = moment(pickup).format("hh:mm");
   // pDetails["pickupTimeExact"] = pickup
    pDetails["pickupTimeDesc"] = alignmentPickup;
    pDetails["pickup_instruction"] = descript;
    pDetails["pickup_latitude"] = String(coordinate.lat);
    pDetails["pickup_longitude"] = String(coordinate.lng);
    pDetails["pickup_status"] = "pending";

    let pDetails1 = {};
    pDetails1["id"] = "2";
    pDetails1["pickup_location"] = address1;
    pDetails1["pickup_location_phone"] = phone1;
    pDetails1["pickupEmail"] = email1;
    pDetails1["pickUpName"] = pickupName1;
    pDetails1["pickupTime"] =pickup1

    //pDetails1["pickupTime"] = pickup1
    pDetails1["pickupTimeExact"] =  moment(pickup1).format("hh:mm")
    pDetails1["pickupTimeDesc"] = alignmentPickup1;
    pDetails1["pickup_instruction"] = descript1;
    pDetails1["pickup_latitude"] = String(coordinate1.lat);
    pDetails1["pickup_longitude"] = String(coordinate1.lng);
    pDetails1["pickup_status"] = "pending";

    let pDetails2 = {};
    pDetails2["id"] = "3";
    pDetails2["pickup_location"] = address2;
    pDetails2["pickup_location_phone"] = phone2;
    pDetails2["pickupEmail"] = email2;
    pDetails2["pickUpName"] = pickupName2;
    pDetails2["pickupTime"] = pickup2
    //pDetails2["pickupTime"] = pickup2
    pDetails2["pickupTimeExact"] = pickup2
    pDetails2["pickupTimeDesc"] = alignmentPickup2;
    pDetails2["pickup_instruction"] = descript2;
    pDetails2["pickup_latitude"] = String(coordinate2.lat);
    pDetails2["pickup_longitude"] = String(coordinate2.lng);
    pDetails2["pickup_status"] = "pending";

    p.push(pDetails);
    if (address1 !== null && !clear) {
      p.push(pDetails1);
    }
    if ((address2 !== null || address2 !== "") && email2 !== null && !clear) {
      p.push(pDetails2);
    }

    let d = [];
    let dDetails = {};
    dDetails["id"] = "1";
    dDetails["delivery_location"] = addressDelivery;
    dDetails["delivery_location_phone"] = phoneD;
    dDetails["deliveryEmail"] = emailD;
    dDetails["deliveryName"] = pickupNameD;
    dDetails["deliveryTime"] =drop
    dDetails["deliveryTimeExact"] =  moment(drop).format("hh:mm");
    dDetails["deliveryTimeDesc"] = alignment;
    dDetails["delivery_instruction"] = descriptD;
    dDetails["delivery_latitude"] = String(coordinateDelivery.lat);
    dDetails["delivery_longitude"] = String(coordinateDelivery.lng);
    dDetails["delivery_status"] = "pending";
    d.push(dDetails);

    var today = new Date();
    let postId;
    let product_list = [
      {
        image: "",
        qty: "2",
        product_name: "i",
        price: String(price),
      },
    ];
    
    let params = {};
    params["userId"] = localStorage.getItem("userId");
    params["company_id"] = localStorage.getItem("companyId");
    params["pickup"] = p;
    params["delivery"] = d;
    params["blackBag"] = {
      "active" : selected1,
      "quantity" : quanBlack
    }
    params["whiteBag"] = {
      "active" : selected2,
      "quantity" : quanWhite
    }
    params["A3"] = {
      "active" : selected3,
      "quantity" : quanA3
    }
   
    params["pickup_location"] = address;
    params["pickup_location_phone"] = email;
    params["pickupTime"] = moment(pickup).format("hh:mm");
    params["pickupTimeDesc"] = alignment;
    params["pickup_instruction"] = descript;
    params["delivery_location"] = addressDelivery;
    params["delivery_location_phone"] = phoneD;
    params["deliveryEmail"] = emailD;
    params["deliveryTime"] = moment(drop).format("hh:mm");
    params["deliveryTimeDesc"] = alignment;
    params["delivery_instruction"] = descriptD;
    params["pickupCoordinates"] = coordinate;
    params["deliveryCoordinates"] = coordinateDelivery;
    params["pickup_status"] = "Pending";
    params["delivery_type"] = "delivernow";
    params["delivery_status"] = "Pending";
    params["pickup_latitude"] = String(coordinate.lat);
    params["pickup_longitude"] = String(coordinate.lng);
    params["delivery_latitude"] = String(coordinateDelivery.lat);
    params["delivery_longitude"] = String(coordinateDelivery.lng);
    params["stop_latitude"] = String(coordinateStop.lat);
    params["stop_longitude"] = String(coordinateStop.lng);
    params["order_amt"] = String(price);
    params["estimatedPrice"] = String(price);
    params["customer_name"] = "jiffy";
    params["customer_id"] = "JIFFY" + uuidv4();
    params["customer_phone"] = "8075095847";
    params["ordered_date"] = today;
    params["order_payment_method"] = "CARD";
    params["order_status"] = "draft";
    params["pickup_status"] = "Pending";
    params["delivery_status"] = "Pending";
    params["compensation_phone"] = "Pending";
    params["package_size"] = "1kg";
    params["is_web"] = "true";
    params["product_list"] = product_list;
    params["emiratesId"] = inputFieldsCompany;
    params["passport"] = inputFields;
    params["mCerti"] = inputFieldsMcerti;
    params["bCerti"] = inputFieldsBcerti;
    params["dCerti"] = inputFieldsDcerti;
    params["other"] = inputFieldsOther;
    params["transport"] = alignmentTrans;
    params["immigoCaseNumber"] = imigo ? imigo : '';
    params["deliveryPartnerPrice"] = dprice;
    params["immigoType"] = immigoSet
    console.log(JSON.stringify(params));

    

    if (
      params["immigoCaseNumber"] === "" ||
      params["immigoCaseNumber"] === null 
    ) {
      setErrorImg(true);
    } else {
    }      setErrorImg(false);

    let fixDate = new Date().setHours(17, 0, 0); // for 3:00:00 pm
    let currDate = pickup;

console.log("ggg",draftId)
    if (
      
      draftId.value === ""
    ) {

      axios.post(draftUrl, params).then((res) => {
        console.log(res.data);
        postId = res.data;
        if (res.data.status === "Success") {
          postId = res.data.id;
          draftId.value = res.data.id
          setId(res.data.id);
          console.log(id);
          setTimeout(() => {
           // navigate("/allorders");
          }, 3000);
        }
       
        try {
        } catch (error) {
          console.error();
        }
      });
      // if (photoChecked) {
      //   let params = {
      //     place: address,
      //     phone_number: phone,
      //     user_email_address: email,
      //     pick_up_time: moment(pickup).format("hh:mm"),
      //     am_or_pm: alignmentPickup,
      //     name: pickupName,
      //     photo_specification: photoValue,
      //     instruction: descript,
      //     price: pricePhoto,
      //     order_status: "Pending",
      //     user_id: localStorage.getItem("userId"),
      //   };
      //   axios.post(urlPhoto, params).then((res) => {
      //     console.log(res.data);

      //     if (res.data.status === "Success") {

      //     }
      //     try {
      //     } catch (error) {
      //       console.error();
      //     }
      //   });
      // }

    }
    else{
      params["_id"] = draftId.value;
      axios.put(draftUrl, params).then((res) => {
        console.log(res.data);
        postId = res.data;
        if (res.data.status === "Success") {
          
         
         
          console.log(id);
          setTimeout(() => {
          //  navigate("/allorders");
          }, 3000);
        }
       
        try {
        } catch (error) {
          console.error();
        }
      });
      // if (photoChecked) {
      //   let params = {
      //     place: address,
      //     phone_number: phone,
      //     user_email_address: email,
      //     pick_up_time: moment(pickup).format("hh:mm"),
      //     am_or_pm: alignmentPickup,
      //     name: pickupName,
      //     photo_specification: photoValue,
      //     instruction: descript,
      //     price: pricePhoto,
      //     order_status: "Pending",
      //     user_id: localStorage.getItem("userId"),
      //   };
      //   axios.post(urlPhoto, params).then((res) => {
      //     console.log(res.data);

      //     if (res.data.status === "Success") {

      //     }
      //     try {
      //     } catch (error) {
      //       console.error();
      //     }
      //   });
      // }

    }
  };
  const handleSubmitDraftSubmit = () => {
    
    let p = [];
    let pDetails = {};
    pDetails["id"] = "1";
    pDetails["pickup_location"] = address;
    pDetails["pickup_location_phone"] = phone;
    pDetails["pickupEmail"] = email;
    pDetails["pickUpName"] = pickupName;
    //pDetails["pickupTime"] = moment(pickup).format("hh:mm");
    pDetails["pickupTime"] =pickup
    pDetails["pickupTimeExact"] = moment(pickup).format("hh:mm");
   // pDetails["pickupTimeExact"] = pickup
    pDetails["pickupTimeDesc"] = alignmentPickup;
    pDetails["pickup_instruction"] = descript;
    pDetails["pickup_latitude"] = String(coordinate.lat);
    pDetails["pickup_longitude"] = String(coordinate.lng);
    pDetails["pickup_status"] = "pending";

    let pDetails1 = {};
    pDetails1["id"] = "2";
    pDetails1["pickup_location"] = address1;
    pDetails1["pickup_location_phone"] = phone1;
    pDetails1["pickupEmail"] = email1;
    pDetails1["pickUpName"] = pickupName1;
    pDetails1["pickupTime"] =pickup1

    //pDetails1["pickupTime"] = pickup1
    pDetails1["pickupTimeExact"] =  moment(pickup1).format("hh:mm")
    pDetails1["pickupTimeDesc"] = alignmentPickup1;
    pDetails1["pickup_instruction"] = descript1;
    pDetails1["pickup_latitude"] = String(coordinate1.lat);
    pDetails1["pickup_longitude"] = String(coordinate1.lng);
    pDetails1["pickup_status"] = "pending";

    let pDetails2 = {};
    pDetails2["id"] = "3";
    pDetails2["pickup_location"] = address2;
    pDetails2["pickup_location_phone"] = phone2;
    pDetails2["pickupEmail"] = email2;
    pDetails2["pickUpName"] = pickupName2;
    pDetails2["pickupTime"] = pickup2
    //pDetails2["pickupTime"] = pickup2
    pDetails2["pickupTimeExact"] = pickup2
    pDetails2["pickupTimeDesc"] = alignmentPickup2;
    pDetails2["pickup_instruction"] = descript2;
    pDetails2["pickup_latitude"] = String(coordinate2.lat);
    pDetails2["pickup_longitude"] = String(coordinate2.lng);
    pDetails2["pickup_status"] = "pending";

    p.push(pDetails);
    if (address1 !== null && !clear) {
      p.push(pDetails1);
    }
    if ((address2 !== null || address2 !== "") && email2 !== null && !clear) {
      p.push(pDetails2);
    }

    let d = [];
    let dDetails = {};
    dDetails["id"] = "1";
    dDetails["delivery_location"] = addressDelivery;
    dDetails["delivery_location_phone"] = phoneD;
    dDetails["deliveryEmail"] = emailD;
    dDetails["deliveryName"] = pickupNameD;
    dDetails["deliveryTime"] =drop
    dDetails["deliveryTimeExact"] =  moment(drop).format("hh:mm");
    dDetails["deliveryTimeDesc"] = alignment;
    dDetails["delivery_instruction"] = descriptD;
    dDetails["delivery_latitude"] = String(coordinateDelivery.lat);
    dDetails["delivery_longitude"] = String(coordinateDelivery.lng);
    dDetails["delivery_status"] = "pending";
    d.push(dDetails);

    var today = new Date();
    let postId;
    let product_list = [
      {
        image: "",
        qty: "2",
        product_name: "i",
        price: String(price),
      },
    ];
    
    let params = {};
    params["userId"] = localStorage.getItem("userId");
    params["company_id"] = localStorage.getItem("companyId");
    params["pickup"] = p;
    params["delivery"] = d;
    params["blackBag"] = {
      "active" : selected1,
      "quantity" : quanBlack
    }
    params["whiteBag"] = {
      "active" : selected2,
      "quantity" : quanWhite
    }
    params["A3"] = {
      "active" : selected3,
      "quantity" : quanA3
    }
   
    params["pickup_location"] = address;
    params["pickup_location_phone"] = email;
    params["pickupTime"] = moment(pickup).format("hh:mm");
    params["pickupTimeDesc"] = alignment;
    params["pickup_instruction"] = descript;
    params["delivery_location"] = addressDelivery;
    params["delivery_location_phone"] = phoneD;
    params["deliveryEmail"] = emailD;
    params["deliveryTime"] = moment(drop).format("hh:mm");
    params["deliveryTimeDesc"] = alignment;
    params["delivery_instruction"] = descriptD;
    params["pickupCoordinates"] = coordinate;
    params["deliveryCoordinates"] = coordinateDelivery;
    params["pickup_status"] = "Pending";
    params["delivery_type"] = "delivernow";
    params["delivery_status"] = "Pending";
    params["pickup_latitude"] = String(coordinate.lat);
    params["pickup_longitude"] = String(coordinate.lng);
    params["delivery_latitude"] = String(coordinateDelivery.lat);
    params["delivery_longitude"] = String(coordinateDelivery.lng);
    params["stop_latitude"] = String(coordinateStop.lat);
    params["stop_longitude"] = String(coordinateStop.lng);
    params["order_amt"] = String(price);
    params["estimatedPrice"] = String(price);
    params["customer_name"] = "jiffy";
    params["customer_id"] = "JIFFY" + uuidv4();
    params["customer_phone"] = "8075095847";
    params["ordered_date"] = today;
    params["order_payment_method"] = "CARD";
    params["order_status"] = "draft";
    params["pickup_status"] = "Pending";
    params["delivery_status"] = "Pending";
    params["compensation_phone"] = "Pending";
    params["package_size"] = "1kg";
    params["is_web"] = "true";
    params["product_list"] = product_list;
    params["emiratesId"] = inputFieldsCompany;
    params["passport"] = inputFields;
    params["mCerti"] = inputFieldsMcerti;
    params["bCerti"] = inputFieldsBcerti;
    params["dCerti"] = inputFieldsDcerti;
    params["other"] = inputFieldsOther;
    params["transport"] = alignmentTrans;
    params["immigoCaseNumber"] = imigo ? imigo : '';
    params["deliveryPartnerPrice"] = dprice;
    params["immigoType"] = immigoSet
    console.log(JSON.stringify(params));

    

    if (
      params["immigoCaseNumber"] === "" ||
      params["immigoCaseNumber"] === null 
    ) {
      setErrorImg(true);
    } else {
    }      setErrorImg(false);

    let fixDate = new Date().setHours(17, 0, 0); // for 3:00:00 pm
    let currDate = pickup;

console.log("ggg",draftId)
    if (
      
      draftId.value === ""
    ) {

      axios.post(draftUrl, params).then((res) => {
        console.log(res.data);
        postId = res.data;
        if (res.data.status === "Success") {
          postId = res.data.id;
          draftId.value = res.data.id
          setId(res.data.id);
          console.log(id);
    
            navigate("/allorders");
      
        }
       
        try {
        } catch (error) {
          console.error();
        }
      });
      if (photoChecked) {
        let params = {
          place: address,
          phone_number: phone,
          user_email_address: email,
          pick_up_time: moment(pickup).format("hh:mm"),
          am_or_pm: alignmentPickup,
          name: pickupName,
          photo_specification: photoValue,
          instruction: descript,
          price: pricePhoto,
          order_status: "Draft",
          user_id: localStorage.getItem("userId"),
        };
        axios.post(urlPhoto, params).then((res) => {
          console.log(res.data);

          if (res.data.status === "Success") {

          }
          try {
          } catch (error) {
            console.error();
          }
        });
      }

    }
    else{
      params["_id"] = draftId.value;
      axios.put(draftUrl, params).then((res) => {
        console.log(res.data);
        postId = res.data;
        if (res.data.status === "Success") {
          
         
         
          console.log(id);
     
         navigate("/allorders");
          
        }
       
        try {
        } catch (error) {
          console.error();
        }
      });
      // if (photoChecked) {
      //   let params = {
      //     place: address,
      //     phone_number: phone,
      //     user_email_address: email,
      //     pick_up_time: moment(pickup).format("hh:mm"),
      //     am_or_pm: alignmentPickup,
      //     name: pickupName,
      //     photo_specification: photoValue,
      //     instruction: descript,
      //     price: pricePhoto,
      //     order_status: "Pending",
      //     user_id: localStorage.getItem("userId"),
      //   };
      //   axios.post(urlPhoto, params).then((res) => {
      //     console.log(res.data);

      //     if (res.data.status === "Success") {

      //     }
      //     try {
      //     } catch (error) {
      //       console.error();
      //     }
      //   });
      // }

    }
  };
  const [tabSwitch,setTabSwitch] = React.useState(0)
  const [openErrorDraft,setOpenErrorDraft] = React.useState(false)
  const [data,setData] = React.useState()
  React.useEffect(() => {
    if(draftId.value === ''){
    axios.get(draftUrl, {
      params:{
          order_status:'draft',
          userId: localStorage.getItem("userId"),
          company_id:localStorage.getItem("companyId"),
          
      }

  }).then((response) => {
      //setDataParcel(response.data.parcel)
      if(response.data.parcel.length && response.data.parcel[0]._id && response.data.parcel[0].delivery_type === 'delivernow' || response.data.parcel[0].delivery_type === 'deliverlater'){
        setOpenErrorDraft(true)
        draftId.value = response.data.parcel[0]._id;
        setData(response.data.parcel[0])
      }
    
     
    //  console.log("INVOICESS", response)


  });
}
  }, [draftUrl]);


  const [savedData,setSavedData] = React.useState({ data: [] })
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndexD, setSelectedIndexD] = useState(null);
  function removeAddress(saved){
    let payload = {
      "_id":saved.user_bookmarked_address._id,
      "userId":saved.user_bookmarked_address.userId,
      "address":saved.user_bookmarked_address.address,
      "longitude": saved.user_bookmarked_address.longitude,
      "latitude": saved.user_bookmarked_address.latitude,
      "bookmark_name":  saved.user_bookmarked_address.bookmark_name,
      "is_active":false
    }
    axios.put(AddressUrl, payload).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      axios.get(AddressUrl, {
        params:{
            userId: localStorage.getItem("userId"),
            // company_id:localStorage.getItem("companyId"),
            
        }
  
    }).then((response) => {
        //setDataParcel(response.data.parcel)
       
      setSavedData(response)
      console.log("fffffffffffffff",response)
    });
  })
}
  // Toggle function that toggles the state for a specific card by index
  const toggleCheck = (index,saved) => {
    setSelectedIndex(prevIndex => (prevIndex === index ? null : index));
    
    setAddress(saved.user_bookmarked_address.address);
    setCoordinates({ lat: saved.user_bookmarked_address.latitude, long: saved.user_bookmarked_address.longitude })
   // handlePrice()
  };
  const toggleCheckD = (index,saved) => {
    setSelectedIndexD(prevIndex => (prevIndex === index ? null : index));
    
    setAddressDelivery(saved.user_bookmarked_address.address);
    setCoordinatesDelivery({ lat: saved.user_bookmarked_address.latitude, long: saved.user_bookmarked_address.longitude })
    handlePrice()
  };
  const [bookmarkName, setBookmarkName] = useState('');
  const [error, setError] = useState(false);
  const [bookmarkNameD, setBookmarkNameD] = useState('');
  const [errorD, setErrorD] = useState(false);

  const handleBookmarkChange = (event) => {
    setBookmarkName(event.target.value);
    setError(event.target.value === ''); // Sets error to true if input is empty
  };
  const handleBookmarkChangeD = (event) => {
    setBookmarkNameD(event.target.value);
    setErrorD(event.target.value === ''); // Sets error to true if input is empty
  };
  const handleSave = () => {
    if (bookmarkName.trim() === '') {
      setError(true);
      return;
    }
    saveAddressRequest(bookmarkName); // Pass the bookmark name to the save function
    handleCloseSave(); // Close the dialog after saving
  };
  const handleSaveD = () => {
    if (bookmarkNameD.trim() === '') {
      setErrorD(true);
      return;
    }
    saveAddressRequestD(bookmarkNameD); // Pass the bookmark name to the save function
    handleCloseSaveD(); // Close the dialog after saving
  };
  const [savePopup,setSavePopup] = React.useState(false);
  const [savePopupD,setSavePopupD] = React.useState(false);

  function openSavedAddressPopup(){
    setSavePopup(true)
  }
  function openSavedAddressPopupDelivery(){
    setSavePopupD(true)
  }
  const handleCloseSave = ()=>{
    setSavePopup(false)
  }
  const handleCloseSaveD = ()=>{
    setSavePopupD(false)
  }
  function saveAddressRequest(bookamark){
    let payload = {
      "userId":localStorage.getItem("userId"),
      "address":address,
      "longitude": String(coordinate.lat),
      "latitude": String(coordinate.lng),
      "bookmark_name": bookamark,
      "is_active":true
    }
    axios.post(AddressUrl, payload).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      axios.get(AddressUrl, {
        params:{
            userId: localStorage.getItem("userId"),
            // company_id:localStorage.getItem("companyId"),
            
        }
  
    }).then((response) => {
        //setDataParcel(response.data.parcel)
      setSavedData(response)
      console.log("fffffffffffffff",response)
    });
      try {
      } catch (error) {
        console.error();
      }
    });
  }
  function saveAddressRequestD(bookamark){
    let payload = {
      "userId":localStorage.getItem("userId"),
      "address":addressDelivery,
      "longitude": String(coordinateDelivery.lat),
      "latitude": String(coordinateDelivery.lng),
      "bookmark_name": bookamark,
      "is_active":true
    }
    axios.post(AddressUrl, payload).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      axios.get(AddressUrl, {
        params:{
            userId: localStorage.getItem("userId"),
            // company_id:localStorage.getItem("companyId"),
            
        }
  
    }).then((response) => {
        //setDataParcel(response.data.parcel)
      setSavedData(response)
      console.log("fffffffffffffff",response)
    });
      try {
      } catch (error) {
        console.error();
      }
    });
  }
  const [openAddressBook,setOpenAddressBook] = React.useState(false)
 
  React.useEffect(() => {
  
    axios.get(AddressUrl, {
      params:{
          userId: localStorage.getItem("userId"),
          is_active: true
          // company_id:localStorage.getItem("companyId"),
          
      }

  }).then((response) => {
      //setDataParcel(response.data.parcel)
      // savedData.data
      //  save.user_bookmarked_address.is_active
     
    setSavedData(response);
    console.log("fffffffffffffff",response)
  });

  }, [AddressUrl]);
 function handlePrice(){
  let p = [];
  let pDetails = {};
  pDetails["id"] = "1";
  pDetails["pickup_location"] = address;
  pDetails["pickup_location_phone"] = phone;
  pDetails["pickupEmail"] = email;
  pDetails["pickupTime"] = pickup;
  pDetails["pickupTimeDesc"] = alignment;
  pDetails["pickup_instruction"] = descript;
  pDetails["pickup_latitude"] = String(coordinate.lat);
  pDetails["pickup_longitude"] = String(coordinate.lng);
  pDetails["pickup_status"] = "pending";

  let pDetails1 = {};
  pDetails1["id"] = "2";
  pDetails1["pickup_location"] = address1;
  pDetails1["pickup_location_phone"] = phone1;
  pDetails1["pickupEmail"] = email1;
  pDetails1["pickupTime"] = pickup1;
  pDetails1["pickupTimeDesc"] = alignment;
  pDetails1["pickup_instruction"] = descript1;
  pDetails1["pickup_latitude"] = String(coordinate1.lat);
  pDetails1["pickup_longitude"] = String(coordinate1.lng);
  pDetails1["pickup_status"] = "pending";

  let pDetails2 = {};
  pDetails2["id"] = "3";
  pDetails2["pickup_location"] = address2;
  pDetails2["pickup_location_phone"] = phone2;
  pDetails2["pickupEmail"] = email2;
  pDetails2["pickupTime"] = pickup2;
  pDetails2["pickupTimeDesc"] = alignment;
  pDetails2["pickup_instruction"] = descript2;
  pDetails2["pickup_latitude"] = String(coordinate2.lat);
  pDetails2["pickup_longitude"] = String(coordinate2.lng);
  pDetails2["pickup_status"] = "pending";

  p.push(pDetails);
  if (address1 !== null) {
    p.push(pDetails1);
  }
  if (address2 !== null) {
    p.push(pDetails2);
  }

  let priceParams = {};
  priceParams["pickup_latitude"] = String(coordinate.lat);
  priceParams["pickup_longitude"] = String(coordinate.lng);
  priceParams["delivery_latitude"] = String(coordinateDelivery.lat);
  priceParams["delivery_longitude"] = String(coordinateDelivery.lng);
  priceParams["delivery_type"] = "delivernow";
  priceParams["locality"] = addressDelivery;
  priceParams["localityp"] = address;
  priceParams["no_of_pickup"] = p.length;
  priceParams["pickup"] = p;
  priceParams["userId"] = localStorage.getItem("userId");
  priceParams["company_id"] = localStorage.getItem("companyId");
  priceParams["transport_type"] = alignmentTrans;
  console.log(priceParams);
  // if (coordinate.lat|| coordinate.lng || ll.lat || ll.lng) {
  axios.post(priceUrl, priceParams).then((res) => {
    console.log("PRICEEEEEEE", res.data);
    setPrice(res.data.estimatedPrice);
    setPriceD(res.data.deliveryPartnerPrice);
    setSla(res.data.sla)
    try {
    } catch (error) {
      console.error();
    }
  });
  let params = {
    user_id: localStorage.getItem("userId"),
    location: address,
    type_photo: photoValue,
    photo_lat: String(coordinate.lat),
    photo_lng: String(coordinate.lng)
  };
  axios.post(priceUrlPhoto, params).then((res) => {
    console.log("PRICEEEEEEE", res.data);
    if(res.data && res.data.estimatedPrice){
    
      if(pricePhoto === "0" && photoChecked){
        setPrice(price + res.data.estimatedPrice);
        setPricePhoto(res.data.estimatedPrice);
      }
    }
   
    try {
    } catch (error) {
      console.error();
    }
  });
  // }
  handleSubmitDraft()
 }
  const handleCloseErrorDraft = ()=>{
    setOpenErrorDraft(false)
  }
  const gotoPageDraft = ()=>{
    setOpenErrorDraft(false)
    if(draftId.value !== ''){
      let isMounted = true;
      //let res;
     
      axios
        .get(draftUrl, {
          params: {
            _id: draftId.value,
          },
        })
        .then((response) => {
          //setLoad(false)
          if (isMounted) {
           // setFlaged(true)
            console.log(response);
            setImigo(response.data.parcel[0].immigoCaseNumber)
            setAddress(response.data.parcel[0].pickup[0].pickup_location);
            setPhone(response.data.parcel[0].pickup[0].pickup_location_phone);
            setemail(response.data.parcel[0].pickup[0].pickupEmail);
            setPickupName(response.data.parcel[0].pickup[0].pickUpName);
            setData3(response.data.parcel[0].pickup[0].pickupTimeExact);
            setAlignmentPickup(response.data.parcel[0].pickup[0].pickupTimeDesc);
            setdesc(response.data.parcel[0].pickup[0].pickup_instruction);
           setPrice(response.data.parcel[0].estimatedPrice)
  
           setAddressDelivery(response.data.parcel[0].delivery[0].delivery_location);
           setPhoneD(response.data.parcel[0].delivery[0].delivery_location_phone);
            setemailD(response.data.parcel[0].delivery[0].deliveryEmail);
            setPickupNameD(response.data.parcel[0].delivery[0].deliveryName ? response.data.parcel[0].delivery[0].deliveryName : response.data.parcel[0].delivery[0].pickUpName ? response.data.parcel[0].delivery[0].pickUpName : "")
            setData4(response.data.parcel[0].delivery[0].deliveryTimeExact);
            setAlignment(response.data.parcel[0].delivery[0].deliveryTimeDesc);
            setdescD(response.data.parcel[0].delivery[0].delivery_instruction);
            if(response.data.parcel[0].pickup[1] && response.data.parcel[0].pickup[1].pickup_location){
              setAddress1(response.data.parcel[0].pickup[1].pickup_location);
              setPhone1(response.data.parcel[0].pickup[1].pickup_location_phone);
              setemail1(response.data.parcel[0].pickup[1].pickupEmail);
              setPickupName1(response.data.parcel[0].pickup[1].pickUpName)
              setData31(response.data.parcel[0].pickup[1].pickupTime);
              setAlignmentPickup(response.data.parcel[0].pickup[1].pickupTimeDesc);
             setdesc1(response.data.parcel[0].pickup[1].pickup_instruction);
           }
            if(response.data.parcel[0].pickup[2] && response.data.parcel[0].pickup[2].pickup_location){
              setAddress2(response.data.parcel[0].pickup[2].pickup_location);
              setPhone2(response.data.parcel[0].pickup[2].pickup_location_phone);
              setemail2(response.data.parcel[0].pickup[2].pickupEmail);
              setPickupName2(response.data.parcel[0].pickup[2].pickUpName)
              setData32(response.data.parcel[0].pickup[2].pickupTime);
              setAlignmentPickup(response.data.parcel[0].pickup[2].pickupTimeDesc);
              setdesc2(response.data.parcel[0].pickup[2].pickup_instruction);
            }
           
            
            setInputFields(response.data.parcel[0].passport)
           setInputFieldsCompany(response.data.parcel[0].emiratesId)
            setInputFieldsBcerti(response.data.parcel[0].bCerti)
            setInputFieldsDcerti(response.data.parcel[0].dCerti)
            setInputFieldsMcerti(response.data.parcel[0].mCerti)
            setInputFieldsOther(response.data.parcel[0].other)
            setSelected1(response.data.parcel[0].blackBag.active)
            setSelected2(response.data.parcel[0].whiteBag.active)
            setSelected3(response.data.parcel[0].A3.active)
            setquanBlack(response.data.parcel[0].blackBag.quantity)
            setquanWhite(response.data.parcel[0].whiteBag.quantity)
            setquanA3(response.data.parcel[0].A3.quantity)
            if(response.data.parcel[0].delivery_type === 'deliverlater' ||response.data.parcel[0].delivery_type === 'deliverLater' ){
              setActiveTab(2)
              setTabValue(1)
            }
            else{
              setActiveTab(1)
            setTabValue(0)
            }
            
           
           
    
           
    
          }
        });
      return () => {
        isMounted = false;
      };
    }

  }
  const gotoTracker = () => {
    axios.get(url + "?" + id).then((response) => {
      console.log(JSON.stringify(response));
    });
  };
  const [clear, getClear] = React.useState(false);
  const clearDetails = () => {
    getClear(true);
    getArrow(false);
    setPhone1("");
    setemail1("");
    setPickup1("");
    setdesc1("");
    setAddress1("");
    setPhone2("");
    setemail2("");
    setPickup2("");
    setdesc2("");
    setAddress2("");
  };
  const [plus, getPlus] = React.useState(false);
  const [phone, setPhone] = React.useState(null);
  const [email, setemail] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [pickup, setPickup] = React.useState('');

  const [descript, setdesc] = React.useState(null);
  const pickupPhone = (val) => {
    setPhone(val.target.value);
  };
  const pickupEmail = (val) => {
    setemail(val.target.value);
  };
  const pickupDesc = (val) => {
    setdesc(val.target.value);
  };
  const [pickupName, setPickupName] = React.useState(null);
  const [pickupName1, setPickupName1] = React.useState(null);
  const [pickupName2, setPickupName2] = React.useState(null);
  const [pickupNameD, setPickupNameD] = React.useState(null);
  const pickupNameSet = (val) => {
    setPickupName(val.target.value);
  };
  const pickupNameSet1 = (val) => {
    setPickupName1(val.target.value);
  };
  const pickupNameSet2 = (val) => {
    setPickupName2(val.target.value);
  };
  const pickupNameSetD = (val) => {
    setPickupNameD(val.target.value);
  };
  const addPlus = () => {
    getPlus(!plus);
  };
  const [phone1, setPhone1] = React.useState(null);
  const [email1, setemail1] = React.useState(null);
  const [time1, setTime1] = React.useState(null);
  const [pickup1, setPickup1] = React.useState(null);
  const [descript1, setdesc1] = React.useState(null);
  const pickupPhone1 = (val) => {
    setPhone1(val.target.value);
  };
  const pickupEmail1 = (val) => {
    setemail1(val.target.value);
  };
  const pickupDesc1 = (val) => {
    setdesc1(val.target.value);
  };

  const [phone2, setPhone2] = React.useState(null);
  const [email2, setemail2] = React.useState(null);
  const [time2, setTime2] = React.useState(null);
  const [pickup2, setPickup2] = React.useState(null);
  const [descript2, setdesc2] = React.useState(null);

  const pickupPhone2 = (val) => {
    setPhone2(val.target.value);
  };
  const pickupEmail2 = (val) => {
    setemail2(val.target.value);
  };
  const pickupDesc2 = (val) => {
    setdesc2(val.target.value);
  };

  const [phoneD, setPhoneD] = React.useState(null);
  const [emailD, setemailD] = React.useState(null);
  const [timeD, setTimeD] = React.useState(null);
  const [imigo, setImigo] = React.useState(null);

  const [descriptD, setdescD] = React.useState(null);
  const phoneDel = (val) => {
    setPhoneD(val.target.value);
  };
  const emailDel = (val) => {
    setemailD(val.target.value);
  };
  const descDel = (val) => {
    setdescD(val.target.value);
  };
  const ImgNumber = (val) => {
    handleSubmitDraft();
    const inputValue = val.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    val.target.value = numericValue;
    setImigo(val.target.value);
    if(val.target.value.length >= 6){
      setErrorImg(false);
    }
    else{
      setErrorImg(true);
    }
  };

  const [arrow, getArrow] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const [address1, setAddress1] = React.useState(null);
  const [address2, setAddress2] = React.useState(null);
  const [price, setPrice] = React.useState("0");
  const [pricePhoto, setPricePhoto] = React.useState("0");
  const [dprice, setPriceD] = React.useState("");
  const [addressDelivery, setAddressDelivery] = React.useState(null);
  const [addressStop, setAddressStop] = React.useState(null);
  const [coordinate, setCoordinates] = React.useState({ lat: "", long: "" });
  const [coordinate1, setCoordinates1] = React.useState({ lat: "", long: "" });
  const [coordinate2, setCoordinates2] = React.useState({ lat: "", long: "" });
  const [coordinateDelivery, setCoordinatesDelivery] = React.useState({
    latDel: "",
    longDel: "",
  });
  const [coordinateStop, setCoordinatesStop] = React.useState({
    latStop: "",
    longStop: "",
  });
  const [activeTab, setActiveTab] = React.useState(1);
  const [sla, setSla] = React.useState("2");

  const handleChanged = (address) => {
    //this.setState({ address });
  };
  const getArrowDown = () => {
    getArrow(!arrow);
    getClear(false);
  };
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    console.log(JSON.stringify(result));
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinates(ll);
    console.log(JSON.stringify(ll));
    // geocodeByAddress(address)
    //    .then(results => getLatLng(results[0]))
    //    .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };

  const handleSelect1 = async (value) => {
    const result = await geocodeByAddress(value);
    console.log(JSON.stringify(result));
    const ll = await getLatLng(result[0]);
    setAddress1(value);
    setCoordinates1(ll);
    console.log(JSON.stringify(ll));
    // geocodeByAddress(address)
    //    .then(results => getLatLng(results[0]))
    //    .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };
  const handleSelect2 = async (value) => {
    const result = await geocodeByAddress(value);
    console.log(JSON.stringify(result));
    const ll = await getLatLng(result[0]);
    setAddress2(value);
    setCoordinates2(ll);
    console.log(JSON.stringify(ll));
    // geocodeByAddress(address)
    //    .then(results => getLatLng(results[0]))
    //    .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };
  const callPriceParams = async (val) =>{
    let p = [];
    let pDetails = {};
    pDetails["id"] = "1";
    pDetails["pickup_location"] = address;
    pDetails["pickup_location_phone"] = phone;
    pDetails["pickupEmail"] = email;
    pDetails["pickupTime"] = pickup;
    pDetails["pickupTimeDesc"] = alignment;
    pDetails["pickup_instruction"] = descript;
    pDetails["pickup_latitude"] = String(coordinate.lat);
    pDetails["pickup_longitude"] = String(coordinate.lng);
    pDetails["pickup_status"] = "pending";

    let pDetails1 = {};
    pDetails1["id"] = "2";
    pDetails1["pickup_location"] = address1;
    pDetails1["pickup_location_phone"] = phone1;
    pDetails1["pickupEmail"] = email1;
    pDetails1["pickupTime"] = pickup1;
    pDetails1["pickupTimeDesc"] = alignment;
    pDetails1["pickup_instruction"] = descript1;
    pDetails1["pickup_latitude"] = String(coordinate1.lat);
    pDetails1["pickup_longitude"] = String(coordinate1.lng);
    pDetails1["pickup_status"] = "pending";

    let pDetails2 = {};
    pDetails2["id"] = "3";
    pDetails2["pickup_location"] = address2;
    pDetails2["pickup_location_phone"] = phone2;
    pDetails2["pickupEmail"] = email2;
    pDetails2["pickupTime"] = pickup2;
    pDetails2["pickupTimeDesc"] = alignment;
    pDetails2["pickup_instruction"] = descript2;
    pDetails2["pickup_latitude"] = String(coordinate2.lat);
    pDetails2["pickup_longitude"] = String(coordinate2.lng);
    pDetails2["pickup_status"] = "pending";

    p.push(pDetails);
    if (address1 !== null) {
      p.push(pDetails1);
    }
    if (address2 !== null) {
      p.push(pDetails2);
    }

    let priceParams = {};
    priceParams["pickup_latitude"] = String(coordinate.lat);
    priceParams["pickup_longitude"] = String(coordinate.lng);
    priceParams["delivery_latitude"] = String(coordinateDelivery.lat);
    priceParams["delivery_longitude"] = String(coordinateDelivery.lng);
    priceParams["delivery_type"] = "delivernow";
    priceParams["no_of_pickup"] = p.length;
    priceParams["pickup"] = p;
    priceParams["userId"] = localStorage.getItem("userId");
    priceParams["company_id"] = localStorage.getItem("companyId");
    priceParams["transport_type"] = val;
    console.log(priceParams);
    // if (coordinate.lat|| coordinate.lng || ll.lat || ll.lng) {
    axios.post(priceUrl, priceParams).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      setPrice(res.data.estimatedPrice);
      setPriceD(res.data.deliveryPartnerPrice);
      setSla(res.data.sla)
      try {
      } catch (error) {
        console.error();
      }
    });
    let params = {
      user_id: localStorage.getItem("userId"),
      location: address,
      type_photo: photoValue,
      photo_lat: String(coordinateDelivery.lat),
      photo_lng: String(coordinateDelivery.lng)
    };
    axios.post(priceUrlPhoto, params).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      if(res.data && res.data.estimatedPrice){
        
        if(pricePhoto === "0" && photoChecked){
          setPrice(price + res.data.estimatedPrice);
          setPricePhoto(res.data.estimatedPrice);
        }
      }

      try {
      } catch (error) {
        console.error();
      }
    });
    handleSubmitDraft()
  }
  const handleSelectDelivery = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddressDelivery(value);
    setCoordinatesDelivery(ll);
    console.log(JSON.stringify(ll));
    // geocodeByAddress(address)
    //    .then(results => getLatLng(results[0]))
    //    .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
   
    let p = [];
    let pDetails = {};
    pDetails["id"] = "1";
    pDetails["pickup_location"] = address;
    pDetails["pickup_location_phone"] = phone;
    pDetails["pickupEmail"] = email;
    pDetails["pickupTime"] = pickup;
    pDetails["pickupTimeDesc"] = alignment;
    pDetails["pickup_instruction"] = descript;
    pDetails["pickup_latitude"] = String(coordinate.lat);
    pDetails["pickup_longitude"] = String(coordinate.lng);
    pDetails["pickup_status"] = "pending";

    let pDetails1 = {};
    pDetails1["id"] = "2";
    pDetails1["pickup_location"] = address1;
    pDetails1["pickup_location_phone"] = phone1;
    pDetails1["pickupEmail"] = email1;
    pDetails1["pickupTime"] = pickup1;
    pDetails1["pickupTimeDesc"] = alignment;
    pDetails1["pickup_instruction"] = descript1;
    pDetails1["pickup_latitude"] = String(coordinate1.lat);
    pDetails1["pickup_longitude"] = String(coordinate1.lng);
    pDetails1["pickup_status"] = "pending";

    let pDetails2 = {};
    pDetails2["id"] = "3";
    pDetails2["pickup_location"] = address2;
    pDetails2["pickup_location_phone"] = phone2;
    pDetails2["pickupEmail"] = email2;
    pDetails2["pickupTime"] = pickup2;
    pDetails2["pickupTimeDesc"] = alignment;
    pDetails2["pickup_instruction"] = descript2;
    pDetails2["pickup_latitude"] = String(coordinate2.lat);
    pDetails2["pickup_longitude"] = String(coordinate2.lng);
    pDetails2["pickup_status"] = "pending";

    p.push(pDetails);
    if (address1 !== null) {
      p.push(pDetails1);
    }
    if (address2 !== null) {
      p.push(pDetails2);
    }

    let priceParams = {};
    priceParams["pickup_latitude"] = String(coordinate.lat);
    priceParams["pickup_longitude"] = String(coordinate.lng);
    priceParams["delivery_latitude"] = String(ll.lat);
    priceParams["delivery_longitude"] = String(ll.lng);
    priceParams["delivery_type"] = "delivernow";
    priceParams["locality"] = value;
    priceParams["localityp"] = address;
    priceParams["no_of_pickup"] = p.length;
    priceParams["pickup"] = p;
    priceParams["userId"] = localStorage.getItem("userId");
    priceParams["company_id"] = localStorage.getItem("companyId");
    priceParams["transport_type"] = alignmentTrans;
    console.log(priceParams);
    // if (coordinate.lat|| coordinate.lng || ll.lat || ll.lng) {
    axios.post(priceUrl, priceParams).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      setPrice(res.data.estimatedPrice);
      setPriceD(res.data.deliveryPartnerPrice);
      setSla(res.data.sla)
      try {
      } catch (error) {
        console.error();
      }
    });
    let params = {
      user_id: localStorage.getItem("userId"),
      location: address,
      type_photo: photoValue,
      photo_lat: String(ll.lat),
      photo_lng: String(ll.lng)
    };
    axios.post(priceUrlPhoto, params).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      if(res.data && res.data.estimatedPrice){
      
        if(pricePhoto === "0" && photoChecked){
          setPrice(price + res.data.estimatedPrice);
          setPricePhoto(res.data.estimatedPrice);
        }
      }
     
      try {
      } catch (error) {
        console.error();
      }
    });
    // }
    handleSubmitDraft()
  };

  const [inputFields, setInputFields] = React.useState([]);
  const [inputFieldsCompany, setInputFieldsCompany] = React.useState([]);
  const [inputFieldsPhoto, setInputFieldsPhoto] = React.useState([]);
  const [inputFieldsMcerti, setInputFieldsMcerti] = React.useState([]);
  const [inputFieldsBcerti, setInputFieldsBcerti] = React.useState([]);
  const [inputFieldsDcerti, setInputFieldsDcerti] = React.useState([]);
  const [inputFieldsOther, setInputFieldsOther] = React.useState([]);

  const [drop, setDrop] = React.useState('');
  const [mode, setMode] = React.useState("bike");

  const handleAdd = () => {
    setInputFields([...inputFields, { passportName: "", passportNumber: "" ,immigoCaseNumber:""}]);
    //alert(JSON.stringify(inputFields.length))
  };
  const handleRemove = () => {
    const values = [...inputFields];
    values.pop();
    setInputFields(values);
  };
  const handleAddCompany = () => {
    setInputFieldsCompany([
      ...inputFieldsCompany,
      { companyId: "", companyName: "" ,immigoCaseNumber: ""},
    ]);
    console.log(inputFields);
  };
  const handleRemoveCompany = () => {
    const values = [...inputFieldsCompany];
    values.pop();
    setInputFieldsCompany(values);
  };
  const handleAddPhoto = () => {
    setInputFieldsPhoto([...inputFieldsPhoto, { photoName: "" ,immigoCaseNumber:""}]);
    console.log(inputFields);
  };
  const handleRemovePhoto = () => {
    const values = [...inputFieldsPhoto];
    values.pop();
    setInputFieldsPhoto(values);
  };
  const handleAddMcerti = () => {
    setInputFieldsMcerti([...inputFieldsMcerti, { name: "" ,immigoCaseNumber:""}]);
  };
  const handleRemoveMcerti = () => {
    const values = [...inputFieldsMcerti];
    values.pop();
    setInputFieldsMcerti(values);
  };
  const handleAddBcerti = () => {
    setInputFieldsBcerti([...inputFieldsBcerti, { name: "" ,immigoCaseNumber:""}]);
  };
  const handleRemoveBcerti = () => {
    const values = [...inputFieldsBcerti];
    values.pop();
    setInputFieldsBcerti(values);
  };
  const handleAddDcerti = () => {
    setInputFieldsDcerti([...inputFieldsDcerti, { name: "",immigoCaseNumber:"" }]);
  };
  const handleRemoveDcerti = () => {
    const values = [...inputFieldsDcerti];
    values.pop();
    setInputFieldsDcerti(values);
  };
  const handleAddOther = () => {
    setInputFieldsOther([...inputFieldsOther, { name: "" ,immigoCaseNumber:""}]);
  };
  const handleRemoveOther = () => {
    const values = [...inputFieldsOther];
    values.pop();
    setInputFieldsOther(values);
  };
  function errorLater() {
    return () => {
      <ScheduleLater></ScheduleLater>;
    };
  }
  const setPreferBag = () =>{
    setPrefer(true);
    setPreferIcon("black")
  }
  const setPreferBagWhite = () =>{
    setPrefer(true);
    setPreferIcon("white")
  }
  const [openA3, setA3] = React.useState(false);
  const setPreferA3 = () =>{
    setA3(true);
  }
  const handleCloseA3 = () =>{
    setA3(false);
  }

  const [quanBlack, setquanBlack] = React.useState(1);
  const [quanWhite, setquanWhite] = React.useState(1);
  const [quanA3, setquanA3] = React.useState(1);
  const addBlack = () => {
    setquanBlack(quanBlack + 1)
    if (selected1) {
     
      setPrice(parseInt(price) + 2);
    
    }
  };
  const removeBlack = () => {
   if(quanBlack !== 1){
    setquanBlack(quanBlack - 1)
    if (selected1) {
     
      setPrice(price - 2);
    
    }
   }

  };
  const addWhite = () => {
    setquanWhite(quanWhite + 1)
    if (selected2) {
      setPrice(parseInt(price) + 3);
    
    }
  };
  const switchTab2 = () =>{

    setActiveTab(2)
    setTabValue(1)
  }
  const switchTab1 = () =>{
    setActiveTab(1)
    setTabValue(0)
  }

  const removeWhite = () => {
   if(quanWhite !== 1){
    setquanWhite(quanWhite - 1)
    if (selected2) {
      setPrice(price - 3);
    }
   }

  };
  const addA3 = () => {
    setquanA3(quanA3 + 1)
    if (selected3) {
      setPrice(parseInt(price) + 5);
    
    }
  };
  const removeA3 = () => {
   if(quanA3 !== 1){
    setquanA3(quanA3 - 1)
    if (selected3) {
      setPrice(parseInt(price) - 5);
    }
   }

  };
  const [loaded, setLoaded] = React.useState("Submit Order");
  const [loading, setLoading] = React.useState(true);
  function handleClick6() {
    setLoading(true);
    setLoading(!loading);
  }
  const [showIM, setImLoading] = React.useState(true);
  const [caseNumber,setCaseNumber] = React.useState("")
  const [showBike, setBikeLoading] = React.useState(true);
  const [photoChecked,setPhotoChecked] = React.useState(false);
  const [photoValue,setPhotoValue] = React.useState("");
  function addPhoto(){
    setPhotoChecked(!photoChecked);
    if(!photoChecked){
      setPhotoValue("");
      setPricePhoto("0")
    }
    let p = [];
    let pDetails = {};
    pDetails["id"] = "1";
    pDetails["pickup_location"] = address;
    pDetails["pickup_location_phone"] = phone;
    pDetails["pickupEmail"] = email;
    pDetails["pickupTime"] = pickup;
    pDetails["pickupTimeDesc"] = alignment;
    pDetails["pickup_instruction"] = descript;
    pDetails["pickup_latitude"] = String(coordinate.lat);
    pDetails["pickup_longitude"] = String(coordinate.lng);
    pDetails["pickup_status"] = "pending";

    let pDetails1 = {};
    pDetails1["id"] = "2";
    pDetails1["pickup_location"] = address1;
    pDetails1["pickup_location_phone"] = phone1;
    pDetails1["pickupEmail"] = email1;
    pDetails1["pickupTime"] = pickup1;
    pDetails1["pickupTimeDesc"] = alignment;
    pDetails1["pickup_instruction"] = descript1;
    pDetails1["pickup_latitude"] = String(coordinate1.lat);
    pDetails1["pickup_longitude"] = String(coordinate1.lng);
    pDetails1["pickup_status"] = "pending";

    let pDetails2 = {};
    pDetails2["id"] = "3";
    pDetails2["pickup_location"] = address2;
    pDetails2["pickup_location_phone"] = phone2;
    pDetails2["pickupEmail"] = email2;
    pDetails2["pickupTime"] = pickup2;
    pDetails2["pickupTimeDesc"] = alignment;
    pDetails2["pickup_instruction"] = descript2;
    pDetails2["pickup_latitude"] = String(coordinate2.lat);
    pDetails2["pickup_longitude"] = String(coordinate2.lng);
    pDetails2["pickup_status"] = "pending";

    p.push(pDetails);
    if (address1 !== null) {
      p.push(pDetails1);
    }
    if (address2 !== null) {
      p.push(pDetails2);
    }
    let priceParams = {};
    priceParams["pickup_latitude"] = String(coordinate.lat);
    priceParams["pickup_longitude"] = String(coordinate.lng);
    priceParams["delivery_latitude"] = String(coordinateDelivery.lat);
    priceParams["delivery_longitude"] = String(coordinateDelivery.lng);
    priceParams["delivery_type"] = "delivernow";
    priceParams["no_of_pickup"] = p.length;
    priceParams["pickup"] = p;
    priceParams["userId"] = localStorage.getItem("userId");
    priceParams["company_id"] = localStorage.getItem("companyId");
    priceParams["transport_type"] = alignmentTrans;
    console.log(priceParams);
    // if (coordinate.lat|| coordinate.lng || ll.lat || ll.lng) {
    axios.post(priceUrl, priceParams).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      setPrice(res.data.estimatedPrice);
      setPriceD(res.data.deliveryPartnerPrice);
      setSla(res.data.sla)
      try {
      } catch (error) {
        console.error();
      }
    });
  }
  const handleChangePhoto = (event)=>{
    //setPhotoValue
    console.log("fffff", event.target.value.value);
    setPhotoValue(event.target.value.value);
    let params = {
      user_id: localStorage.getItem("userId"),
      location: address,
      type_photo: event.target.value.value,
      photo_lat: String(coordinateDelivery.lat),
      photo_lng: String(coordinateDelivery.lng)
    };
    axios.post(priceUrlPhoto, params).then((res) => {
      console.log("PRICEEEEEEE", res.data);
      if(res.data && res.data.estimatedPrice){
        
        if(pricePhoto === "0" && photoChecked){
          setPrice(price + res.data.estimatedPrice);
          setPricePhoto(res.data.estimatedPrice);
        }
      }

      try {
      } catch (error) {
        console.error();
      }
    });

  }
  React.useEffect(() => {
    for (let i = 0; i < Configuration.Company.length; i++) {
      console.log("444",Configuration.Company[i].logo)
      if(Configuration.Company[i].Cname ===  localStorage.getItem("company")){
         // console.log("444",Configuration.Company[i].immigo_case_number_display)
          setImLoading(Configuration.Company[i].immigo_case_number_display);
          setBikeLoading(Configuration.Company[i].is_bike_enable);
          setCaseNumber(Configuration.Company[i].immigo_case_number_display_name)
      }
  } 

}, []);
  const priceChange = (e) => {
    console.log(e.target.value);
    setPrice(e.target.value);
  };
  function goback() {
    navigate("/services");
  }
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const handlePassport = (event, val) => {
    inputFields[val].passportName = event.target.value;
  };
  const handlePassportNo = (event, val) => {
    inputFields[val].passportNumber = event.target.value;
  };
  const handlePassportImmigo = (event, val) => {
    inputFields[val].immigoCaseNumber = event.target.value;
  };
  const handleId1 = (event, val) => {
    inputFieldsCompany[val].companyId = event.target.value;
  };
  const handleId2 = (event, val) => {
    inputFieldsCompany[val].companyName = event.target.value;
  };
  const handleId3 = (event, val) => {
    inputFieldsCompany[val].immigoCaseNumber = event.target.value;
  };
  const handlephoto = (event, val) => {
    inputFieldsPhoto[val].photoName = event.target.value;
  };
  const handlephotoImmigo = (event, val) => {
    inputFieldsPhoto[val].immigoCaseNumber = event.target.value;
  };
  const handleMcerti = (event, val) => {
    inputFieldsMcerti[val].name = event.target.value;
  };
  const handleMcertiImmigo = (event, val) => {
    inputFieldsMcerti[val].immigoCaseNumber = event.target.value;
  };
  const handleDcerti = (event, val) => {
    inputFieldsDcerti[val].name = event.target.value;
  };
  const handleDcertiImmigo = (event, val) => {
    inputFieldsDcerti[val].immigoCaseNumber = event.target.value;
  };
  const handleBcerti = (event, val) => {
    inputFieldsBcerti[val].name = event.target.value;
  };
  const handleBcertiImmigo = (event, val) => {
    inputFieldsBcerti[val].immigoCaseNumber = event.target.value;
  };
  const handleOther = (event, val) => {
    inputFieldsOther[val].name = event.target.value;
  };
  const handleOtherImmigo = (event, val) => {
    inputFieldsOther[val].immigoCaseNumber = event.target.value;
  };
  const updatePrice = (event) => {
    //setSelected2(false);
    console.log(selected1)
    if(!selected1){
      setPrice(parseInt(price) + (2 * parseInt(quanBlack)));
    }
    else if (selected1){
      setPrice(parseInt(price) - (2 * parseInt(quanBlack)));
    }
    
  };
  const updatePrice1 = (event) => {
    if(!selected2){
 
      setPrice(parseInt(price) + (3 *parseInt(quanWhite)));
    }
    else if (selected2){
      setPrice(parseInt(price) - (3 * parseInt(quanWhite)));
    }
    
  };
  const updatePrice2 = () => {
    if(!selected3){
      setPrice(parseInt(price) + (5 *quanA3 ));
    }
    else if (selected3){
      setPrice(parseInt(price) - (5 * quanA3));
    }
  }
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedTime1 = (time) => {
    console.log("ttttt", time.getTime());
    const currentDate = new Date(pickup.getTime() + 1.9 * 60 * 60 * 1000);
    const selectedDate = new Date(time);

    return currentDate < selectedDate.getTime();
  };
  const filterPassedTime2 = (time) => {
    const currentDate = new Date(pickup1.getTime() + 1.9 * 60 * 60 * 1000);
    const selectedDate = new Date(time);

    return currentDate < selectedDate.getTime();
  };
  const filterPassedTimeD = (time) => {
    let currentDate = "";
    if (pickup !== null && pickup1 === null && pickup2 === null) {
      if(sla !== '4'){
        currentDate = new Date(pickup.getTime() + 1.9 * 60 * 60 * 1000);
      }
      else{
        currentDate = new Date(pickup.getTime() + 3.8 * 60 * 60 * 1000);
      }
    }
    
    if (pickup !== null && pickup1 !== null && pickup2 === null) {
    
      if(sla !== '4'){
        currentDate = new Date(pickup.getTime() + 1.9 * 60 * 60 * 1000);
      }
      else{
        currentDate = new Date(pickup.getTime() + 3.8 * 60 * 60 * 1000);
      }
    }
  
    if (pickup !== null && pickup1 !== null && pickup2 !== null ) {
      if(sla !== '4'){
        currentDate = new Date(pickup.getTime() + 1.9 * 60 * 60 * 1000);
      }
      else{
        currentDate = new Date(pickup.getTime() + 3.8 * 60 * 60 * 1000);
      }
    }
    const selectedDate = new Date(time);

    return currentDate < selectedDate.getTime();
  };

  const [alignmentPickup1, setAlignmentPickup1] = React.useState("AM");

  const [alignmentPickup2, setAlignmentPickup2] = React.useState("AM");

  const PickupTimeInput1 = forwardRef(({ value, onClick }, ref) => (
    <FormControl
      fullWidth
      className={clsx(classes.margin, classes.textField)}
      variant="outlined"
    >
      <OutlinedInput
        id="outlined-adornment-weight"
        name="pktime"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Pick Up Time if any"
        startAdornment={
          <InputAdornment position="start">
            {" "}
            <img src="./Images/clock-two.svg"></img>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
        labelWidth={0}
        className={clsx(classes.searchInput, classes.timeSelector)}
        endAdornment={
          <ToggleButtonGroup
            fullWidth
            color="info"
            id="pickupAA"
            value={alignmentPickup1}
            exclusive
            onChange={(e, value) => setAlignmentPickup1(value)}
            className={classes.timeGroup}
            onClick={(e) => e.stopPropagation()}
          >
            <ToggleButton
              className={clsx("toggledts", "toggle-button-left")}
              value="AM"
            >
              AM
            </ToggleButton>
            <ToggleButton
              className={clsx("toggledts", "toggle-button-right")}
              value="PM"
            >
              PM
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
    </FormControl>
  ));

  const PickupTimeInput2 = forwardRef(({ value, onClick }, ref) => (
    <FormControl
      fullWidth
      className={clsx(classes.margin, classes.textField)}
      variant="outlined"
    >
      <OutlinedInput
        id="outlined-adornment-weight"
        name="pktime"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Pick Up Time if any"
        startAdornment={
          <InputAdornment position="start">
            {" "}
            <img src="./Images/clock-two.svg"></img>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
        labelWidth={0}
        className={clsx(classes.searchInput, classes.timeSelector)}
        endAdornment={
          <ToggleButtonGroup
            fullWidth
            color="info"
            id="pickupAA"
            value={alignmentPickup2}
            exclusive
            onChange={(e, value) => setAlignmentPickup2(value)}
            className={classes.timeGroup}
            onClick={(e) => e.stopPropagation()}
          >
            <ToggleButton
              className={clsx("toggledts", "toggle-button-left")}
              value="AM"
            >
              AM
            </ToggleButton>
            <ToggleButton
              className={clsx("toggledts", "toggle-button-right")}
              value="PM"
            >
              PM
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
    </FormControl>
  ));

  const setTimeDesc = (date) => {
    let fixDate = new Date().setHours(17, 0, 0); // for 3:00:00 pm
    let currDate = date;

    if (fixDate < currDate) {
      setOpenError(true);
    }
    setPickup(date);
    setData3(date)
    let temp = moment(date).format("hh:mm");
    //alert("dfd")
    console.log(temp);
    if (
      temp === "08:00" ||
      temp === "08:30" ||
      temp === "09:30" ||
      temp === "09:00" ||
      temp === "10:00" ||
      temp === "10:30" ||
      temp === "11:00" ||
      temp === "11:30"
    ) {
      setAlignmentPickup("AM");
    } else {
      setAlignmentPickup("PM");
    }
  };
  const setDesc1 = (date) => {
    setPickup1(date);
    let temp = moment(date).format("hh:mm");
    //alert("dfd")
    console.log(temp);
    if (
      temp === "08:00" ||
      temp === "08:30" ||
      temp === "09:30" ||
      temp === "09:00" ||
      temp === "10:00" ||
      temp === "10:30" ||
      temp === "11:00" ||
      temp === "11:30"
    ) {
      setAlignmentPickup1("AM");
    } else {
      setAlignmentPickup1("PM");
    }
  };
  const setDesc2 = (date) => {
    setPickup2(date);
    let temp = moment(date).format("hh:mm");
    //alert("dfd")
    console.log(temp);
    if (
      temp === "08:00" ||
      temp === "08:30" ||
      temp === "09:30" ||
      temp === "09:00" ||
      temp === "10:00" ||
      temp === "10:30" ||
      temp === "11:00" ||
      temp === "11:30"
    ) {
      setAlignmentPickup2("AM");
    } else {
      setAlignmentPickup2("PM");
    }
  };
  const setDescDel = (date) => {
    setDrop(date);
    setData4(date)
    let temp = moment(date).format("hh:mm");
    //alert("dfd")
    console.log(temp);
    if (
      temp === "08:00" ||
      temp === "08:30" ||
      temp === "09:30" ||
      temp === "09:00" ||
      temp === "10:00" ||
      temp === "10:30" ||
      temp === "11:00" ||
      temp === "11:30"
    ) {
      setAlignment("AM");
    } else {
      setAlignment("PM");
    }
  };
  {
    /*Stopss*/
  }
  const handleSelectStop = async (value) => {
    const result = await geocodeByAddress(value);
    console.log(JSON.stringify(result));
    const ll = await getLatLng(result[0]);
    setAddressStop(value);
    setCoordinatesStop(ll);
    console.log(JSON.stringify(ll));
    // geocodeByAddress(address)
    //    .then(results => getLatLng(results[0]))
    //    .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };

  const PickupTimeInput = forwardRef(({ value, onClick }, ref) => (
    <FormControl
      fullWidth
      className={clsx(classes.margin, classes.textField)}
      variant="outlined"
    >
      <OutlinedInput
        id="outlined-adornment-weight"
        name="pktime"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Pick Up Time if any"
        startAdornment={
          <InputAdornment position="start">
            {" "}
            <img src="./Images/clock-two.svg"></img>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
        labelWidth={0}
        className={clsx(classes.searchInput, classes.timeSelector)}
        endAdornment={
          <ToggleButtonGroup
            fullWidth
            color="info"
            id="pickupAA"
            value={alignmentPickup}
            exclusive
            onChange={(e, value) => setAlignmentPickup(value)}
            className={classes.timeGroup}
            onClick={(e) => e.stopPropagation()}
          >
            <ToggleButton
              className={clsx("toggledts", "toggle-button-left")}
              value="AM"
            >
              AM
            </ToggleButton>
            <ToggleButton
              className={clsx("toggledts", "toggle-button-right")}
              value="PM"
            >
              PM
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
    </FormControl>
  ));

  const DropTimeInput = forwardRef(({ value, onClick }, ref) => (
    <FormControl
      fullWidth
      className={clsx(classes.margin, classes.textField)}
      variant="outlined"
    >
      <OutlinedInput
        id="outlined-adornment-weight"
        name="deliveryTime"
        placeholder="Drop Time"
        onClick={onClick}
        value={value}
        ref={ref}
        startAdornment={
          <InputAdornment position="start">
            {" "}
            <img src="./Images/clock-two.svg"></img>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
        labelWidth={0}
        className={classes.searchInput}
        endAdornment={
          <ToggleButtonGroup
            fullWidth
            color="info"
            value={alignment}
            exclusive
            onChange={(e, value) => setAlignment(value)}
            className={classes.timeGroup}
            onClick={(e) => e.stopPropagation()}
          >
            <ToggleButton
              className={clsx("toggledts", "toggle-button-left")}
              value="AM"
            >
              AM
            </ToggleButton>
            <ToggleButton
              className={clsx("toggledts", "toggle-button-right")}
              value="PM"
            >
              PM
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
    </FormControl>
  ));

  return (
    <>
      <Allservices></Allservices>
      <Grid container className={classes.section_start}>
        <Grid container className={classes.newbg}>
          <Grid container className={classes.secn}>
            <Grid container className={classes.ht}>
              <Grid container className={classes.send_bg}>
                <Grid item xs={8} md={5} lg={5}>
                  <div className={classes.heading}>
                    <img
                      className={classes.send_img}
                      src="./Images/Io.svg"
                      onClick={() => goback()}
                    ></img>
                    &nbsp;&nbsp;
                    <div className={classes.send_heading}> Send Package</div>
                  </div>
                </Grid>
                <Grid item xs={4} md={7} lg={7}>
                  <div className={classes.send_header}>
                    <ColoredLine color="#131C4C" />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.vat}>
                  Send packages to your clients/companies in a secure manner
                  with double authentication features within the same day.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TabsUnstyled value={tabvalue} className={classes.ht}>
            <TabsList className={classes.tabList}>
              <Tab className={classes.tabItem} onClick={() => switchTab1()}>
                <Box className={classes.tabContent}>
                  <img
                    className={classes.icon}
                    src={
                      activeTab != 1
                        ? "./Images/clock_dark.png"
                        : "./Images/clock_lite.png"
                    }
                    width={49}
                    height={49}
                  ></img>
                  {/*<AccessTimeIcon className={classes.icon}></AccessTimeIcon>*/}
                  <div className={classes.tab2}>
                    <span className={classes.mainTab}>Deliver Now</span>
                    <span
                      className={clsx(
                        classes.mainTab2,
                        activeTab != 1 && classes.mainInactive
                      )}
                    >
                      Within 2 Hours &nbsp;<b></b>&nbsp;
                    </span>
                  </div>
                </Box>
              </Tab>
              <Tab className={classes.tabItem} onClick={() => switchTab2()}>
                <Box className={classes.tabContent}>
                  <img
                    className={classes.icon}
                    src={
                      activeTab != 2
                        ? "./Images/date_dark.png"
                        : "./Images/date_lite.png"
                    }
                    width={46}
                    height={51}
                  ></img>
                  {/*<CalendarTodayIcon className={classes.icon}></CalendarTodayIcon>*/}
                  <div className={classes.tab2}>
                    <span className={classes.mainTab}>Schedule for Later</span>
                    <span
                      className={clsx(
                        classes.mainTab2,
                        activeTab != 2 && classes.mainInactive
                      )}
                    >
                      Next Day Delivery &nbsp;<b></b>&nbsp;
                    </span>
                  </div>
                </Box>
              </Tab>
            </TabsList>
            <TabPanel value={0} index={0}>
              <div></div>
              <div className={classes.will}>
                We will assign the nearest delivery agent to pick up and deliver
                as soon as possible
              </div>

              <div className={classes.req}>
                <p className={classes.revert}>
                  <b className={classes.reqi}>
                    Select based on your requirement :
                  </b>
                  <p
                    className={
                      alignmentTrans === "bike"
                        ? classes.two_wheel
                        : classes.two_wheel_inactive
                    }
                  >
                    <b>2 Wheeler</b>
                  </p>
                  <div className={classes.del_icon2}>
                    {showBike ? (<ToggleButtonGroup
                      fullWidth
                      color="info"
                      id="pickupAA"
                      value={alignmentTrans}
                     // value={"car"}
                      exclusive
                      onChange={(e, value) => {setAlignmentTransport(value);callPriceParams(value)}}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ToggleButton
                        className={clsx("toggledtsCar", "toggle-button-left")}
                        value="bike"
                      >
                        {alignmentTrans === "bike" ? (
                          <img src="./Images/wbike.svg"></img>
                        ) : (
                          <img src="./Images/gbike.svg"></img>
                        )}
                      </ToggleButton>
                      <ToggleButton
                        className={clsx("toggledtsCar", "toggle-button-right")}
                        value="car"
                      >
                        {alignmentTrans === "bike" ? (
                          <img src="./Images/gcar.svg"></img>
                        ) : (
                          <img src="./Images/wcar.svg"></img>
                        )}
                      </ToggleButton>
                    </ToggleButtonGroup>):
                    (<ToggleButtonGroup
                      fullWidth
                      color="info"
                      id="pickupAA"
                      value={'car'}
                     // value={"car"}
                      exclusive
                      //onChange={(e, value) => setAlignmentTransport(value)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ToggleButton
                        className={clsx("toggledtsCar", "toggle-button-left")}
                        value="bike"
                      >
                        {alignmentTrans === "bike" ? (
                          <img src="./Images/wbike.svg"></img>
                        ) : (
                          <img src="./Images/gbike.svg"></img>
                        )}
                      </ToggleButton>
                      <ToggleButton
                        className={clsx("toggledtsCar", "toggle-button-right")}
                        value="car"
                      >
                        {alignmentTrans === "bike" ? (
                          <img src="./Images/gcar.svg"></img>
                        ) : (
                          <img src="./Images/wcar.svg"></img>
                        )}
                      </ToggleButton>
                    </ToggleButtonGroup>) }
                    
                  </div>
                  <p
                    className={
                      alignmentTrans === "bike"
                        ? classes.four_wheel
                        : classes.four_wheel_active
                    }
                  >
                    4 Wheeler
                  </p>
                </p>{" "}
                &nbsp;
                {/*<p className={mode === 'bike' ? classes.two_wheel : classes.two_wheel_inactive}><b>2 Wheeler</b></p>
                <div className={classes.del_icon}>
          
                  <img src='./Images/biked.svg' className={mode === 'bike' ? classes.biked : classes.biked_inactive} width={41} height={31}></img>&nbsp;
                </div>
                <SwitchUnstyled component={Root} onChange={(e, value) => setMode(e.target.checked ? 'bike' : 'car')} />
                <img src='./Images/sedan.png' height={60} className={mode === 'bike' && classes.sedan}></img>&nbsp;
                <p className={mode === 'bike' ? classes.four_wheel : classes.four_wheel_active}>4 Wheeler</p>
             */}{" "}
              </div>
              {/*Weights not in cooperate login */}
              {/*<div className={classes.content}>
                <div>Weights</div>
                <div className={classes.content}>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  className={classes.weightGroup}
                  fullWidth
                >
                  <ToggleButton className={classes.weightButton} value="web">Upto 1kg</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="android">Upto 5kg</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="ios1">Upto 10kg</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="ios2">Upto 15kg</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="ios23">Upto 20kg</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="ios4">20+</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="ios5">Unsure</ToggleButton>

                </ToggleButtonGroup>
                </div>

  </div>*/}

              <form onSubmit={handleSubmit}>
                <div className={classes.content1}>

                  {showIM ? (<>
                  <div className={classes.send_label}>{caseNumber}</div>
                  <Grid container spacing={2}>
                  {immigoSet === 'single' ? (
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl
                      fullWidth
                      className={clsx(classes.margin, classes.textField)}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        name="immigo"
                        type="text"
                        value={imigo}
                        onChange={(event) => ImgNumber(event)}
                        placeholder={"Enter "+ caseNumber}
                        startAdornment={
                          <InputAdornment position="start">
                            {" "}
                            <img src="./Images/imigo.svg"></img>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength:8,
                          "aria-label": "weight"
                     
                         
                        }}
                        labelWidth={0}
                        className={classes.searchInput}
                      />
                    </FormControl>
                    {errorImg ? (
                      <>
                        <div className={classes.errors}>
                          Enter  {caseNumber}
                        </div>
                      </>
                    ) : null}
                  </Grid>):(<></>)}
                {localStorage.getItem('company') === 'pwc' ? (
                  <Grid item xs={12} md={4} lg={4}>
                  <ToggleButtonGroup
                      fullWidth
                      color="info"
                      id="pickupAA"
                      value={immigoSet}
                     // value={"car"}
                      exclusive
                      onChange={(e, value) => {setImmigoSet(value)}}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ToggleButton
                      
                        className={clsx("toggledtsSingle", "toggle-button-multiple")}
                        value="single"
                      >
                     Single
                      </ToggleButton>
                      <ToggleButton
                        className={clsx("toggledtsSingle", "toggle-button-Multipleright")}
                        value="multiple"
                      >
                       Multiple
                      </ToggleButton>
                    </ToggleButtonGroup>
                    </Grid>):(<></>)}
                  </Grid>
                  <br />
                  <br />
                  </>):
                  (<></>)}


                  <div className={classes.send_label}>Pick Up Address</div>
                  <br/>
                  {savedData && savedData !== undefined && savedData.data?.length ? (
  <>
    <div className={classes.send_label_address}>Select a saved address</div>
    {/* <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{"<"}</button>
                <span>{currentPage} / {Math.ceil(savedData.data.length / addressesPerPage)}</span>
                <button onClick={handleNextPage} disabled={currentPage === Math.ceil(savedData.data.length / addressesPerPage)}>{">"}</button>
            </div> */}
    <div style={{ display: 'flex', overflowX: 'auto', gap: '20px', padding: '10px' }}>
      {savedData.data.map((save, index) => (
        save.user_bookmarked_address.is_active ? (
        <div
          key={index}
          className="address-card"
          style={{ flexShrink: 0 }}
        >
          <div className="address-header">
            <div className="address-info">
              <ToggleButton
                style={{ marginRight: "10px !important" }}
                value="check"
                selected={selectedIndex === index}  // Bind to the specific index and convert undefined to false
                onChange={() => toggleCheck(index,save)}
                className={clsx("toggledtsPrefer3", "toggle-button-left")}
                
              >
                <CheckIcon />
              </ToggleButton>
              <span className="address-title">
                &nbsp;
                {save.user_bookmarked_address.bookmark_name}
              </span>
            </div>
            <Button onClick={()=>removeAddress(save)} className="remove-button">Remove</Button>
          </div>
          <div className="address-details">
            <p>
              {save.user_bookmarked_address.address}
            </p>
          </div>
        </div>):(<></>)
      ))}
    </div>
    <div className={classes.send_label_address}>Or Add Your Address</div>
  </>
 
) : (<> </>)}

                
                 
    <br/>
   
                  <div className={classes.content}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          {/*} <OutlinedInput
                  id="outlined-adornment-weight"
                  placeholder='Address'
                  name="address"
                  startAdornment={<InputAdornment position="start"> <img src='./Images/loc1.svg'></img></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  labelWidth={0}
                />*/}
                          <PlacesAutocomplete
                            value={address}
                           
                            onChange= {setAddress}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  placeholder="Address"
                                  fullWidth
                                  name="address"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/loc1.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />

                                <div className={classes.locationdropdown}>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                          padding: "10px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          padding: "10px",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </FormControl>
                        {error1 ? (
                          <>
                            <div className={classes.errors}>
                              Select pickup location
                            </div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="mobileNo"
                            value={phone}
                            onChange={(event) => pickupPhone(event)}
                            placeholder="Phone Number"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src="./Images/mobile.svg"></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {error2 ? (
                          <>
                            <div className={classes.errors}>
                              Enter mobile number
                            </div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="email"
                            value={email}
                            onChange={(event) => pickupEmail(event)}
                            placeholder="User Email Address"
                            startAdornment={
                              <InputAdornment position="start">
                                {" "}
                                <img src="./Images/email-send.svg"></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {error3 ? (
                          <>
                            <div className={classes.errors}>Enter E-mail</div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid  xs={12} item md={4} lg={4}>
                        <DatePicker
                        value={pickTime}
                          selected={pickup}
                          onChange={(date) => setTimeDesc(date)}
                          customInput={<PickupTimeInput />}
                          showTimeSelect
                          showTimeSelectOnly
                          filterTime={filterPassedTime}
                          excludeTimes={[
                            setHours(setMinutes(new Date(), 0), 0),
                            setHours(setMinutes(new Date(), 30), 0),
                            setHours(setMinutes(new Date(), 0), 1),
                            setHours(setMinutes(new Date(), 30), 1),
                            setHours(setMinutes(new Date(), 0), 2),
                            setHours(setMinutes(new Date(), 30), 2),
                            setHours(setMinutes(new Date(), 0), 3),
                            setHours(setMinutes(new Date(), 30), 3),
                            setHours(setMinutes(new Date(), 0), 4),
                            setHours(setMinutes(new Date(), 30), 4),
                            setHours(setMinutes(new Date(), 0), 5),
                            setHours(setMinutes(new Date(), 30), 5),
                            setHours(setMinutes(new Date(), 0), 6),
                            setHours(setMinutes(new Date(), 30), 6),
                            setHours(setMinutes(new Date(), 0), 7),
                            setHours(setMinutes(new Date(), 30), 7),

                            setHours(setMinutes(new Date(), 0), 23),
                            setHours(setMinutes(new Date(), 30), 23),
                            setHours(setMinutes(new Date(), 0), 22),
                            setHours(setMinutes(new Date(), 30), 22),
                            setHours(setMinutes(new Date(), 0), 21),
                            setHours(setMinutes(new Date(), 30), 21),
                            setHours(setMinutes(new Date(), 0), 20),
                            setHours(setMinutes(new Date(), 30), 20),
                            setHours(setMinutes(new Date(), 0), 19),
                            setHours(setMinutes(new Date(), 30), 19),
                            setHours(setMinutes(new Date(), 30), 18),
                            setHours(setMinutes(new Date(), 0), 18),
                            setHours(setMinutes(new Date(), 30), 17),
                          ]}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />

                        {/*} <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                  <TimePicker value={selectedDate} onChange={handleDateChange} />

                                </MuiPickersUtilsProvider>*/}
                        {error4 ? (
                          <>
                            <div className={classes.errors}>Enter time</div>
                          </>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="name"
                            value={pickupName}
                            onChange={(event) => pickupNameSet(event)}
                            placeholder="Name"
                            startAdornment={
                              <InputAdornment position="start">
                                {" "}
                                <img
                                  className="nameus"
                                  src="./Images/name.svg"
                                ></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {errorName ? (
                          <>
                            <div className={classes.errors}>Enter Name</div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            placeholder="Instruction to Delivery Agent"
                            name="pkinstruction"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src="./Images/direction.svg"></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            value={descript}
                            labelWidth={0}
                            onChange={(event) => pickupDesc(event)}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {error5 ? (
                          <>
                            <div className={classes.errors}>
                              Enter pickup description
                            </div>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{display:"flex"}}>
                  <ToggleButton
                        value="check1"
                      
                        className={clsx(
                          "toggledtsPrefer3",
                          "toggle-button-left"
                        )}
                        onChange={() => {
                          openSavedAddressPopup()
                        }}
                       
                      >
                        <CheckIcon />
                      </ToggleButton>
                      <span  style={{paddingTop:"20px",paddingLeft:"25px"}} className={classes.send_label_address}>Save address for future delivery</span>
                  </div>
                 
                </div>
                {/*ADD STOP*/}
                <div className={classes.content1A} onClick={getArrowDown}>
                  <span className={classes.send_label}>
                    Add pickup point(Optional)
                  </span>
                  <span>
                    {arrow ? (
                      <img
                        className={classes.arrow}
                        src="./Images/up-arrow.png"
                      ></img>
                    ) : (
                      <></>
                    )}
                    {!arrow ? (
                      <img
                        className={classes.arrow}
                        src="./Images/arrowdn.png"
                      ></img>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
                {arrow ? (
                  <>
                    <div className={classes.content1}>
                      <div className={classes.send_label}>
                        <span>Add Pick Up Point 1</span>
                        <span onClick={clearDetails}>Clear all</span>
                      </div>

                      <div className={classes.content}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={12}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              {/*} <OutlinedInput
                  id="outlined-adornment-weight"
                  placeholder='Address'
                  name="address"
                  startAdornment={<InputAdornment position="start"> <img src='./Images/loc1.svg'></img></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  labelWidth={0}
                />*/}
                              <PlacesAutocomplete
                                value={address1}
                                onChange={setAddress1}
                                onSelect={handleSelect1}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      placeholder="Address"
                                      fullWidth
                                      name="address"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          {" "}
                                          <img src="./Images/loc1.svg"></img>
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input",
                                      })}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                    />

                                    <div className={classes.locationdropdown}>
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                              padding: "10px",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                              padding: "10px",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </FormControl>
                            {error1 ? (
                              <>
                                <div className={classes.errors}>
                                  Select pickup location
                                </div>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                name="mobileNo"
                                value={phone1}
                                onChange={(event) => pickupPhone1(event)}
                                placeholder="Phone Number"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <img src="./Images/mobile.svg"></img>
                                  </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                labelWidth={0}
                                className={classes.searchInput}
                              />
                            </FormControl>
                            {error2 ? (
                              <>
                                <div className={classes.errors}>
                                  Enter mobile number
                                </div>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                name="email"
                                value={email1}
                                onChange={(event) => pickupEmail1(event)}
                                placeholder="User Email Address"
                                startAdornment={
                                  <InputAdornment position="start">
                                    {" "}
                                    <img src="./Images/email-send.svg"></img>
                                  </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                labelWidth={0}
                                className={classes.searchInput}
                              />
                            </FormControl>
                            {error3 ? (
                              <>
                                <div className={classes.errors}>
                                Enter E-mail
                                </div>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <DatePicker
                            value={pickTime1}
                              selected={pickup1}
                              onChange={(date) => setDesc1(date)}
                              customInput={<PickupTimeInput1 />}
                              showTimeSelect
                              showTimeSelectOnly
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              excludeTimes={[
                                setHours(setMinutes(new Date(), 0), 0),
                                setHours(setMinutes(new Date(), 30), 0),
                                setHours(setMinutes(new Date(), 0), 1),
                                setHours(setMinutes(new Date(), 30), 1),
                                setHours(setMinutes(new Date(), 0), 2),
                                setHours(setMinutes(new Date(), 30), 2),
                                setHours(setMinutes(new Date(), 0), 3),
                                setHours(setMinutes(new Date(), 30), 3),
                                setHours(setMinutes(new Date(), 0), 4),
                                setHours(setMinutes(new Date(), 30), 4),
                                setHours(setMinutes(new Date(), 0), 5),
                                setHours(setMinutes(new Date(), 30), 5),
                                setHours(setMinutes(new Date(), 0), 6),
                                setHours(setMinutes(new Date(), 30), 6),
                                setHours(setMinutes(new Date(), 0), 7),
                                setHours(setMinutes(new Date(), 30), 7),

                                setHours(setMinutes(new Date(), 0), 23),
                                setHours(setMinutes(new Date(), 30), 23),
                                setHours(setMinutes(new Date(), 0), 22),
                                setHours(setMinutes(new Date(), 30), 22),
                                setHours(setMinutes(new Date(), 0), 21),
                                setHours(setMinutes(new Date(), 30), 21),
                                setHours(setMinutes(new Date(), 0), 20),
                                setHours(setMinutes(new Date(), 30), 20),
                                setHours(setMinutes(new Date(), 0), 19),
                                setHours(setMinutes(new Date(), 30), 19),
                                setHours(setMinutes(new Date(), 30), 18),
                                setHours(setMinutes(new Date(), 0), 18),
                                setHours(setMinutes(new Date(), 30), 17),
                              ]}
                              filterTime={filterPassedTime1}
                            />
                            {/*} <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                  <TimePicker value={selectedDate} onChange={handleDateChange} />

                                </MuiPickersUtilsProvider>*/}
                            {error4 ? (
                              <>
                                <div className={classes.errors}>
                                  Select time
                                </div>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                name="name"
                                value={pickupName1}
                                onChange={(event) => pickupNameSet1(event)}
                                placeholder="Name"
                                startAdornment={
                                  <InputAdornment position="start">
                                    {" "}
                                    <img
                                      className="nameus"
                                      src="./Images/name.svg"
                                    ></img>
                                  </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                labelWidth={0}
                                className={classes.searchInput}
                              />
                            </FormControl>
                          </Grid>
                          <Grid xs={12} item md={12} lg={12}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                placeholder="Instruction to  Delivery Agent"
                                name="pkinstruction"
                                value={descript1}
                                onChange={(event) => pickupDesc1(event)}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <img src="./Images/direction.svg"></img>
                                  </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                labelWidth={0}
                                className={classes.searchInput}
                              />
                            </FormControl>
                            {error5 ? (
                              <>
                                <div className={classes.errors}>
                                Enter pickup description
                                </div>
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <span className={classes.plusIcon}>
                      {!plus ? (
                        <>
                          {" "}
                          <div onClick={addPlus} className={classes.will2}>
                            Add more <AddIcon></AddIcon>
                          </div>
                        </>
                      ) : (
                        <>
                          <div onClick={addPlus} className={classes.will2}>
                            Remove <RemoveIcon onClick={addPlus}></RemoveIcon>
                          </div>
                        </>
                      )}
                    </span>
                    {plus ? (
                      <div className={classes.content1}>
                        <div className={classes.send_label}>
                          Add Pick Up Point 2(Optional)
                        </div>
                        <div className={classes.content}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                {/*} <OutlinedInput
                  id="outlined-adornment-weight"
                  placeholder='Address'
                  name="address"
                  startAdornment={<InputAdornment position="start"> <img src='./Images/loc1.svg'></img></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  labelWidth={0}
                />*/}
                                <PlacesAutocomplete
                                  value={address2}
                                  onChange={setAddress2}
                                  onSelect={handleSelect2}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div>
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        placeholder="Address"
                                        fullWidth
                                        name="address"
                                        startAdornment={
                                          <InputAdornment position="start">
                                            {" "}
                                            <img src="./Images/loc1.svg"></img>
                                          </InputAdornment>
                                        }
                                        aria-describedby="outlined-weight-helper-text"
                                        {...getInputProps({
                                          placeholder: "Search Places ...",
                                          className: "location-search-input",
                                        })}
                                        labelWidth={0}
                                        className={classes.searchInput}
                                      />

                                      <div className={classes.locationdropdown}>
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                                padding: "10px",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                                padding: "10px",
                                              };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </FormControl>
                              {error1 ? (
                                <>
                                  <div className={classes.errors}>
                                    Select pickup location
                                  </div>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="mobileNo"
                                  value={phone2}
                                  onChange={(event) => pickupPhone2(event)}
                                  placeholder="Phone Number"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src="./Images/mobile.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                              {error2 ? (
                                <>
                                  <div className={classes.errors}>
                                  Enter mobile number
                                  </div>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="email"
                                  value={email2}
                                  onChange={(event) => pickupEmail2(event)}
                                  placeholder="User Email Address"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/email-send.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                              {error3 ? (
                                <>
                                  <div className={classes.errors}>
                                  Enter E-mail
                                  </div>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <DatePicker
                              value={pickTime2}
                                selected={pickup2}
                                onChange={(date) => setDesc2(date)}
                                customInput={<PickupTimeInput2 />}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                excludeTimes={[
                                  setHours(setMinutes(new Date(), 0), 0),
                                  setHours(setMinutes(new Date(), 30), 0),
                                  setHours(setMinutes(new Date(), 0), 1),
                                  setHours(setMinutes(new Date(), 30), 1),
                                  setHours(setMinutes(new Date(), 0), 2),
                                  setHours(setMinutes(new Date(), 30), 2),
                                  setHours(setMinutes(new Date(), 0), 3),
                                  setHours(setMinutes(new Date(), 30), 3),
                                  setHours(setMinutes(new Date(), 0), 4),
                                  setHours(setMinutes(new Date(), 30), 4),
                                  setHours(setMinutes(new Date(), 0), 5),
                                  setHours(setMinutes(new Date(), 30), 5),
                                  setHours(setMinutes(new Date(), 0), 6),
                                  setHours(setMinutes(new Date(), 30), 6),
                                  setHours(setMinutes(new Date(), 0), 7),
                                  setHours(setMinutes(new Date(), 30), 7),

                                  setHours(setMinutes(new Date(), 0), 23),
                                  setHours(setMinutes(new Date(), 30), 23),
                                  setHours(setMinutes(new Date(), 0), 22),
                                  setHours(setMinutes(new Date(), 30), 22),
                                  setHours(setMinutes(new Date(), 0), 21),
                                  setHours(setMinutes(new Date(), 30), 21),
                                  setHours(setMinutes(new Date(), 0), 20),
                                  setHours(setMinutes(new Date(), 30), 20),
                                  setHours(setMinutes(new Date(), 0), 19),
                                  setHours(setMinutes(new Date(), 30), 19),
                                  setHours(setMinutes(new Date(), 30), 18),
                                  setHours(setMinutes(new Date(), 0), 18),
                                  setHours(setMinutes(new Date(), 30), 17),
                                ]}
                                filterTime={filterPassedTime2}
                              />
                              {/*} <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                  <TimePicker value={selectedDate} onChange={handleDateChange} />

                                </MuiPickersUtilsProvider>*/}
                              {error4 ? (
                                <>
                                  <div className={classes.errors}>
                                    Select time
                                  </div>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  name="name"
                                  value={pickupName2}
                                  onChange={(event) => pickupNameSet2(event)}
                                  placeholder="Name"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img
                                        className="nameus"
                                        src="./Images/name.svg"
                                      ></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item  xs={12} md={12} lg={12}>
                              <FormControl
                                fullWidth
                                className={clsx(
                                  classes.margin,
                                  classes.textField
                                )}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  placeholder="Instruction to  Delivery Agent"
                                  name="pkinstruction"
                                  value={descript2}
                                  onChange={(event) => pickupDesc2(event)}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src="./Images/direction.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />
                              </FormControl>
                              {error5 ? (
                                <>
                                  <div className={classes.errors}>
                                  Enter pickup description
                                  </div>
                                </>
                              ) : null}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {/*END STOP*/}

                <div className={classes.content1}>
                  <div className={classes.send_label}>Delivery Address</div>
                  <br/>
                  {savedData && savedData !== undefined && savedData.data?.length ? (
  <>
    <div className={classes.send_label_address}>Select a saved address</div>
    {/* <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{"<"}</button>
                <span>{currentPage} / {Math.ceil(savedData.data.length / addressesPerPage)}</span>
                <button onClick={handleNextPage} disabled={currentPage === Math.ceil(savedData.data.length / addressesPerPage)}>{">"}</button>
            </div> */}
    <div style={{ display: 'flex', overflowX: 'auto', gap: '20px', padding: '10px' }}>
      {savedData.data.map((save, index) => (
        save.user_bookmarked_address.is_active ? (
        <div
          key={index}
          className="address-card"
          style={{ flexShrink: 0 }}
        >
          <div className="address-header">
            <div className="address-info">
              <ToggleButton
                style={{ marginRight: "10px !important" }}
                value="check"
                selected={selectedIndexD === index}  // Bind to the specific index and convert undefined to false
                onChange={() => toggleCheckD(index,save)}
                className={clsx("toggledtsPrefer3", "toggle-button-left")}
                
              >
                <CheckIcon />
              </ToggleButton>
              <span className="address-title">
                &nbsp;
                {save.user_bookmarked_address.bookmark_name}
              </span>
            </div>
            <Button onClick={()=>removeAddress(save)} className="remove-button">Remove</Button>
          </div>
          <div className="address-details">
            <p>
              {save.user_bookmarked_address.address}
            </p>
          </div>
        </div>):(<></>)
      ))}
    </div>
    <div className={classes.send_label_address}>Or Add Your Address</div>
  </>
 
) : (<> </>)}

                
                 
    <br/>
                  <div className={classes.content}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          {/*<OutlinedInput
                  id="outlined-adornment-weight"
                  name='deliveryAddress'
                  startAdornment={<InputAdornment position="start"> <img src='./Images/loc1.svg'></img></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  labelWidth={0}
                />*/}
                          <PlacesAutocomplete
                            value={addressDelivery}
                           
                            onChange= { setAddressDelivery}
                            onSelect={handleSelectDelivery}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  placeholder="DeliveryAddress"
                                  name="deliveryAddress"
                                  fullWidth
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {" "}
                                      <img src="./Images/loc1.svg"></img>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                  labelWidth={0}
                                  className={classes.searchInput}
                                />

                                <div className={classes.locationdropdown}>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                          padding: "10px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          padding: "10px",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </FormControl>
                        {error6 ? (
                          <>
                            <div className={classes.errors}>
                              Select delivery location
                            </div>
                          </>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="deliveryMobileNo"
                            value={phoneD}
                            onChange={(event) => phoneDel(event)}
                            placeholder="Phone Number"
                            startAdornment={
                              <InputAdornment position="start">
                                {" "}
                                <img src="./Images/mobile.svg"></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {error7 ? (
                          <>
                            <div className={classes.errors}>
                            Enter delivery mobile number
                            </div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="deliveryEmail"
                            value={emailD}
                            onChange={(event) => emailDel(event)}
                            placeholder="Client Email Address"
                            startAdornment={
                              <InputAdornment position="start">
                                {" "}
                                <img src="./Images/email-send.svg"></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {error8 ? (
                          <>
                            <div className={classes.errors}>
                            Enter delivery E-mail
                            </div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <DatePicker
                        value={dropTime}
                          selected={drop}
                          onChange={(date) => setDescDel(date)}
                          customInput={<DropTimeInput />}
                          showTimeSelect
                          showTimeSelectOnly
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          excludeTimes={[
                            setHours(setMinutes(new Date(), 0), 0),
                            setHours(setMinutes(new Date(), 30), 0),
                            setHours(setMinutes(new Date(), 0), 1),
                            setHours(setMinutes(new Date(), 30), 1),
                            setHours(setMinutes(new Date(), 0), 2),
                            setHours(setMinutes(new Date(), 30), 2),
                            setHours(setMinutes(new Date(), 0), 3),
                            setHours(setMinutes(new Date(), 30), 3),
                            setHours(setMinutes(new Date(), 0), 4),
                            setHours(setMinutes(new Date(), 30), 4),
                            setHours(setMinutes(new Date(), 0), 5),
                            setHours(setMinutes(new Date(), 30), 5),
                            setHours(setMinutes(new Date(), 0), 6),
                            setHours(setMinutes(new Date(), 30), 6),
                            setHours(setMinutes(new Date(), 0), 7),
                            setHours(setMinutes(new Date(), 30), 7),

                            setHours(setMinutes(new Date(), 0), 23),
                            setHours(setMinutes(new Date(), 30), 23),
                            setHours(setMinutes(new Date(), 0), 22),
                            setHours(setMinutes(new Date(), 30), 22),
                            setHours(setMinutes(new Date(), 0), 21),
                            setHours(setMinutes(new Date(), 30), 21),
                            setHours(setMinutes(new Date(), 30), 20),
                            setHours(setMinutes(new Date(), 0), 20),

                            setHours(setMinutes(new Date(), 30), 19),
                          ]}
                          filterTime={filterPassedTimeD}
                        />
                        {error9 ? (
                          <>
                            <div className={classes.errors}>
                              Select delivery time
                            </div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="name"
                            value={pickupNameD}
                            onChange={(event) => pickupNameSetD(event)}
                            placeholder="Name"
                            startAdornment={
                              <InputAdornment position="start">
                                {" "}
                                <img
                                  className="nameus"
                                  src="./Images/name.svg"
                                ></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {errorName1 ? (
                          <>
                            <div className={classes.errors}>Enter Name</div>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="deliveryInstruction"
                            value={descriptD}
                            onChange={(event) => descDel(event)}
                            placeholder="Instruction to  Delivery Agent"
                            startAdornment={
                              <InputAdornment position="start">
                                {" "}
                                <img src="./Images/direction.svg"></img>
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight",
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                        {error10 ? (
                          <>
                            <div className={classes.errors}>
                            Enter delivery instruction
                            </div>
                          </>
                        ) : null}
                      </Grid>

                      {/*}  <Grid item md={12} lg={12} className={classes.content1}>
                        <div className={classes.content1}>
                          <div className={classes.send_label}>
                            Add stop
                          </div>
                          <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">

                            <PlacesAutocomplete
                              value={addressStop}
                              onChange={setAddressStop}
                              onSelect={handleSelectStop}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <OutlinedInput
                                    id="outlined-adornment-weightite"
                                    placeholder='Add stop'
                                    name="addStop"
                                    fullWidth
                                    startAdornment={<InputAdornment position="start"> <img src='./Images/loc1.svg'></img></InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}(Optional)
                                    labelWidth={0}
                                    className={classes.searchInput}
                                  />(Optional)

                                  <div className={classes.locationdropdown}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '10px' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </FormControl>
                        </div>
                      </Grid>*/}
                    </Grid>
                  </div>
                </div>
                <div style={{display:"flex"}}>
                  <ToggleButton
                        value="check1"
                       
                        className={clsx(
                          "toggledtsPrefer3",
                          "toggle-button-left"
                        )}
                        onChange={() => {
                          openSavedAddressPopupDelivery()
                        }}
                       
                      >
                        <CheckIcon />
                      </ToggleButton>
                      <span  style={{paddingTop:"20px",paddingLeft:"25px"}} className={classes.send_label_address}>Save address for future delivery</span>
                  </div>
                <div className={classes.content1}>
                  <div className={classes.itemLabel}>Item Information</div>
                  <div className="contr">What are you sending?</div>
                  <Grid container>
                    <div className={classes.content}>
                      <div>
                        <PopupState
                          variant="contained"
                          popupId="demo-popup-menu"
                        >
                          {(popupState) => (
                            <React.Fragment>
                              <Button
                                className={classes.document_btn}
                                {...bindTrigger(popupState)}
                              >
                                Documents
                                <img
                                  className={classes.ared}
                                  src="./Images/arrowed.svg"
                                ></img>
                              </Button>
                              <Menu
                                {...bindMenu(popupState)}
                                className={classes.popupContainer}
                              >
                                <MenuItem className="dropnew">
                                  <span className="valdet"> Passport</span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemove()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFields.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAdd()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                <MenuItem className="dropnew">
                                  <span className="valdet">Emirates ID</span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemoveCompany()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsCompany.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddCompany()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                <MenuItem className="dropnew">
                                  <span className="valdet">Photo</span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemovePhoto()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsPhoto.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddPhoto()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>

                                <MenuItem className="dropnew">
                                  <span className="valdet">Other</span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemoveOther()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsOther.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddOther()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                {/*<MenuItem onClick={popupState.close}>Logout</MenuItem>*/}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </div>
                    </div>
                    &nbsp;&nbsp;
                    <div className={classes.content}>
                      <div>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <Button
                                className={classes.document_btn}
                                {...bindTrigger(popupState)}
                              >
                                Certificates
                                <img
                                  className={classes.ared}
                                  src="./Images/arrowed.svg"
                                ></img>
                              </Button>
                              <Menu
                                {...bindMenu(popupState)}
                                className={classes.popupContainer}
                              >
                                <MenuItem className="dropnew">
                                  <span className="valdet">
                                    Marriage Certificate
                                  </span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemoveMcerti()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsMcerti.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddMcerti()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                <MenuItem className="dropnew">
                                  <span className="valdet">
                                    Birth Certificate
                                  </span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemoveBcerti()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsBcerti.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddBcerti()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                <MenuItem className="dropnew">
                                  <span className="valdet">
                                    Degree Certificate
                                  </span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemoveDcerti()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsDcerti.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddDcerti()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                <MenuItem className="dropnew">
                                  <span className="valdet">Other</span>
                                  <div className="passd">
                                    <IconButton
                                      onClick={() => handleRemoveOther()}
                                      className="valDocLeft"
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                    <Button className="newed">
                                      {inputFieldsOther.length}
                                    </Button>
                                    <IconButton
                                      onClick={() => handleAddOther()}
                                      className="valDoc"
                                    >
                                      <AddIcon></AddIcon>
                                    </IconButton>
                                  </div>
                                </MenuItem>
                                {/*<MenuItem onClick={popupState.close}>Logout</MenuItem>*/}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </div>
                    </div>
                    &nbsp;&nbsp;
                    {/*} <div className={classes.content}>
                      <div>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <Button className={classes.document_btn} {...bindTrigger(popupState)}>
                                Photo<img className={classes.ared} src='./Images/arrowed.svg'></img>
                              </Button>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </div>
                    </div>&nbsp;&nbsp;*/}
                    {/* <div className={classes.content}>
                      <div>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <Button
                                className={classes.document_btn}
                                {...bindTrigger(popupState)}
                              >
                                Agreement
                                <img
                                  className={classes.ared}
                                  src="./Images/arrowed.svg"
                                ></img>
                              </Button>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </div>
                    </div> */}
                    &nbsp;&nbsp;
                    {/*}  <div className={classes.content}>
                      <div>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <Button className={classes.document_btn} {...bindTrigger(popupState)} onClick={() => handleAddOther()}>
                                Others
                              </Button>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </div>
                    </div>*/}
                  </Grid>

                  <Grid container className={classes.documents} containe>
                    {inputFields.map((inputField, index) => (
                      <div key={index}>                        
                        <Grid
                          container
                          className={classes.documents}
                          spacing={1}
                        >
                           {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder={"Enter "+ caseNumber}
                                defaultValue={inputField.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handlePassportImmigo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={6} lg={6}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="passportName"
                                placeholder="Passport Name"
                                defaultValue={inputField.passportName}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) =>
                                  handlePassport(event, index)
                                }
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="passportNumber"
                                placeholder="Passport Number"
                                defaultValue={inputField.passportNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handlePassportNo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                         
                        </Grid>
                        <br/>
                      </div>
                    ))}
                  </Grid>
                  <Grid container className={classes.documents}>
                    {inputFieldsCompany.map((inputField, index) => (
                      <div key={index}>
                        <Grid container spacing={1}>
                        {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder={"Enter "+ caseNumber}
                                defaultValue={inputField.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handleId3(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={6} lg={6}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                placeholder="Emirates ID Number"
                                name="Company Id"
                                defaultValue={inputField.passportName}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handleId1(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6} lg={6}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                placeholder="Emirates ID Name"
                                name="Company Name"
                                defaultValue={inputField.passportNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handleId2(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                  <Grid container className={classes.documents}>
                    {inputFieldsPhoto.map((inputFieldsPhoto, index) => (
                      <div key={index}>
                        <Grid container className={classes.documents}>
                        {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder="Tiger Case Number"
                                defaultValue={inputFieldsPhoto.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handlephotoImmigo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={12} lg={12}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="photo"
                                placeholder="Description photo"
                                defaultValue={inputFieldsPhoto.photoName}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handlephoto(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                  <Grid container className={classes.documents}>
                    {inputFieldsMcerti.map((inputFieldsMcerti, index) => (
                      <div key={index}>
                        <Grid container className={classes.documents}>
                        {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder="Tiger Case Number"
                                defaultValue={inputFieldsMcerti.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handleMcertiImmigo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={12} lg={12}  sx={{width: '250px !important'}}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="mcerti"
                               
                                placeholder="Description Marriage Certificate"
                                defaultValue={inputFieldsMcerti.name}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handleMcerti(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                  <Grid container className={classes.documents}>
                    {inputFieldsBcerti.map((inputFieldsBcerti, index) => (
                      <div key={index}>
                        <Grid container className={classes.documents}>
                        {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder="Tiger Case Number"
                                defaultValue={inputFieldsBcerti.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handleBcertiImmigo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={12} lg={12} sx={{width: '250px !important'}}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="bcerti"
                                placeholder="Description Birth Certificate"
                                defaultValue={inputFieldsBcerti.name}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handleBcerti(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                  <Grid container className={classes.documents}>
                    {inputFieldsDcerti.map((inputFieldsDcerti, index) => (
                      <div key={index}>
                        <Grid container className={classes.documents}>
                        {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder="Tiger Case Number"
                                defaultValue={inputFieldsDcerti.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handleDcertiImmigo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={12} lg={12} sx={{width: '250px !important'}}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="dcerti"
                                placeholder="Description Degree Certificate"
                                defaultValue={inputFieldsDcerti.name}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handleDcerti(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                  <Grid container className={classes.documented}>
                    {inputFieldsOther.map((inputFieldsOther, index) => (
                      <div key={index}>
                        <Grid
                          container
                          className={classes.documents}
                          spacing={3}
                        >
                            {immigoSet === 'multiple' ?
                          (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            className={classes.documentsInput}
                          >
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="tigerCase"
                                placeholder="Tiger Case Number"
                                defaultValue={inputFieldsOther.immigoCaseNumber}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxLength:8,
                                  "aria-label": "weight"
                             
                                 
                                }}
                                labelWidth={0}
                                onChange={(event) =>
                                  handleOtherImmigo(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>):(<></>)}
                          <Grid item md={12} lg={12}>
                            <FormControl
                              fullWidth
                              className={clsx(
                                classes.margin,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <OutlinedInput
                                name="other"
                                placeholder="Description"
                                defaultValue={inputFieldsOther.name}
                                className={classes.searchInput}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                onChange={(event) => handleOther(event, index)}
                                labelWidth={0}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        &nbsp;&nbsp;
                      </div>
                    ))}
                  </Grid>
                </div>
               
                  <Grid sx={{display:"flex",paddingBottom:"20px"}}>
                  <ToggleButton
                        value="check1"
                        selected={photoChecked}
                        className={clsx(
                          "toggledtsPrefer3",
                          "toggle-button-left"
                        )}
                        onChange={() => {
                          addPhoto()
                        }}
                       
                      >
                        <CheckIcon />
                      </ToggleButton>&nbsp;&nbsp;
                      <div className={classes.prefer} style={{paddingTop:"20px"}}>
                        <span>Do you want to add Photo Service to this request?
                          </span> </div>
                  </Grid>
                  {photoChecked ? (<Grid sx={{display:"flex",paddingBottom:"20px"}}>
                    <Grid item xs={12} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          className={clsx(classes.margin, classes.textField)}
                        >
                          <Select
                            displayEmpty
                            className={classes.searchInput}
                            name="photoValue"
                            id="outlined-adornment-weight"
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <span className={classes.photoQty}>
                                    Photo Specification
                                  </span>
                                );
                              }
                              return photoValue;
                            }}
                            value={photoValue}
                            onChange={handleChangePhoto}
                          >
                            <MenuItem disabled value="">
                              Photo Specification
                            </MenuItem>
                            {names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <div>
                                  <span>{name.value}</span>&nbsp;&nbsp;
                                  <span>({name.size})</span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errorName ? (
                          <>
                            <div className={classes.errors}>Select Name</div>
                          </>
                        ) : null}
                      </Grid>

                  </Grid>):(<div></div>)}
                 
                    
                 
               
                <Grid item md={12} lg={12}>
                  <span className={classes.prefer}>Prefer Delivery with </span>
                  {/*<span className={classes.apply}>Charges apply</span>*/}
                  <br></br>
                  <br />
                  <div className="jk">
                    <div>
                      <ToggleButton
                        value="check1"
                        selected={selected1}
                        className={clsx(
                          "toggledtsPrefer2",
                          "toggle-button-left"
                        )}
                        onChange={() => {
                          setSelected1(!selected1);
                        }}
                        onClick={(event) => updatePrice(event)}
                      >
                        <CheckIcon />
                      </ToggleButton>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span
                        style={{
                          borderRight: "0.5px dashed #C0C3D7",
                          paddingRight: "35px",
                        }}
                        className={classes.prefer}
                      >
                        Select tamper proof black bag
                        <span className={classes.preferDesc}>
                          (Black 2 AED)
                        </span>
                        <span className={classes.preferDesc}>
                          (Height 230mm x Width 180mm)
                        </span>
                        <br />

                        {selected1 ? (
                        <span style={{ display: "flex" }}>
                          <span style={{ marginTop: "10px" }}>
                            Quantity &nbsp;
                          </span>{" "}
                          <div className="passd">
                            <IconButton
                              onClick={() => removeBlack()}
                              className="valDocLeft"
                            >
                              <RemoveIcon></RemoveIcon>
                            </IconButton>
                            <Button className="newed">{quanBlack}</Button>
                            <IconButton
                              onClick={() => addBlack()}
                              className="valDoc"
                            >
                              <AddIcon></AddIcon>
                            </IconButton>
                          </div>
                        </span>
                        ):(<span style={{ display: "flex" }}>
                        <span style={{ marginTop: "10px" }}>
                          Quantity &nbsp;
                        </span>{" "}
                        <div className="passd">
                          <IconButton disabled
                            onClick={() => removeBlack()}
                            className="valDocLeft"
                          >
                            <RemoveIcon></RemoveIcon>
                          </IconButton>
                          <Button className="newed">{quanBlack}</Button>
                          <IconButton disabled
                            onClick={() => addBlack()}
                            className="valDoc"
                          >
                            <AddIcon></AddIcon>
                          </IconButton>
                        </div>
                      </span>)}
                        <div
                          className={classes.prefer10}
                          onClick={() => setPreferBag()}
                        >
                          <span>
                            {" "}
                            View our tamper proof bags &nbsp;
                            <img src="./Images/arrowNew.svg"></img>
                          </span>
                        </div>
                      </span>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <ToggleButton
                        value="check2"
                        className={clsx(
                          "toggledtsPrefer2",
                          "toggle-button-left"
                        )}
                        selected={selected2}
                        onChange={() => {
                          setSelected2(!selected2);
                        }}
                        onClick={(event) => updatePrice1(event)}
                      >
                        <CheckIcon />
                      </ToggleButton>
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span className={classes.prefer} style={{
                          borderRight: "0.5px dashed #C0C3D7",
                          paddingRight: "35px",
                        }}>
                        Select tamper proof white bag
                        <span className={classes.preferDesc}>
                          (White 3 AED)
                        </span>
                        <span className={classes.preferDesc}>
                          (Height 300mm x Width 220mm)
                        </span>
                        <br />
                        {selected2 ? (
                        <span style={{ display: "flex" }}>
                          <span style={{ marginTop: "10px" }}>
                            Quantity &nbsp;
                          </span>{" "}
                          <div className="passd">
                            <IconButton
                              onClick={() => removeWhite()}
                              className="valDocLeft"
                            >
                              <RemoveIcon></RemoveIcon>
                            </IconButton>
                            <Button className="newed">{quanWhite}</Button>
                            <IconButton
                              onClick={() => addWhite()}
                              className="valDoc"
                            >
                              <AddIcon></AddIcon>
                            </IconButton>
                          </div>
                        </span>):( <span style={{ display: "flex",cursor:"not-allowed" }}>
                          <span style={{ marginTop: "10px" }}>
                            Quantity &nbsp;
                          </span>{" "}
                          <div className="passd" style={{cursor:"not-allowed"}}>
                            <IconButton disabled
                              onClick={() => removeWhite()}
                              className="valDocLeft"
                            >
                              <RemoveIcon></RemoveIcon>
                            </IconButton>
                            <Button className="newed">{quanWhite}</Button>
                            <IconButton disabled
                              onClick={() => addWhite()}
                              className="valDoc"
                            >
                              <AddIcon></AddIcon>
                            </IconButton>
                          </div>
                        </span>

                        )}
                        <div
                          className={classes.prefer10}
                          onClick={() => setPreferBagWhite()}
                        >
                          <span>
                            {" "}
                            View our tamper proof bags &nbsp;
                            <img src="./Images/arrowNew.svg"></img>
                          </span>
                        </div>
                      </span>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <ToggleButton
                        value="check2"
                        className={clsx(
                          "toggledtsPrefer2",
                          "toggle-button-left"
                        )}
                        selected={selected3}
                        onChange={() => {
                          setSelected3(!selected3);
                        }}
                        onClick={(event) => updatePrice2(event)}
                      >
                        <CheckIcon />
                      </ToggleButton>
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span className={classes.prefer}>
                        Select A3 bag
                        <span className={classes.preferDesc}>
                          (5AED)
                        </span>
                        <span className={classes.preferDesc}>
                          (Height 420mm x Width 297mm)
                        </span>
                        <br />
                        {selected3 ? (
                        <span style={{ display: "flex" }}>
                          <span style={{ marginTop: "10px" }}>
                            Quantity &nbsp;
                          </span>{" "}
                          <div className="passd">
                            <IconButton
                              onClick={() => removeA3()}
                              className="valDocLeft"
                            >
                              <RemoveIcon></RemoveIcon>
                            </IconButton>
                            <Button className="newed">{quanA3}</Button>
                            <IconButton
                              onClick={() => addA3()}
                              className="valDoc"
                            >
                              <AddIcon></AddIcon>
                            </IconButton>
                          </div>
                        </span>) :(
                           <span style={{ display: "flex" }}>
                           <span style={{ marginTop: "10px" }}>
                             Quantity &nbsp;
                           </span>{" "}
                           <div className="passd">
                             <IconButton
                               onClick={() => removeA3()}
                               className="valDocLeft"
                             >
                               <RemoveIcon></RemoveIcon>
                             </IconButton>
                             <Button className="newed">{quanA3}</Button>
                             <IconButton
                               onClick={() => addA3()}
                               className="valDoc"
                             >
                               <AddIcon></AddIcon>
                             </IconButton>
                           </div>
                         </span>
                        )}
                          <div
                          className={classes.prefer10}
                          onClick={() => setPreferA3()}
                        >
                          <span>
                            {" "}
                            View our tamper proof bags &nbsp;
                            <img src="./Images/arrowNew.svg"></img>
                          </span>
                        </div>
                      
                      </span>
                    </div>
                    {/*<ToggleButton
                    value="check3"
                    className={clsx("toggledts", "toggle-button-left")}
                    selected={selected3}
                    onChange={() => {
                      setSelected3(!selected3);
                    }}
                    onClick={updatePrice2}
                  >
                    <CheckIcon />
                  </ToggleButton>*/}
                    &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                   {/*} <div
                      className={classes.prefer1}
                      onClick={() => setPrefer(true)}
                    >
                      <span>
                        {" "}
                        View our tamper proof bags &nbsp;
                        <img src="./Images/arrowNew.svg"></img>
                      </span>
                      <span className={classes.preferDesc}>
                        (Height 230mm x Width 180mm)
                      </span>
                    </div>*/}
                  </div>
                </Grid>

                {/*<div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item md={4} lg={4} >
                      <div className={classes.send_label}>
                        Approx Parcel Value
                      </div>
                      <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          placeholder='Enter parcel value'
                          onChange={(e) => priceChange(e)}

                          endAdornment={<InputAdornment position="end"> <p><b>AED</b></p></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          labelWidth={0}
                          className={classes.searchInput}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container item md={8} lg={8} display="block">
                      <div className={classes.send_label}>
                        Secure Your Parcel
                      </div>
                      <Grid xs={6}>
                        <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            placeholder='Parcel value'
                            onChange={(e) => priceChange(e)}

                            endAdornment={<InputAdornment position="end"> <p><b>AED</b></p></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            labelWidth={0}
                            className={classes.searchInput}
                          />
                        </FormControl>
                      </Grid>
                      <p className={classes.secureParcelInfo}>0 AED : Additional charges for securing parcel</p>
                      <p className={classes.secureParcelWarning}>Secure your important packages to retrieve the value in case of loss or damage done during delivery. Valid upto AED 5000.</p>
                    </Grid>
                  </Grid>
                </div>*/}

                {/*<div className={classes.content1}>
                  <div className={classes.send_label}>
                    Payment Type
                  </div>
                  <div className={classes.content}>
                    <Grid container spacing={2}>
                      <Grid xs={2} item>
                        <Button className={classes.weightSelectorButton}>
                          Cash
                        </Button>
                      </Grid>
                      <Grid xs={3} item>
                        <Button className={classes.weightSelectorButton}>
                          Credit / Debit Card
                        </Button>
                      </Grid>
                      <Grid xs={2.5} item>
                        <Button className={classes.weightSelectorButton}>
                          e-Dirham Card
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                          </div>*/}

                {/*Payment not in cooperate login */}
                {/*} <div className={classes.content}>
            <Grid container spacing={3}>
             <Grid item md={12} lg={12} >
               <div>
                 Payment Type
               </div>
               <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  className={classes.weightGroup}
                  fullWidth
                >
                  <ToggleButton className={classes.weightButton} value="web">Cash</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="android">Credit/Debit Card</ToggleButton>
                  <ToggleButton className={classes.weightButton} value="ios1">e-Dirham Card<br/>(0 Balance)Top up Now</ToggleButton>
                  


                </ToggleButtonGroup>

               </Grid>
               </Grid>
                </div>*/}

                {/*} <div className={classes.content1}>
                  <div className={classes.send_label}>
                    Other
                  </div>
                  <Grid container spacing={3}>
                  
                     <Grid item md={4} lg={4} >

                        <ToggleButton

                          value="check"
                          selected={selected}
                          onChange={() => {
                            setSelected(!selected);
                          }}
                        >
                          <CheckIcon />
                        </ToggleButton>&nbsp;&nbsp;Notify recipient by Whatsapp Messages
                      </Grid>
                    <Grid item md={4} lg={4} >
                      <ToggleButton
                        value="check"
                        selected={selected4}
                        onChange={() => {
                          setSelected4(!selected4);
                        }}
                      >
                        <CheckIcon />
                      </ToggleButton> &nbsp;&nbsp;Notify recipient by SMS
                    </Grid>
                  </Grid>
                </div>*/}

                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item md={3} lg={3}></Grid>
                    {price !== "" ? (
                      <Grid item md={6} lg={6}>
                        <div className={classes.total}>
                          <b className={classes.price_cont}>
                            Total Estimated Price
                          </b>&nbsp;&nbsp;
                          <img
                            className={classes.price_img}
                            src="./Images/info.svg"
                          ></img>
                          <b className={classes.price_tag}>
                            &nbsp;&nbsp;{price}
                          </b>
                          &nbsp;AED
                        </div>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item md={3} lg={3}></Grid>
                  </Grid>
                </div>
                <div className={classes.divider}></div>
                {/*} <div className={classes.estimationContainer}>
                  <p className={classes.totalPrice}>Total Estimated Price : <span className={classes.totalPriceCount}>65.00</span> <span className={classes.totalPriceUnit}>AED</span></p>
                </div>*/}

                <div className={classes.content}>
                  <Grid container>
                
                    <Grid item md={2} lg={2}></Grid>                  
                    <Grid item xs={10} md={4} lg={4}>
                    <button className={classes.service_btn_draft} onClick={()=>handleSubmitDraftSubmit()}>
                       Save as draft
                      </button>
                     
                    </Grid>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Grid item xs={10} md={4} lg={4}>
                    
                      <button className={classes.service_btn} type="submit" disabled={placed}>
                        {loaded}
                      </button>
                    </Grid>
                    <Grid item md={2} lg={2}></Grid>
                   
                  </Grid>
                </div>
              </form>
              <div className={classes.content5}>
                <Grid container spacing={3} className={classes.contnew}>
                  <span>
                    By Clicking ‘Submit order’ you are forwarding your request
                    to couriers and agree to Our Terms and Conditions along with
                    the{" "}
                    <u>
                      <b style={{ cursor: "pointer" }} onClick={clause}>
                        clauses of the agreements
                      </b>
                    </u>
                  </span>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={1} index={1}>
           
              <ScheduleLater></ScheduleLater>
            </TabPanel>

            {/*schedule later part begins */}
          </TabsUnstyled>
          <Grid item md={12} lg={12}>
            <div className="footed">
              <Footer></Footer>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/*error popup */}
      <Dialog
        open={openError}
        onClose={() => handleCloseError()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Jiffy Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sorry, pickup time chosen is not within agreed SLA, please choose a
            pick up time 2 hours prior to working hours (8:00 AM to 7:00 PM -
            pick up time cannot be post 5:00 PM
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseError()} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDocError}
        onClose={() => handleCloseErrorDoc()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Jiffy Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select documents to be sent
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseErrorDoc()} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLoc}
        onClose={() => handleCloseLoc()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Jiffy Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select location from dropdown
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseLoc()} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={prefer}
        onClose={() => handleClosePrefer()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogContent>
          <Grid xs={12} md={12} lg={12} className="jk">
            <ToggleButtonGroup
              fullWidth
              color="info"
              id="pickupAA"
              value={preferIcon}
              exclusive
              onChange={(e, value) => setPreferIcon(value)}
              className={classes.timeGroup1}
              // onClick={(e) => e.stopPropagation()}
            >
              <ToggleButton
                className={clsx("toggledtsPrefer", "toggle-button-left")}
                value="black"
              >
                Black
              </ToggleButton>
              <ToggleButton
                className={clsx("toggledtsPrefer", "toggle-button-right")}
                value="white"
              >
                White
              </ToggleButton>
            </ToggleButtonGroup>
            <span className="prefered" onClick={() => handleClosePrefer()}>
              <img src="./Images/crosss.svg"></img>
            </span>
          </Grid>
          <Grid xs={2} md={2} lg={2}></Grid>

          {preferIcon === "black" ? (
            <DialogContentText
              id="alert-dialog-description"
              className={classes.preferGroup}
            >
              <div>
                <span className="tamper">
                  Tamper proof black bag dimension{" "}
                </span>
                <span className="dark">(2 AED)</span>
              </div>
              <br />
              <Box sx={{ marginLeft: "40px" }}>
                <img src="./Images/black_bag.png"></img>)
              </Box>
            </DialogContentText>
          ) : (
            <>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.preferGroup}
              >
                <div>
                  <span className="tamper">
                    Tamper proof white bag dimension{" "}
                  </span>
                  <span className="dark">(3 AED)</span>
                </div>
                <br />
                <Box sx={{ marginLeft: "40px" }}>
                  <img src="./Images/white_bag.png"></img>
                </Box>
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
open={friday}
onClose={() => handleFriday()}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
sx={{
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "500px",
      
    },
  },
}}
>
<DialogTitle id="alert-dialog-title">{"Jiffy Alert"}</DialogTitle>
<DialogContent>
  <DialogContentText id="alert-dialog-description">
{frMsg}
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={() => handleFriday()} autoFocus>
    OK
  </Button>
</DialogActions>
</Dialog>
      <Dialog
        open={openBag}
        onClose={() => handleBag()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
     
      >
        <DialogTitle id="alert-dialog-title">{"Jiffy Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select Bag
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleBag()} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openA3}
        onClose={() => handleClosePrefer()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogContent>
          <Grid xs={12} md={12} lg={12} className="jk">
            <ToggleButtonGroup
              fullWidth
              color="info"
              id="pickupAA"
            
              exclusive
            
              className={classes.timeGroup1}
              // onClick={(e) => e.stopPropagation()}
            >
              <ToggleButton
                className={clsx("toggledtsPrefer", "toggle-button-right")}
                value="white" selected
              >
                Black
              </ToggleButton>
            
            </ToggleButtonGroup>
            <span className="prefered" onClick={() => handleCloseA3()}>
              <img src="./Images/crosss.svg"></img>
            </span>
          </Grid>
          <Grid xs={2} md={2} lg={2}></Grid>

          
            <DialogContentText
              id="alert-dialog-description"
              className={classes.preferGroup}
            >
              <div>
                <span className="tamper">
                Tamper proof black bag dimension 
                </span>
                <span className="dark">(5 AED)</span>
              </div>
              <br />
              <Box sx={{ marginLeft: "5px" }}>
                <img height={"500px"} width={"350px"} src="./Images/a3.png"></img>
              </Box>
            </DialogContentText>
         
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        open={openErrorDraft}
        onClose={() => handleCloseErrorDraft()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Jiffy Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to continue Editing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => handleCloseErrorDraft()} autoFocus className='popup'>
            Cancel
          </Button>
          <Button onClick={() => gotoPageDraft()} autoFocus className='popup'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/*Save Address */}
       <Dialog
      open={savePopup}
      onClose={handleCloseSave}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{"Add your bookmark name"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please enter a name for your bookmark. This field is required.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="bookmark-name"
          label="Bookmark Name"
          type="text"
          fullWidth
          variant="outlined"
          value={bookmarkName}
          onChange={handleBookmarkChange}
          error={error}
          helperText={error ? "Bookmark name is required" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSave} className="popup">
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={bookmarkName.trim() === ''} className="popup">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={savePopupD}
      onClose={handleCloseSaveD}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{"Add your bookmark name"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please enter a name for your bookmark. This field is required.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="bookmark-name"
          label="Bookmark Name"
          type="text"
          fullWidth
          variant="outlined"
          value={bookmarkNameD}
          onChange={handleBookmarkChangeD}
          error={error}
          helperText={error ? "Bookmark name is required" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSaveD} className="popup">
          Cancel
        </Button>
        <Button onClick={handleSaveD} disabled={bookmarkNameD.trim() === ''} className="popup">
          OK
        </Button>
      </DialogActions>
    </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openClause}
        onClose={handleCloseClause}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className='wdialog'
      >
        <Fade in={openClause}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              component="h2"
              className="closed"
            >
              <b>Terms and Conditions</b>
              <div id="mdiv" onClick={handleCloseClause}>
                <div class="mdiv">
                  <div class="md"></div>
                </div>
              </div>
            </Typography>

            <Typography id="transition-modal-title" variant="h5" component="h2">
              <b>User Terms</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  These terms of use (“Terms of Use”) mandate the terms on which
                  users (“You” or “Your” or “Yourself” or “Users”) interested in
                  browsing or availing Jiffy Services (defined below), and
                  accessing the platform www.Jiffy.ae and the mobile application
                  owned and operated by Jiffy Delivery Services (Jiffy), a Sole
                  Establishment incorporated under the law of Dubai, having its
                  registered office at Office suite # 207, Misa Business Centre, Blue Wave Tower, Wadi Al Safa 5, 
                  Dubai - UAE,
                  collectively referred to as the “Platform” connects with the
                  delivery partners registered on the Platform (“Delivery
                  Partners”) to avail the Jiffy Services.
                </li>
                <li className="listed">
                  Please read the Terms of Use carefully before using the
                  Platform or registering on the Platform or accessing any
                  material or information through the Platform. By clicking on
                  the “I Accept” button, You accept this Terms of Use and agree
                  to be legally bound by the same.
                </li>
                <li className="listed">
                  Use of and access to the Platform is offered to You upon the
                  condition of acceptance of all the terms, conditions and
                  notices contained in this Terms of Use and Privacy Policy,
                  along with any amendments made by Jiffy at its sole discretion
                  and posted on the Platform from time to time.
                </li>
                <li className="listed">
                  For the purposes of these Terms of Use, the term ‘Jiffy’ or
                  ‘Us’ or ‘We’ refers to Jiffy. The term ‘You’ refers to the
                  user or visitor of the Website and/or App. When You use our
                  services, You will be subject to the terms, guidelines and
                  policies applicable to such service and as set forth in these
                  Terms of Use. As long as you comply with these Terms of Use,
                  We grant You a personal, non-exclusive, non-transferable,
                  limited privilege to enter and use our Platforms and services.
                </li>
              </ol>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>PART A – GENERAL TERMS RELATING TO JIFFY SERVICES</b>
            </Typography>
            <Typography id="transition-modal-title" variant="h5" component="h2">
              <b>1. Registration:</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  a. You shall be permitted to access the Platform, avail the
                  Jiffy Services and connect with Delivery Partners on the
                  Platform only upon creating an Account (as defined below) and
                  obtaining a registration on the Platform. Your ability to
                  continue using the Platform, Jiffy Services is subject to Your
                  continued registration on the Platform. You will be required
                  to enter Your personal information including your name,
                  contact details, valid phone number while registering on the
                  Platform.
                </li>
                <li className="listed">
                  b. As a part of the registration, You may be required to
                  undertake a verification process to verify Your personal
                  information and setting up the Account. Jiffy shall have the
                  right to display the information, feedback, ratings, reviews
                  etc. provided by You on the Platform. You agree and accept
                  that as on the date of Your registration on the Platform, the
                  information provided by You is complete, accurate and
                  up-to-date. In the event of any change to such information,
                  You shall be required to promptly inform Jiffy of the same, in
                  writing, at least 1 (one) week prior to the date on which such
                  change shall take effect. You acknowledge and accept that
                  Jiffy has not independently verified the information provided
                  by You. Jiffy shall in no way be responsible or liable for the
                  accuracy or completeness of any information provided by You.
                  If You provide any information that is untrue, inaccurate, not
                  current or incomplete, or Jiffy has reasonable grounds to
                  suspect that such information is untrue, inaccurate, not
                  current or incomplete, Jiffy reserves the right to suspend or
                  terminate Your Account (defined below) and refuse any and all
                  current or future use of the Platform (or any portion thereof)
                  at any time.
                </li>
              </ol>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>2. Jiffy Services:</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  a. The Platform provides You with the following services
                  (“Jiffy Services”):
                </li>

                <li className="listed">
                  We identify and use your IP address to help diagnose problems
                  with our server and to administer our Website. Your IP address
                  is also used to help identify you and to gather broad
                  demographic information.
                </li>
                <li className="listed">
                  ii. It allows you to pick up- and drop off packages from one
                  location to the other through the Delivery Partner (“Pick Up
                  and Drop Off Services”);
                </li>
                <li className="listed">
                  iii. It allows You to give ratings, write comments and reviews
                  about Delivery Partners;
                </li>
                <li className="listed">
                  iv. It facilitates improvement in the quality of the services
                  provided by Jiffy on the Platform based on User ratings,
                  reviews and feedback provided on the Platform.
                </li>
                <li className="listed">
                  b. Once the Delivery Services/Pick-Up and Drop Off Services
                  have been completed or delivered, as the case may be, You
                  shall promptly notify the same on the Platform.
                </li>
                <li className="listed">
                  c. Jiffy may, at its absolute sole discretion, add, modify,
                  upgrade, extend, withdraw or alienate any of the Jiffy
                  Services listed above from time to time. Jiffy does not
                  provide any guarantee to You that the Jiffy Services will be
                  made available to You at all times.
                </li>
                <li className="listed">
                  d. You hereby agree and acknowledge that Jiffy is only a
                  facilitator between You and Delivery Partners and Jiffy only
                  provides You with access to the Platform to connect with
                  Delivery Partners for You to initiate transactions on the
                  Platform. You hereby agree and acknowledge that Jiffy will not
                  be a party to any of the transactions that are initiated by
                  You through the Platform and Jiffy shall not be liable in any
                  manner or incur any liability with respect to the services
                  performed by the Delivery Partners. Further, You hereby agree
                  and acknowledge that Jiffy shall not be liable for the
                  conduct, acts and omissions of the Delivery Partners in the
                  course of providing their services to You, or for any loss or
                  damage to the Item or otherwise caused to You as a consequence
                  of or in relation to the services being provided to You by the
                  Delivery Partner, as the case may be.
                </li>
                <li className="listed">
                  e. You shall be eligible to avail the Jiffy Services as per
                  applicable laws.
                </li>
                <li className="listed">
                  f. You shall not initiate any transaction for any Item on the
                  Platform which is illegal, immoral, unethical, unlawful,
                  unsafe, contains any harmful substance and/ or is in violation
                  of this Terms of Use and applicable laws. You specifically
                  agree that You shall not initiate any transaction on the
                  Platform for the delivery of any alcoholic beverages, narcotic
                  drug or psychotropic substance, etc. Further, You hereby
                  acknowledge and agree that Jiffy shall not be liable for any
                  indirect, direct damage or loss, cost, expense incurred by You
                  in relation to the transactions initiated by You on the
                  Platform.
                </li>
                <li className="listed">
                  g. Jiffy does not check or verify the packages that are being
                  picked up and dropped off on behalf of You or the Items that
                  are being delivered to You by the Delivery Partner, and
                  therefore Jiffy shall have no liability with respect to the
                  same. However, if it comes to the knowledge of Jiffy that You
                  have packaged any illegal or dangerous substance or availed
                  the Pick- up and Drop Off Services using the Platform to
                  deliver any illegal or dangerous substance, Jiffy shall have
                  the right to report You to the government authorities and take
                  other appropriate legal actions against You.
                </li>
                <li className="listed">
                  h. You hereby acknowledge that Jiffy shall not be liable for
                  any damages of any kind arising from Your use of the Jiffy
                  Services, including, but not limited to direct, indirect,
                  incidental, punitive, and consequential damages.
                </li>
                <li className="listed">
                  i. Jiffy shall be entitled at any time, without giving any
                  reason, to terminate Your request for any Jiffy Service.
                </li>
                <li className="listed">
                  j. You hereby agree that Jiffy shall not be liable for any
                  conduct or misbehaviour or actions of Delivery Partner with
                  respect to any transactions initiated on the Platform.
                  Further, You agree that Jiffy has no control over the Items
                  dispatched/ received by You by the Delivery Partners and
                  therefore, Jiffy shall not incur any liability with respect to
                  such Items. However, keeping in mind the interests of the
                  Users, We have informed our Delivery Partners to ensure that
                  Items are handled properly and with due care to avoid any form
                  of damage to the Item.
                </li>
                <li className="listed">
                  k. You hereby agree that scheduling and rescheduling a
                  transaction on the Platform depends upon the availability of
                  Delivery Partners around Your area at the time of such
                  scheduling and re-scheduling a transaction. Should You choose
                  to reschedule a transaction on the Platform at a later point
                  of time, You shall cancel the current transaction on the
                  Platform (if initiated) and initiate a new transaction on the
                  Platform, as per Your convenient time.
                </li>
                <li className="listed">
                  l. If a transaction initiated by You on the Platform cannot be
                  completed, Jiffy shall notify You on the Platform.
                </li>
                <li className="listed">
                  m. You agree to provide as much information as possible on the
                  Platform with respect to the Items/services You wish to avail,
                  using the Platform.
                </li>
                <li className="listed">
                  n. Jiffy shall use Your location based information that is
                  captured by Jiffy through the global positioning system (GPS)
                  when You are using Your mobile device to request a Jiffy
                  Service on its m-app. Such location based information shall be
                  used by Jiffy only to facilitate and improve the Jiffy
                  Services being offered to You.
                </li>
                <li className="listed">
                  o. We can’t fulfil any tasks which are immoral or unlawful in
                  nature. Jiffy reserves the right to refuse to perform any
                  tasks on the grounds of such tasks being
                  immoral/unethical/unlawful/banned either by Jiffy’s internal
                  policies or as per the independent discretion of Jiffy. Jiffy
                  may also refuse to perform any task on the grounds that such
                  task is prohibited under any contract to which we are party.
                </li>
                <li className="listed">
                  p. You understand and acknowledge that Jiffy by itself does
                  not provide any delivery services through its own employees,
                  and that Jiffy is not responsible for the quality of such
                  delivery services. Accordingly, in the event of any grievances
                  arising from the transaction initiated by You on the Platform
                  pertaining to delivery services, You may contact Jiffy support
                  for routing Your grievances to the Delivery Partner through
                  the Platform.
                </li>
                <li className="listed">
                  q. You hereby acknowledge that if You have any complaint with
                  respect to the Jiffy Services, You will first inform Jiffy in
                  writing within 24 (twenty four) hours of using such Jiffy
                  Services.
                </li>
                <li className="listed">3. User Information</li>
                <li className="listed">
                  a. You are solely responsible for and in control of the
                  information You provide to us. Compilation of User Accounts
                  and User Account bearing contact number and e-mail addresses
                  are owned by Jiffy. Further, You understand and agree that
                  certain information will be case sensitive and must be handled
                  with prudent care.
                </li>
                <li className="listed">
                  b. In the case where the Platform is unable to establish
                  unique identity of the User against a valid mobile number or
                  e-mail address, the Account shall be indefinitely suspended.
                  Jiffy reserves the full discretion to suspend a User’s Account
                  in the above event and does not have the liability to share
                  any Account information whatsoever.
                </li>
                <li className="listed">4. Payment Terms</li>
                <li className="listed">
                  While initiating a request for a Pick Up and Drop Off Service,
                  You shall pay the service fees for availing the Pick Up and
                  Drop Off Service, as may be displayed to You on the Platform.
                  Only upon making such payment will the Delivery Partner
                  initiate the Pick Up and Drop of Service.
                </li>
                <li className="listed">
                  Service Fees: With respect to Delivery Services, You will be
                  charged a separate service fees (“Service Fees”). The Service
                  Fees shall be paid prior to availing any of the Jiffy
                  Services.
                </li>
                <li className="listed">
                  You agree that Jiffy may use certain third-party vendors and
                  service providers, including payment gateways, to process the
                  payments made by You on the Platform.
                </li>
                <li className="listed">5. Rating</li>
                <li className="listed">
                  a. You agree that: (i) after completion of a transaction on
                  the Platform, the Platform will prompt the User with an option
                  to provide a rating and comments about the Delivery Partner
                  (with respect to the services performed by the Delivery
                  Partner) and (ii) the Delivery Partner may also be prompted to
                  rate You on the Platform. Based upon such Delivery Partner
                  ratings, Your rating score on the Platform will be determined
                  and displayed.
                </li>
                <li className="listed">
                  b. Jiffy and its affiliates reserve the right to use, share
                  and display such ratings and comments in any manner in
                  connection with the business of Jiffy and its affiliates
                  without attribution to or approval of Users and You hereby
                  consent to the same. Jiffy and its affiliates reserve the
                  right to edit or remove comments in the event that such
                  comments include obscenities or other objectionable content,
                  include an individual’s name or other personal information, or
                  violate any privacy laws, other applicable laws or Jiffy’s or
                  its affiliates’ content policies.
                </li>
                <li className="listed">
                  c. Location: You acknowledge and agree that Your geo-location
                  information is required for You to avail the Jiffy Services
                  and initiate transactions on the Platform. You acknowledge and
                  hereby consent to the monitoring and tracking of Your
                  geo-location information. In addition, Jiffy may share Your
                  geo-location information with Delivery Partners.
                </li>
              </ol>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>PART B – SPECIFIC TERMS FOR JIFFY SERVICES</b>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>6. Cancellation and Refund</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  a. Jiffy shall confirm and initiate the execution of the
                  transaction initiated by You upon receiving confirmation from
                  You for the same. If You wish to cancel a transaction on the
                  Platform, You shall select the cancel option on the Platform.
                  It is to be noted that You may have to pay a cancellation fee
                  for transactions initiated on the Platform for which work has
                  already been commenced by the Delivery Partner.
                </li>
                <li className="listed">
                  b. Jiffy may cancel the transaction initiated by You on the
                  Platform, if: The designated address to avail the Jiffy
                  Services provided by You is outside the service zone of Jiffy.
                </li>
              </ol>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>Safety guidelines</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  Failure to get your response via phone or any other
                  communication channel at the time of confirmation of the order
                  booking. The transaction involves supply/delivery of any
                  material good that is illegal, offensive or violative of the
                  Terms of Use. Information, instructions and authorisations
                  provided by You is not complete or sufficient to execute the
                  transaction initiated by You on the Platform. If a Delivery
                  Partner is not available to perform the services, as may be
                  requested. If the transaction cannot be completed for reasons
                  not in control of Jiffy. c. You shall only be able to claim
                  refunds for transactions initiated by You only if You have
                  already pre-paid the fees with respect to such transaction.
                  Subject to our refund policy and in accordance therein, You
                  shall be eligible to get the refund in the following
                  circumstances: Your package has been tampered or damaged at
                  the time of delivery, as determined by Jiffy basis the
                  parameters established by Jiffy in its sole discretion. If the
                  wrong Item has been delivered to You, which does not match
                  with the Item for which You had initiated a transaction on the
                  Platform. Jiffy has cancelled the order because of any reason
                  mentioned under Para 6 (b) above. All decisions with respect
                  to refunds will be at the sole discretion of Jiffy and in
                  accordance with Jiffy’s internal refund policy and the same
                  shall be final and binding. All refunds initiated by Jiffy
                  shall be refunded to the financial source account from which
                  You have initiated the transaction on the Platform.
                </li>
                <li className="listed">7. Pick Up and Drop Off Services</li>
                <li className="listed">
                  i. As a part of the Jiffy Services, Jiffy also gives You an
                  option to avail the Pick Up and Drop Off Services being
                  provided by the Delivery Partners.
                </li>
                <li className="listed">
                  ii. You can initiate a transaction on the Platform by which
                  You may (through the help of a Delivery Partner) send packages
                  at a particular location. The Pick Up and Drop Off Services
                  are provided to You directly by the Delivery Partner and Jiffy
                  merely acts as a technology platform to facilitate
                  transactions initiated on the Platform and Jiffy does not
                  assume any responsibility or liability for any form of
                  deficiency of services on part of the Delivery Partner.
                </li>
                <li className="listed">
                  iii. Upon initiation of a request for Pick Up and Drop Off
                  Services on the Platform, depending upon the availability of
                  Delivery Partner around Your area, Jiffy will assign a
                  Delivery Partner to You. The Delivery Partner shall pick up
                  the Item from a location designated by You on the Platform and
                  drop off the Items at a particular location designated by You.
                  While performing the Pick Up and Drop off Services, the
                  Delivery Partner shall act as an agent of You and shall act in
                  accordance with Your instructions. You agree and acknowledge
                  that the pick-up location and the drop off location has been
                  added by You voluntarily and such information will be used for
                  the Jiffy Services and shall be handled by Jiffy in accordance
                  with the terms of its Privacy Policy. iv. You agree that You
                  shall not request for a Pick Up and Drop Off Services for
                  Items which are illegal, hazardous, dangerous, or otherwise
                  restricted or constitute Items that are prohibited by any
                  statute or law or regulation or the provisions of this Terms
                  of Use. v. You agree that before requesting a Pick-up and
                  Drop-off Service, You are well aware of the contents of the
                  package sent or requested by You through registered Delivery
                  Partner, and that such contents are legal and within limits of
                  transportation under any applicable laws. Such contents shall
                  not be restricted and/or banned and/or dangerous and/or
                  prohibited for carriage (such items include, but are not
                  limited to, radio-active, incendiary, corrosive or flammable
                  substances, hazardous chemicals, explosives, firearms or parts
                  thereof and ammunition, firecrackers, cyanides, precipitates,
                  gold and silver ore, bullion, precious metals and stones,
                  jewellery, semi-precious stones including commercial carbons
                  or industrial diamonds, currency (paper or coin) of any
                  nationality, securities (including stocks and bonds, share
                  certificates and blank signed share transfer forms), coupons,
                  stamps, negotiable instruments in bearer form, cashier’s
                  cheques, travellers’ cheques, money orders, passports,
                  credit/debit/ATM cards, antiques, works of art, lottery
                  tickets and gambling devices, livestock, fish, insects,
                  animals, plants and plant material, human corpses, organs or
                  body parts, blood, urine and other liquid diagnostic
                  specimens, hazardous or bio-medical waste, wet ice,
                  pornographic materials, contraband, bottled alcoholic
                  beverages or any intoxicant or narcotics and psychotropic
                  substances or any other prohibited material or material for
                  the transportation of which specific authorisation/license is
                  required under applicable laws).
                </li>
                <li className="listed">
                  vi. You also agree that, upon becoming aware of the commission
                  any offence by You or Your intention to commit any offence
                  upon initiating a Pick-up and Drop-off Service or during a
                  Pick-up and Drop-off service of any Item(s) restricted under
                  applicable law, the Delivery Partner may report such
                  information to Jiffy or to the law enforcement authorities.
                </li>
                <li className="listed">
                  If any User fails to observe the above rules of conduct at any
                  time, Jiffy reserves the right to terminate the User from the
                  Platform, depending on the nature and severity of the breach
                  and without prior warning or consent of the User.
                </li>
              </ol>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>PART C: GENERAL TERMS OF USE</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              8. Eligibility to Use
              <br />
            </Typography>

            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  a. The Jiffy Services are not available to minors i.e. persons
                  under the age of 18 (eighteen) years or to any Users suspended
                  or removed by Jiffy from accessing the Platform for any reason
                  whatsoever. You represent that You are of legal age to form a
                  binding contract and are not a person barred from receiving/
                  using or availing Jiffy Services under the applicable laws. b.
                  Jiffy reserves the right to refuse access to the Platform, at
                  any time to new Users or to terminate or suspend access
                  granted to existing Users at any time without according any
                  reasons for doing so.
                </li>
                <li className="listed">
                  c. You shall not have more than 1 (one) active Account (as
                  defined below) on the Platform. Additionally, You are
                  prohibited from selling, trading, or otherwise transferring
                  Your Account to another party or impersonating any other
                  person for the purposing of creating an account with the
                  Platform.
                </li>
                <li className="listed">
                  9. User Account, Password, and Security
                </li>
                <li className="listed">
                  a. In order to use the Platform and avail the Jiffy Services,
                  You will have to register on the Platform and create an
                  account with a unique user identity and password (“Account”).
                  If You are using the Platform on a compatible mobile or
                  tablet, You will have to install the application and then
                  proceed with registration.
                </li>
                <li className="listed">
                  b. You will be responsible for maintaining the confidentiality
                  of the Account information, and are fully responsible for all
                  activities that occur under Your Account. You agree to (i)
                  immediately notify Jiffy of any unauthorized use of Your
                  Account information or any other breach of security, and (ii)
                  ensure that You exit from Your Account at the end of each
                  session. Jiffy cannot and will not be liable for any loss or
                  damage arising from Your failure to comply with this
                  provision. You may be held liable for losses incurred by Jiffy
                  or any other User of or visitor to the Platform due to
                  authorized or unauthorized use of Your Account as a result of
                  Your failure in keeping Your Account information secure and
                  confidential.Use of another User’s Account information for
                  using the Platform is expressly prohibited.
                </li>
                <li className="listed">10. Representations and Warranties</li>
                <li className="listed">
                  a. Subject to compliance with the Terms of Use, Jiffy grants
                  You a non-exclusive, limited privilege to access and use this
                  Platform and the Jiffy Services.
                </li>
                <li className="listed">
                  b. You agree to use the Platform only: (i) for purposes that
                  are permitted by this Terms of Use; and (ii) in accordance
                  with any applicable law, regulation or generally accepted
                  practices or guidelines. You agree not to engage in activities
                  that may adversely affect the use of the Platform by Jiffy or
                  Delivery Partner(s) or Merchants or other Users.
                </li>
                <li className="listed">
                  c. You represent and warrant that You have not received any
                  notice from any third party or any governmental authority and
                  no litigation is pending against You in any court of law,
                  which prevents You from accessing the Platform and/or availing
                  the Jiffy Services. d. You represent and warrant that You are
                  legally authorised to view and access the Platform and avail
                  the Jiffy Services.
                </li>
                <li className="listed">
                  e. You agree not to access (or attempt to access) the Platform
                  by any means other than through the interface that is provided
                  by Jiffy. You shall not use any deep-link, robot, spider or
                  other automatic device, program, algorithm or methodology, or
                  any similar or equivalent manual process, to access, acquire,
                  copy or monitor any portion of the Platform, or in any way
                  reproduce or circumvent the navigational structure or
                  presentation of the Platform, materials or any Jiffy Property,
                  to obtain or attempt to obtain any materials, documents or
                  information through any means not specifically made available
                  through the Platform. f. You acknowledge and agree that by
                  accessing or using the Platform, You may be exposed to content
                  from others that You may consider offensive, indecent or
                  otherwise objectionable. Jiffy disclaims all liabilities
                  arising in relation to such offensive content on the Platform.
                </li>
              </ol>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <b>g. Further, You undertake not to:</b>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li className="listed">
                  defame, abuse, harass, threaten or otherwise violate the legal
                  rights of others; publish, post, upload, distribute or
                  disseminate any inappropriate, profane, defamatory,
                  infringing, disparaging, ethnically objectionable, obscene,
                  indecent or unlawful topic, name, material or information; do
                  any such thing that may harms minors in any way; copy,
                  republish, post, display, translate, transmit, reproduce or
                  distribute any Jiffy Property through any medium without
                  obtaining the necessary authorization from Jiffy; conduct or
                  forward surveys, contests, pyramid schemes or chain letters;
                  upload or distribute files that contain software or other
                  material protected by applicable intellectual property laws
                  unless You own or control the rights thereto or have received
                  all necessary consents; upload or distribute files or
                  documents or videos (whether live or pre-recorded) that
                  contain viruses, corrupted files, or any other similar
                  software or programs that may damage the operation of the
                  Platform or another’s computer; engage in any activity that
                  interferes with or disrupts access to the Platform (or the
                  servers and networks which are connected to the Platform);
                  attempt to gain unauthorized access to any portion or feature
                  of the Platform, any other systems or networks connected to
                  the Platform, to any Jiffy server, or through the Platform, by
                  hacking, password mining or any other illegitimate means;
                  probe, scan or test the vulnerability of the Platform or any
                  network connected to the Platform, nor breach the security or
                  authentication measures on the Platform or any network
                  connected to the Platform. You may not reverse look-up, trace
                  or seek to trace any information on any other User, of or
                  visitor to, the Platform, to its source, or exploit the
                  Platform or information made available or offered by or
                  through the Platform, in any way whether or not the purpose is
                  to reveal any information, including but not limited to
                  personal identification information, other than Your own
                  information, as provided on the Platform; disrupt or interfere
                  with the security of, or otherwise cause harm to, the
                  Platform, systems resources, accounts, passwords, servers or
                  networks connected to or accessible through the Platform or
                  any affiliated or linked sites; collect or store data about
                  other Users, Merchants, Delivery Partner in connection with
                  the prohibited conduct and activities set forth herein; use
                  any device or software to interfere or attempt to interfere
                  with the proper working of the Platform or any transaction
                  being conducted on the Platform, or with any other person’s
                  use of the Platform; use the Platform or any material or Jiffy
                  Property for any purpose that is unlawful or prohibited by
                  these Terms of Use, or to solicit the performance of any
                  illegal activity or other activity which infringes the rights
                  of the Company or other third parties; falsify or delete any
                  author attributions, legal or other proper notices or
                  proprietary designations or labels of the origin or source of
                  software or other material contained in a file that is
                  uploaded; impersonate any other User, Merchant, Delivery
                  Partner or person; violate any applicable laws or regulations
                  for the time being in force within or outside India or
                  anyone’s right to privacy or personality; violate the Terms of
                  Use contained herein or elsewhere; reverse engineer, modify,
                  copy, distribute, transmit, display, perform, reproduce,
                  publish, license, create derivative works from, transfer, or
                  sell any information or software obtained from the Platform.
                </li>
                <li className="listed">
                  h. You agree and acknowledge that the use of the Jiffy
                  Services offered by Jiffy is at Your sole risk and that Jiffy
                  disclaims all representations and warranties of any kind,
                  whether express or implied as to condition, suitability,
                  quality, merchantability and fitness for any purposes are
                  excluded to the fullest extent permitted by law.
                </li>
                <li className="listed">
                  i. Without prejudice to the above, Jiffy makes no
                  representation or warranty that the Jiffy Services will meet
                  Your requirements.
                </li>
                <li className="listed">
                  j. All materials/content on our Platform (except any third
                  party content available on the Platform), including, without
                  limitation, names, logos, trademarks, images, text, columns,
                  graphics, videos, photographs, illustrations, artwork,
                  software and other elements (collectively, “Material”) are
                  protected by copyrights, trademarks and/or other intellectual
                  property rights owned and controlled by Jiffy. You acknowledge
                  and agree that the Material is made available for limited,
                  non-commercial, personal use only. Except as specifically
                  provided herein or elsewhere in our Platform, no Material may
                  be copied, reproduced, republished, sold, downloaded, posted,
                  transmitted, or distributed in any way, or otherwise used for
                  any purpose other than the purposes stated under this Terms of
                  Use, by any person or entity, without Jiffy’s prior express
                  written permission. You may not add, delete, distort, or
                  otherwise modify the Material. Any unauthorized attempt to
                  modify any Material, to defeat or circumvent any security
                  features, or to utilize our Platform or any part of the
                  Material for any purpose other than its intended purposes is
                  strictly prohibited. Subject to the above restrictions under
                  this Clause, Jiffy hereby grants You a non-exclusive, freely
                  revocable (upon notice from Jiffy), non-transferable access to
                  view the Material on the Platform.
                </li>
                <li className="listed">11. Intellectual Property Rights</li>
                <li className="listed">
                  a. The Platform and process, and their selection and
                  arrangement, including but not limited to, all text, videos,
                  graphics, user interfaces, visual interfaces, sounds and music
                  (if any), artwork, algorithm and computer code (and any
                  combination thereof), except any third party software
                  available on the Platform, is owned by Jiffy (“Jiffy
                  Property”) and the design, structure, selection,
                  co-ordination, expression, look and feel and arrangement of
                  such Jiffy Property is protected by copyright, patent and
                  trademark laws, and various other intellectual property
                  rights. You are not permitted to use Jiffy Property without
                  the prior written consent of Jiffy.
                </li>
                <li className="listed">
                  b. The trademarks, logos and service marks displayed on the
                  Platform (“Marks”) are the property of Jiffy, except any
                  trademark, logos and service marks of third parties available
                  on the Platform. You are not permitted to use the Marks
                  without the prior consent of Jiffy or such third party as may
                  be applicable.
                </li>
                <li className="listed">
                  12. Disclaimer of Warranties & Liabilities
                </li>
                <li className="listed">
                  You expressly understand and agree that, to the maximum extent
                  permitted by applicable law:
                </li>
                <li className="listed">
                  a. The Platform and Jiffy Property, Jiffy Services are
                  provided by Jiffy on an “as is” basis without warranty of any
                  kind, express, implied, statutory or otherwise, including the
                  implied warranties of title, non-infringement, merchantability
                  or fitness for a particular purpose. Without limiting the
                  foregoing, Jiffy makes no warranty that (i) the Platform,
                  Jiffy Services will meet Your requirements or Your use of the
                  Platform will be uninterrupted, timely, secure or error-free;
                  (ii) the quality of the Platform will meet Your expectations;
                  or (iii) any errors or defects in the Platform will be
                  corrected. No advice or information, whether oral or written,
                  obtained by You from Jiffy shall create any warranty not
                  expressly stated in the Terms of Use.
                </li>
                <li className="listed">
                  b. Jiffy will have no liability related to any User content
                  arising under intellectual property rights, libel, privacy,
                  publicity, obscenity or other laws. Jiffy also disclaims all
                  liability with respect to the misuse, loss, modification or
                  unavailability of any User content.
                </li>
                <li className="listed">
                  c. Jiffy will not be liable for any loss that You may incur as
                  a consequence of unauthorized use of Your Account or Account
                  information in connection with the Platform either with or
                  without Your knowledge.
                </li>
                <li className="listed">
                  d. Jiffy shall not be responsible for the delay or inability
                  to use the Platform, Jiffy Services or related
                  functionalities, the provision of or failure to provide
                  functionalities, or for any information, software,
                  functionalities and related graphics obtained through the
                  Platform, or otherwise arising out of the use of the Platform,
                  whether based on contract, tort, negligence, strict liability
                  or otherwise. Further, Jiffy shall not be held responsible for
                  non-availability of the Platform during periodic maintenance
                  operations or any unplanned suspension of access to the
                  Platform that may occur due to technical reasons or for any
                  reason beyond Jiffy’s control. You understand and agree that
                  any material or data downloaded or otherwise obtained through
                  the Platform is done entirely at Your own discretion and risk,
                  and that You will be solely responsible for any damage to Your
                  computer systems or loss of data that results from the
                  download of such material or data.
                </li>
                <li className="listed">
                  13. Indemnification and Limitation of Liability
                </li>
                <li className="listed">
                  a. You agree to indemnify, defend and hold harmless Jiffy and
                  its affiliates including but not limited to its officers,
                  directors, consultants, agents and employees (“Indemnitees”)
                  from and against any and all losses, liabilities, claims,
                  damages, demands, costs and expenses (including legal fees and
                  disbursements in connection therewith and interest chargeable
                  thereon) asserted against or incurred by the Indemnitees that
                  arise out of, result from, or may be payable by virtue of, any
                  breach or non-performance of any obligation, covenant,
                  representation or warranty by You pursuant to these Terms of
                  Use. Further, You agree to hold the Indemnitees harmless
                  against any claims made by any third party due to, or arising
                  out of, or in connection with, Your use of the Platform, Jiffy
                  Services, any misrepresentation with respect to the data or
                  information provided by You in relation to the Account, Your
                  violation of the Terms of Use, or Your violation of any rights
                  of another, including any intellectual property rights.
                </li>
                <li className="listed">
                  b. In no event shall the Indemnitees be liable to You or any
                  third party for any special, incidental, indirect,
                  consequential or punitive damages whatsoever, arising out of
                  or in connection with Your use of or access to the Platform or
                  Jiffy Property on the Platform.
                </li>
                <li className="listed">
                  c. Your indemnification obligation under the Terms of Use will
                  survive the termination of Your Account or use of the Platform
                  or Jiffy Services.
                </li>
                <li className="listed">
                  d. Subject to applicable laws, in no event will Jiffy or its
                  employees aggregate liability, arising from or related to the
                  Jiffy Services or the use of the Platform exceeding AED 100
                  for any and all causes of actions brought by You or on behalf
                  of You.
                </li>
                <li className="listed">
                  e. The Platform and the Jiffy Services are only available to
                  Users located in Dubai. Users shall not access or use the
                  Platform from any other jurisdiction except for Dubai. If a
                  User accesses or uses the Platform from any other jurisdiction
                  except for Dubai, the User shall be liable to comply with all
                  applicable laws and Jiffy shall not be liable for the same,
                  whatsoever.
                </li>
                <li className="listed">14. Violation of the Terms of Use</li>
                <li className="listed">
                  You agree that any violation by You of these Terms of Use will
                  constitute an unlawful and unfair business practice, and will
                  cause irreparable harm to Jiffy, for which monetary damages
                  would be inadequate, and You consent to Jiffy obtaining any
                  injunctive or equitable relief that they deem necessary or
                  appropriate in such circumstances. These remedies are in
                  addition to any other remedies that Jiffy may have at law or
                  in equity. If Jiffy takes any legal action against You as a
                  result of Your violation of these Terms of Use, they will be
                  entitled to recover from You, and You agree to pay all
                  reasonable attorneys’ fees and costs of such action, in
                  addition to any other relief that may be granted.
                </li>
                <li className="listed">15. Additional Terms</li>
                <li className="listed">
                  a. We may also require You to follow additional rules,
                  guidelines or other conditions in order to participate in
                  certain promotions or activities available through the
                  Platform. These additional terms shall form a part of this
                  Terms of Use, and You agree to comply with them when You
                  participate in those promotions, or otherwise engage in
                  activities governed by such additional terms.
                </li>
                <li className="listed">16. Link to Third Parties</li>
                <li className="listed">
                  a. The Platform may contain links to other sites owned by
                  third parties (i.e. advertisers, affiliate partners, strategic
                  partners, or others). Jiffy shall not be responsible for
                  examining or evaluating such third party websites, and Jiffy
                  does not warrant the products or offerings of, any of these
                  businesses or individuals, or the accuracy of the content of
                  such third party websites. Jiffy does not assume any
                  responsibility or liability for the actions, product, and
                  content of any such third party websites. Before You
                  use/access any such third-party websites, You should review
                  the applicable terms of use and policies for such third party
                  websites. If You decide to access any such linked third party
                  website, You do so at Your own risk.
                </li>
                <li className="listed">17. Term and Termination</li>
                <li className="listed">
                  a. The Terms of Use will continue to apply until terminated by
                  either You or Jiffy as set forth below. If You object to the
                  Terms of Use or are dissatisfied with the Platform, Jiffy
                  Services, Your only recourse is to (i) close Your Account on
                  the Platform by writing to Us at Office suite # 207, Misa Business Centre, Blue Wave Tower, Wadi Al Safa 5, 
                  Dubai - UAE,
                  Email: support@jiffy.ae; and/or (ii) stop accessing the
                  Platform. Jiffy will make Your account dormant upon receipt of
                  request in writing. Jiffy may, in its sole discretion, bar
                  your use of the Jiffy Services at any time, for any or no
                  reason. Even after your account with Jiffy is disabled,
                  dormant or made inactive, the terms agreed by You at the time
                  of registration will remain in effect. This termination shall
                  be effective only once You have cleared all Your dues that You
                  are liable to pay as per the provisions of this Terms of Use.
                  b. The Company may delist You or block Your future access to
                  the Platform or suspend or terminate Your Account if it
                  believes, in its sole and absolute discretion that You have
                  infringed, breached, violated, abused, or unethically
                  manipulated or exploited any term of these Terms of Use or
                  anyway otherwise acted unethically. c. Notwithstanding
                  anything in this clause, these Terms of Use will survive
                  indefinitely unless and until Jiffy chooses to terminate them.
                </li>
                <li className="listed">
                  d. You hereby agree and acknowledge, upon termination, Jiffy
                  shall have the right to retain all information pertaining to
                  the transactions initiated by You on the Platform.
                </li>
                <li className="listed">
                  18. Governing Law and Dispute Resolution
                </li>
                <li className="listed">
                  These Terms of Use shall be governed by and construed in
                  accordance with the laws of India and subject to the
                  provisions of arbitration set out herein, the courts at
                  Bangalore, India shall have exclusive jurisdiction in relation
                  to any Disputes (defined below) arising out of or in
                  connection with this these Terms of Use. Any adverse action,
                  dispute or difference arising under or relating to these Terms
                  of Use (“Dispute”) shall at the first instance be resolved
                  through good faith negotiations between the parties hereto,
                  which negotiations shall begin promptly, within 15 (fifteen)
                  days after a party has delivered to the other party a written
                  request for such negotiations. If the parties are unable to
                  resolve the Dispute in question within 15 (fifteen) days of
                  the commencement of such negotiations, the Dispute shall be
                  referred to and finally resolved by arbitration in accordance
                  with the Indian Arbitration and Conciliation Act, 1996, as
                  amended from time to time and rules prescribed thereunder.
                  When any Dispute is under arbitration, except for the matters
                  under dispute, We and you shall continue to exercise the
                  remaining respective rights and fulfil the remaining
                  respective obligations under these Terms of Use. The
                  arbitration shall be conducted by a sole arbitrator appointed
                  by Jiffy and the seat of arbitration shall be Bangalore,
                  India. The language of the arbitration proceedings and of all
                  written decisions and correspondence relating to the
                  arbitration shall be English.
                </li>
                <li className="listed">19. Report Abuse</li>
                <li className="listed">
                  In the event You come across any abuse or violation of these
                  Terms of Use or if You become aware of any objectionable
                  content on the Platform, please report the same to the
                  following e-mail id: support@jiffy.ae. In case You have any
                  queries with respect to the Terms or the Jiffy Services,
                  please write to Us at Office suite # 207, Misa Business Centre, Blue Wave Tower, Wadi Al Safa 5, 
                  Dubai - UAE.
                </li>

                <li className="listed">20. Communications</li>
                <li className="listed">
                  You hereby expressly agree to receive communications by way of
                  SMSs and/or e-mails and/or Whatsapp from Jiffy, or other third
                  parties. You can unsubscribe/ opt-out from receiving
                  communications through SMS, Whatsapp and email anytime by
                  contacting us for the same. However, You may still receive
                  communications from Your end with respect to Your use of the
                  Jiffy Service.
                </li>
                <li className="listed">21. General</li>
                <li className="listed">
                  a. Amendments: Jiffy reserves the unconditional right to
                  modify or amend this Terms of Use without any requirement to
                  notify You of the same. You can determine when this Terms of
                  Use was last modified by referring to the “Last Updated”
                  legend above. It shall be Your responsibility to check this
                  Terms of Use periodically for changes. Your acceptance of the
                  amended Terms of Use shall signify Your consent to such
                  changes and agreement to be legally bound by the same. b.
                  Notice: All notices from Jiffy will be served by email to Your
                  registered email address or by general notification on the
                  Platform. c. Assignment: You cannot assign or otherwise
                  transfer the Terms of Use, or any rights granted hereunder to
                  any third party. Jiffy’s rights under the Terms of Use are
                  freely transferable by Jiffy to any third party without the
                  requirement of informing You or seeking Your consent. d.
                  Severability: If, for any reason, a court of competent
                  jurisdiction finds any provision of the Terms of Use, or any
                  portion thereof, to be unenforceable, that provision shall be
                  enforced to the maximum extent permissible so as to give
                  effect to the intent of the parties as reflected by that
                  provision, and the remainder of the Terms of Use shall
                  continue in full force and effect. e. Waiver: Any failure by
                  Jiffy to enforce or exercise any provision of the Terms of
                  Use, or any related right, shall not constitute a waiver by
                  Jiffy of that provision or right. f. Integration: These Terms
                  of Use together with Jiffy’s Privacy Policy and any other
                  legal notices, communications published by Jiffy on its
                  Platform, and any other agreements executed between You and
                  Jiffy shall constitute the entire agreement between you and
                  Jiffy concerning its Platform, Jiffy Services and governs Your
                  use of the Platform and Jiffy Service, superseding any prior
                  agreements between You and Jiffy with respect to the Platform
                  and Jiffy Service g. IP Infringement: If You believe the
                  Platform violates Your intellectual property, You must
                  promptly notify Jiffy in writing at Office suite # 207, Misa Business Centre, Blue Wave Tower, Wadi Al Safa 5, 
                  Dubai - UAE, Email: support@jiffy.ae. These notifications should only
                  be submitted by the owner of the intellectual property or an
                  agent duly authorized to act on his/her behalf. However, any
                  false claim by You may result in the termination of Your
                  access to the Platform. You are required to provide the
                  following details in Your notice:
                </li>
                <li className="listed">
                  i. the intellectual property that You believe is being
                  infringed;
                </li>
                <li className="listed">
                  ii. the item that You think is infringing and include
                  sufficient information about where the material is located on
                  the Platform;
                </li>
                <li className="listed">
                  iii. a statement that You believe in good faith that the item
                  You have identified as infringing is not authorized by the
                  intellectual property owner, its agent, or the law to be used
                  in connection with the Platform;
                </li>
                <li className="listed">
                  iv. Your contact details, such as Your address, telephone
                  number, and/or email;
                </li>
                <li className="listed">
                  v. a statement that the information You provided in Your
                  notice is accurate, and that You are the intellectual property
                  owner or an agent authorized to act on behalf of the
                  intellectual property owner whose intellectual property is
                  being infringed; and
                </li>
                <li className="listed">
                  vi. Your physical or electronic signature.
                </li>
              </ol>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default withStyles(SendPackageStyle)(SendPackage);
