import React from "react";
import Footer from "../Homepage/Footer";
import Allservices from "./Allservices";

const Comming = () => {
    return (<>
    <Allservices></Allservices>
    <div className="comming">
    <h1>
          Website
          <br />
          Coming Soon
        </h1>
    </div>
       
       
     
    <Footer></Footer>
 </>)
}

export default(Comming);
