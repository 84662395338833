import React, { useState } from "react";

const MapMarker = ({ lat, lng, title, description }) => {
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="map-marker" onClick={toggleInfo}>
      <div className="marker-title">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.383"
            height="10"
            viewBox="0 0 10 11.976"
          >
            <g id="ic_location_on_24px" transform="translate(-5 -2)">
              <path
                id="Path_651"
                data-name="Path 651"
                d="M9.192,2A4.189,4.189,0,0,0,5,6.192c0,3.144,4.192,7.784,4.192,7.784s4.192-4.641,4.192-7.784A4.189,4.189,0,0,0,9.192,2Zm0,5.689a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,9.192,7.689Z"
                fill="#d99e12"
              />
            </g>
          </svg>
        </span>
        &nbsp;
        <span className="title">
          IoT(1)&nbsp;&nbsp;
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.899"
              height="4.26"
              viewBox="0 0 6.899 4.26"
            >
              <g
                id="ic_keyboard_arrow_down_24px"
                transform="translate(-6 -7.84)"
              >
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M6.811,7.84l2.639,2.633L12.088,7.84l.811.811L9.449,12.1,6,8.651Z"
                  transform="translate(0 0)"
                  fill="#fff"
                />
              </g>
            </svg>
          </span>
        </span>
      </div>
      {showInfo && (
        <div className="marker-description">
          {description}&nbsp;<span className="status">IN PROGRESS</span>
          <br />
          <span className="details">Doc. ID 223221</span>
        </div>
      )}
    </div>
  );
};

export default MapMarker;
