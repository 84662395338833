import React,{useState} from "react";
import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker"; // Adjust the path to your MapMarker component
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getFirestore, onSnapshot, collection } from "@firebase/firestore";

const MyMap = (props) => {
  console.log("ggg",props)
  const Card = ({ onClick }) => {
    return (
      <div className="overlay" onClick={onClick}>
        <button></button>
      </div>
    );
  };
  const firebaseConfig = {
    apiKey: "AIzaSyApfVOAcPyKnWgMlDCQlrlJvDLro2LW4Xo",
    authDomain: "jiffy-7c780.firebaseapp.com",
    projectId: "jiffy-7c780",
    storageBucket: "jiffy-7c780.appspot.com",
    messagingSenderId: "858697568951",
    appId: "1:858697568951:web:c314027e738f1df16db332",
    measurementId: "G-ZMK5HQEQFN",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  console.log("fff",app)
const [devices,setDevices] = React.useState([])


  React.useEffect(() => {
    if(props.id === undefined)
    onSnapshot(collection(getFirestore(), "devices"), (snapshot) => {
      snapshot.docs.forEach((doc) => {
          const documentData = doc.data(); // Access the document data
          const documentId = doc.id; // Access the document ID
          let details = {
            _id:documentId,
            data: documentData
          }
         devices.push(details)
          console.log("Document ID:", documentId);
          console.log("Document Data:", documentData);
      });
  });
  
  }, [firebaseConfig])
  
  const handleEdit = () => {};
  const mapStyles = {
    
    borderRadius: '20px', // Apply border radius to the map
  };
  const markers = [
    {
      id: 1,
      lat: +25.2788,
      lng: +55.3309,
      title: "Marker 1",
      description: "4454646737",
    },
    {
      id: 2,
      lat: +25.3088,
      lng: +55.3309,
      title: "Marker 2",
      description: "4454646737",
    },
    // Add more markers here
  ];
  const [showOverlay, setShowOverlay] = useState(false);
 
  return (
    <>
    <div className={showOverlay ? 'map2' : 'map1'} >
      <GoogleMapReact
        // Provide your API key and other configuration in googleMapURL
        // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY_HERE`}
        loadingElement={<div>Loading...</div>}
        defaultZoom={11}
        mapContainerStyle={mapStyles}
        defaultCenter={{ lat: +25.2788, lng: +55.3309 }}
      >
   { showOverlay && devices.map((marker) => (
           <MapMarker
            key={marker.id}
            lat={marker.data.latitude}
            lng={marker.data.longitude}
            title={marker.title}
            description={marker.description}
          />
        ))}
      </GoogleMapReact>
 
     
      
    
     

    </div>
    {!showOverlay ? (
     <button className="btn-overlay" onClick={() => setShowOverlay(!showOverlay)}>
     {showOverlay ? '' : 'Activate Map'}
   </button>):(<></>)}
   </>
  );
};

export default MyMap;
