import React, { Component, forwardRef } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import TrackorderStyle from "../TrackOrders/TrackorderStyle";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useParams } from "react-router-dom";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Pdf from "react-to-pdf";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import DatePicker from "react-datepicker";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { getBaseURL } from "../../../api/apiManagement";
import { useNavigate } from "react-router-dom";
import draftId from "../../AllService";

const Draft = (props) => {
  const navigate = useNavigate();
  let dispalyData;
  const { id } = useParams();
  const url = getBaseURL() + "/vendor/api/v1/parcel";
  const { classes } = props;
  const [data, setData] = React.useState();
  const [dataParcel, setDataParcel] = React.useState();
  const [datanew, setDataNew] = React.useState();
  const [searched, setSearched] = React.useState("");

  const ref = React.createRef();
  let [pageNo, setPageNo] = React.useState(1);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  /*React.useEffect(() => {

        axios.get(url, {
            params: {
                _id: id
            }
        }).then((response) => {

            console.log(response)
            setData(response.data.parcel[0])

        });



    }, [url]);*/
  const onChangeDate = (dates) => {
    setDataParcel(undefined);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end) {
      axios
        .get(url, {
          params: {
            order_status: "Delivered",

            userId: localStorage.getItem("userId").toString(),
            company_id: localStorage.getItem("companyId").toString(),
            from_date: moment(start).format("DD/MM/YYYY"),
            to_date: moment(end).format("DD/MM/YYYY"),
          },
        })
        .then((response) => {
          setDataParcel(response.data.parcel);
        });
    }
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <OutlinedInput
      id="outlined-adornment-password"
      onClick={onClick}
      ref={ref}
      value={value}
      className={classes.datePicker}
      startAdornment={
        <InputAdornment position="start">
          <img
            src="./Images/calendar-alt.png"
            width={17}
            height={19}
            alt="calender"
          />
        </InputAdornment>
      }
      placeholder="Date From - Date To"
    />
  ));
  const [searchRes, setSearchRes] = React.useState(false);
  const searchOrder = (val) => {
    setSearched(val.target.value);
    console.log("uuuu", val.target.value);
    axios
      .get(url, {
        params: {
          order_id: val.target.value,
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
        },
      })
      .then((response) => {
        setDataParcel(response.data.parcel);
        if (
          response &&
          response.data &&
          response.data.parcel &&
          !response.data.parcel.length
        ) {
          setSearchRes(true);
        }
        //console.log("INVOICESS", response)
        window.scroll(0, 0);
      });
    if (val.target.value === "") {
      setDetailsall();
    }
  };

  const decreasePage = (er, newpage) => {
    setDataParcel(undefined);
    // console.log("ddd",newpage)
    pageNo = newpage;
    setPageNo(parseInt(newpage));
    axios
      .get(url, {
        params: {
          order_status: "invoice",
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
          noOfRecord: "10",
          pageNumber: pageNo,
        },
      })
      .then((response) => {
        //setDataParcel(response.data.parcel)
        setDataParcel(response.data.parcel);
        // console.log("INVOICESS", response)
        window.scroll(0, 0);
      });
  };
  const editChange = (e) => {
    draftId.value = e._id;
    if (e.delivery_type && e.delivery_type && e.delivery_type.toLowerCase() === "delivernow") {
      navigate("/sendpackage");
    }
    if (e.delivery_type && e.delivery_type && e.delivery_type.toLowerCase() === "deliverlater") {
      navigate("/sendpackage");
    }
    if (e.delivery_type && e.delivery_type && e.delivery_type.toLowerCase() === "collectnow") {
      navigate("/collectpackage");
    }
    if (e.delivery_type && e.delivery_type && e.delivery_type.toLowerCase() === "collectlater") {
      navigate("/collectpackage");
    }
  };
  const deleteChange = (e) => {
    axios
      .put(draftUrl, {
        order_status: "deleted",
        _id: e._id,
        userId: localStorage.getItem("userId"),
        company_id: localStorage.getItem("companyId"),
      })
      .then((response) => {
        //setDataParcel(response.data.parcel)
        if (response) {
          axios
            .get(draftUrl, {
              params: {
                order_status: "draft",
                userId: localStorage.getItem("userId"),
                company_id: localStorage.getItem("companyId"),
              },
            })
            .then((response) => {
              //setDataParcel(response.data.parcel)
              setDataParcel(response.data.parcel);
              //  console.log("INVOICESS", response)
            });
          //  console.log("INVOICESS", response)
        }
      });
  };
  const addPageNumber = (e) => {
    pageNo = e;
    setPageNo(parseInt(e));
    // console.log("fff",pageNo)
    axios
      .get(url, {
        params: {
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
          noOfRecord: "10",
          pageNumber: parseInt(e),
        },
      })
      .then((response) => {
        // console.log(response)
        setDataParcel(response.data.parcel);
        window.scroll(0, 0);
      });
  };
  const addPage = () => {
    pageNo = pageNo + 1;
    setPageNo(parseInt(pageNo));
    axios
      .get(url, {
        params: {
          order_status: "invoice",
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
          noOfRecord: "10",
          pageNumber: pageNo,
        },
      })
      .then((response) => {
        //setDataParcel(response.data.parcel)
        setDataParcel(response.data.parcel);
        //  console.log("INVOICESS", response)
        window.scroll(0, 0);
      });
  };
  const draftUrl = getBaseURL() + "/vendor/api/v1/draft";
  React.useEffect(() => {
    axios
      .get(draftUrl, {
        params: {
          order_status: "draft",
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
        },
      })
      .then((response) => {
        //setDataParcel(response.data.parcel)
        setDataParcel(response.data.parcel);
        //  console.log("INVOICESS", response)
      });
  }, [draftUrl]);
  function resetData() {
    setDataParcel(undefined);
    setStartDate(null);
    setEndDate(null);
    axios
      .get(url, {
        params: {
          order_status: "invoice",
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
          noOfRecord: "10",
          pageNumber: "1",
        },
      })
      .then((response) => {
        //setDataParcel(response.data.parcel)
        setDataParcel(response.data.parcel);
        //  console.log("INVOICESS", response)
      });
  }
  function setDetailsall() {
    axios
      .get(url, {
        params: {
          order_status: "invoice",
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
          noOfRecord: "10",
          pageNumber: pageNo,
        },
      })
      .then((response) => {
        //setDataParcel(response.data.parcel)
        setDataParcel(response.data.parcel);
        //  console.log("INVOICESS", response)
      });
  }

  //React.useEffect(() => {

  //   axios.get(url, {
  //       params: {

  //      }
  //  }).then((response) => {

  //      console.log(response)
  //      setDataParcel(response.data.parcel)

  //   });

  // }, [url]);
  //console.log(data)
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        {/* <Grid item xs={12} md={6} lg={6} className={classes.filterContainer1}>
            <span className={classes.filterText1}>Search</span>&nbsp;&nbsp;
            <OutlinedInput
              id="outlined-adornment-password"
              // onClick={searchOrder(value)}
              style={{ borderRadius: "31px", height: "51px" }}
              className={classes.datePicker5}
              onChange={(event) => searchOrder(event)}
              placeholder="Order id"
            />
            <img src="./Images/search.svg"></img>
          </Grid> */}
        {/* <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className={classes.filterContainer}
            textAlign="right"
          >
            <FilterListIcon />
            <p className={classes.filterText}>Filter by date</p>
            <DatePicker
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={<ExampleCustomInput />}
              calendarClassName={classes.calender}
            />&nbsp;&nbsp;
             <img onClick={resetData} title='refresh' style={{cursor:'pointer'}} src="./Images/undo.svg"></img>
          </Grid> */}
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          {/*} <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="onGoing" control={<Radio />} label="Ongoing" />
                        <FormControlLabel value="completed" control={<Radio />} label="Completed" />
                    </RadioGroup>
                </FormControl>*/}
        </Grid>
        <Grid item xs={6} md={6} lg={6}></Grid>
      </Grid>

      <div className="searchRes">
        {searched !== "" && searchRes && dataParcel.length === 0 ? (
          <>
            <span>No Records Found</span>
          </>
        ) : (
          <></>
        )}
      </div>
      {dataParcel !== undefined ? (
        (dispalyData = dataParcel.map((item, index) =>
          item.order_status === "draft" ? (
            <Box className={classes.deliveryIn}>
              <Grid container ref={ref} className={classes.Invoiced}>
                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.stepperIn}
                >
                  <Grid xs={12} md={2.5} lg={2.5}>
                    <p className={classes.stepperItem}>
                      <span className={classes.stepperBold}>
                        Drafted Date :
                        {moment(item.createdAt).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </Grid>
                  {item &&
                  item.delivery_type !== "accompaniment" &&
                  item.delivery_type !== "accompainmentschedulelater" ? (
                    <Grid xs={12} md={2.5} lg={2.5}>
                      <p className={classes.stepperItem}>
                        Status :{" "}
                        <span className={classes.stepperBold}>
                          {item.order_status}
                        </span>
                      </p>
                    </Grid>
                  ) : (
                    <Grid xs={12} md={2} lg={2}>
                      <p className={classes.stepperItem}>
                        Status :{" "}
                        <span className={classes.stepperBold}>Completed</span>
                      </p>
                    </Grid>
                  )}
                  <Grid xs={12} md={2} lg={2}>
                    <p className={classes.stepperItem}>
                      Order ID :{" "}
                      <span className={classes.stepperBold}>
                        {item.order_id}
                      </span>
                    </p>
                  </Grid>
                  {item &&
                  item.delivery_type !== "accompaniment" &&
                  item.delivery_type !== "accompainmentschedulelater" &&
                  item.product_info &&
                  item.product_info.length ? (
                    <Grid xs>
                      <p className={classes.stepperItem}>
                        Total Item(s) :{" "}
                        <span className={classes.stepperBold}>
                          {item.product_info.length}{" "}
                        </span>
                      </p>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {item &&
                  (item.delivery_type === "accompaniment" ||
                    item.delivery_type === "accompainmentschedulelater") &&
                  item.total_time_taken ? (
                    <Grid xs={12} md={2.5} lg={2.5}>
                      <p className={classes.stepperItem}>
                        Total time taken :{" "}
                        <span className={classes.stepperBold}>
                          {item.total_time_taken}{" "}
                        </span>
                      </p>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Grid xs={12} md={2.5} lg={2.5}>
                    <p className={classes.stepperItem}>
                      <span className={classes.stepperBold}>
                        Total Price : {item.estimatedPrice} AED
                      </span>
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.invoiceContent}
                  alignItems="center"
                >
                  <Grid xs>
                    <p className={classes.stepperItem}>
                      Delivery Type :{" "}
                      <span className={classes.stepperBold}>
                        {item.delivery_type_display}
                      </span>
                    </p>
                    <br />
                  </Grid>
                  {item.order_by ? (
                    <Grid item xs={12} md={12} lg={12}>
                      <p className={classes.stepperItem}>
                        Ordered by :{" "}
                        <span className={classes.stepperBold}>
                          {item.order_by}
                        </span>
                      </p>
                      <br />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.orderStatus}
                  >
                    <Grid
                      item
                      xs={12}
                      md={2}
                      lg={2}
                      className={classes.stepped}
                    >
                      <div className={classes.lineDot}>
                        <img src="./Images/e1.svg"></img>
                      </div>
                      <div className={classes.lineNew}>
                        <img
                          src="./Images/e2.svg"
                          className={classes.imgLine}
                        ></img>
                      </div>
                      {item && item.pickup && item.pickup[1] ? (
                        <>
                          <div className={classes.lineDot}>
                            <img src="./Images/e1.svg"></img>
                          </div>
                          <div className={classes.lineNew}>
                            <img
                              src="./Images/e2.svg"
                              className={classes.imgLine}
                            ></img>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {item && item.pickup && item.pickup[2] ? (
                        <>
                          <div className={classes.lineDot}>
                            <img src="./Images/e1.svg"></img>
                          </div>
                          <div className={classes.lineNew}>
                            <img
                              src="./Images/e2.svg"
                              className={classes.imgLine}
                            ></img>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className={classes.lineDot}>
                        <img src="./Images/e1.svg"></img>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      className={classes.ddpick}
                    >
                      <span className={classes.lpick}>
                        <b>Pickup Address</b>{" "}
                      </span>
                      <br />
                      {item && item.pickup && item.pickup[0] ? (
                        <span className={classes.lpickData}>
                          {" "}
                          {item.pickup[0].pickup_location}&nbsp;
                          {item &&
                          item.pickup &&
                          item.pickup[0] &&
                          item.pickup[0].pickup_status &&
                          item.pickup[0].pickup_status === "pickedup" ? (
                            <img
                              className="tick"
                              src="./Images/check.png"
                            ></img>
                          ) : (
                            <></>
                          )}
                          &nbsp;
                          {item &&
                          item.pickup &&
                          item.pickup[0] &&
                          item.pickup[0].time_taken ? (
                            <span>Time taken: {item.pickup[0].time_taken}</span>
                          ) : (
                            <></>
                          )}
                        </span>
                      ) : (
                        <></>
                      )}
                      &nbsp;
                      {/*<u className={classes.modifyButton}>Modify</u><span className={classes.modifyButton}> / </span><u className={classes.modifyButton}>Reschedule</u>*/}
                      <br /> <br />
                      {item && item.pickup && item.pickup[1] ? (
                        <>
                          <span className={classes.lpick}>
                            <b>Pickup Point 1 Address</b>{" "}
                          </span>

                          <br />
                          {item && item.pickup && item.pickup[1] ? (
                            <span className={classes.lpickData}>
                              {" "}
                              {item.pickup[1].pickup_location}
                              {item &&
                              item.pickup &&
                              item.pickup[1] &&
                              item.pickup[1].pickup_status &&
                              item.pickup[1].pickup_status === "pickedup" ? (
                                <img
                                  className="tick"
                                  src="./Images/check.png"
                                ></img>
                              ) : (
                                <></>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}

                          <br />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {item &&
                      item.pickup &&
                      item.pickup[2] &&
                      item.pickup[2] ? (
                        <>
                          <span className={classes.lpick}>
                            <b>Pickup Point 2 Address</b>{" "}
                          </span>

                          <br />
                          {item && item.pickup && item.pickup[2] ? (
                            <span className={classes.lpickData}>
                              {item.pickup[2].pickup_location}
                              {item &&
                              item.pickup &&
                              item.pickup[2] &&
                              item.pickup[2].pickup_status &&
                              item.pickup[2].pickup_status === "pickedup" ? (
                                <span>
                                  {" "}
                                  <img
                                    className="tick"
                                    src="./Images/check.png"
                                  ></img>
                                </span>
                              ) : (
                                <></>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}

                          <br />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      <span className={classes.dpick}>
                        {" "}
                        <b>Delivery Address</b>
                      </span>{" "}
                      <br />
                      <span className={classes.dpickData}>
                        {item.delivery[0].delivery_location} &nbsp;
                        {/*<u className={classes.modifyButton}>Modify</u>*/}
                      </span>
                      <br />
                      <br />
                      <br />
                    </Grid>
                  </Grid>

                  {/*} <Grid item xs={12} md={4} lg={4} className={classes.stepperDownload}>
                                    <img src='./Images/demo-user.png' className={classes.userIcon} width={70} height={70} alt='user' />
                                    <Pdf targetRef={ref} filename="code-example.pdf">
                                        {({ toPdf }) => <div title='DOWNLOAD'><button onClick={toPdf} className={classes.btnInvoice}>Downlooad</button></div>}
                                    </Pdf>
                                </Grid>*/}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.stepperIn}
                >
                  <Grid xs={12} md={2.5} lg={2.5}>
                    <button
                      className={classes.service_btn}
                      onClick={() => {
                        editChange(item);
                      }}
                    >
                      Edit
                    </button>
                  </Grid>
                  <Grid xs={12} md={2.5} lg={2.5}>
                    <button
                      className={classes.service_btn}
                      onClick={() => {
                        deleteChange(item);
                      }}
                    >
                      Delete
                    </button>
                  </Grid>
                </Grid>

                {/*} <Grid item xs={12} md={12} lg={12} className={classes.deliverySpace}>
                                <Grid item xs={4} md={4} >
                                    <img src='./../Images/icon.png' className='logo'></img>
                                </Grid>
                                <Grid item xs={6} md={3} lg={6} className={classes.deliverySpace}>
                                    <b className={classes.note}> Document Delivery Note</b>
                                </Grid>
                                <Grid item xs={6} md={3} lg={6} className={classes.deliverySpace}>
                                    OrderID: {item._id}
                                </Grid>
                                <Grid item xs={6} md={3} lg={6} className={classes.delDownload}>
                                    <Pdf targetRef={ref} filename="code-example.pdf">
                                        {({ toPdf }) => <div title='DOWNLOAD'><DownloadForOfflineIcon onClick={toPdf}></DownloadForOfflineIcon></div>}
                                    </Pdf>

                                </Grid>




        </Grid>*/}
              </Grid>
            </Box>
          ) : (
            <></>
          )
        ))
      ) : (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              className={classes.filterContainer1}
            >
              <div className="center2">
                <div class="loader"></div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(TrackorderStyle)(Draft);
